import { createContext, useState } from "react";

// context 생성 (여기에 데이터를 보관)
export const ProductsContext = createContext();

// provider도 컴포넌트로 Wrapper 같은 거 같음.
// wrapper안에 children들은 모두 Context 데이터에 접근이 가능.✅
// 그 데이터 = 밑에 value이다.
export function ProductsProvider({ children }) {
  const [products, setProducts] = useState([]);
  const [searchWord, setSearchWord] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(-1); // 오직 키보드에서 방향키에 따른

  // 키워드와 관련된 제품들
  const [searchedProducts, setSearchedProducts] = useState([]);

  //! 제품 추천 토글
  const [recommendation, updateRecommendation] = useState(true);

  const filterSearchedProducts = (keyword) => {
    const filteredProducts = [];

    if (keyword === " " || keyword === "") {
      setSearchedProducts([]);
      return;
    }

    products.forEach((product) => {
      /// 제품 이름 검색
      const match = product.name.toLowerCase().includes(keyword.toLowerCase());
      let match2 = false;

      // 초성 검색
      match2 = product.searchKeywords.toLowerCase().includes(keyword.toLowerCase());

      if (match || match2) {
        filteredProducts.push(product);
      }
    });

    setSearchedProducts(filteredProducts);
  };

  const getRelatedProducts = (subCategory) => {
    const sidewalkCategoryName = "물도랑 (보도용)";
    const bothCategoryName = "물도랑 (보도, 차도용)";
    let filteredProducts = [];
    if (subCategory === sidewalkCategoryName) {
      filteredProducts = products.filter((item) => item.type === "sidewalk");
    } else if (subCategory === bothCategoryName) {
      filteredProducts = products.filter((item) => item.type === "both");
    } else {
      filteredProducts = products.filter((item) => item.smallCategory === subCategory);
    }

    return filteredProducts.sort((a, b) => a.order - b.order);
  };

  const getCouplingProducts = (id) => {
    const product = products.find((m) => m.id === id);
    const upOrDown = product.upOrDown;
    const setStandard = product.setStandard;

    if (upOrDown === "" || upOrDown === "예외") {
      return [];
    }

    let couplingPartName = "";
    if (upOrDown === "상부") {
      couplingPartName = "하부";
    } else {
      couplingPartName = "상부";
    }

    const couplingProducts = [];
    products.forEach((item) => {
      if (
        item.upOrDown === couplingPartName &&
        item.setStandard === setStandard &&
        item.normalPrice !== 0
      ) {
        couplingProducts.push(item);
      }
    });

    return couplingProducts;
  };

  const toggleRecommendation = () => {
    updateRecommendation(!recommendation);
  };

  const increaseSelectedIndex = () => {
    if (selectedIndex !== searchedProducts.length - 1) {
      setSelectedIndex((prev) => prev + 1);
    }
  };

  const decreaseSelectedIndex = () => {
    if (selectedIndex !== -1) {
      setSelectedIndex((prev) => prev - 1);
    }
  };

  // 물도랑을 제외한 , 공사 가능한 smallCategories를 가지고 온다.
  const getConstructibleSmallCategoriesWithoutMuldorang = () => {
    const categories = [];
    products.forEach((product) => {
      if (product.dryConstructionCost > 0 && product.smallCategory !== "물도랑") {
        categories.push(product.smallCategory);
      }
    });
    return Array.from(new Set(categories));
  };

  return (
    // value = 자식 컴포들이 사용할 것들을 명시하면 된다.
    <ProductsContext.Provider
      value={{
        searchWord,
        setSearchWord,
        products,
        selectedIndex,
        setSelectedIndex,
        decreaseSelectedIndex,
        increaseSelectedIndex,
        setProducts,
        searchedProducts,
        setSearchedProducts,
        filterSearchedProducts,
        getConstructibleSmallCategoriesWithoutMuldorang,
        getRelatedProducts,
        getCouplingProducts,
        recommendation,
        toggleRecommendation,
      }}
    >
      {children}
    </ProductsContext.Provider>
  );
}
