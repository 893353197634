import React, { useContext, useState, useEffect } from "react";

import DialogBodyPipeSizesDropdown from "./DialogBodyPipeSizesDropdown";
import { Dialog } from "@headlessui/react";
import { Button } from "@mui/material";
import { PdfContext } from "../context/PdfContext";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { EstimateContext } from "../context/EstimatesContext";
import PipeOptionItem from "./PipeOptionItem";

export default function SidePipeOptionDialogBody({ estimate, length }) {
  const { dialogBodies } = useContext(PdfContext);
  const [remainCount, setRemainCount] = useState(length);
  const [goodToGo, setGoodToGo] = useState(false);
  const { estimates, handleSidePipeAdd, setActiveDialogIndex, activeDialogIndex } =
    useContext(EstimateContext);

  const nextSlide = () => {
    setActiveDialogIndex((prevSlide) => (prevSlide + 1) % dialogBodies.length);
  };

  const prevSlide = () => {
    setActiveDialogIndex(
      (prevSlide) => (prevSlide - 1 + dialogBodies.length) % dialogBodies.length
    );
  };

  useEffect(() => {
    // 남은 카운트 계산
    const est = estimates.find((est) => est.id === estimate.id);
    const theCount = length - est.sidePipeOptions.total;
    setRemainCount(theCount);
    if (theCount === 0) {
      setGoodToGo(true);
    } else {
      setGoodToGo(false);
    }
  }, [estimates, activeDialogIndex, estimate.id, length]);

  return (
    <div className="flex flex-col">
      <div className="bg-gray-50 px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
        <div className="mt-3 text-center sm:mt-0">
          <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
            <h2>
              측면 파이프 가공 옵션을 선택해주세요 ( <b className="text-red-500">{remainCount}</b> )
            </h2>
          </Dialog.Title>
        </div>
      </div>

      <div className="flex flex-col items-center">
        <span className="my-3" />
        <img
          src="../../sidePipe.png"
          className="w-24 h-24 md:w-28 md:h-28 bg-white rounded-md border"
          alt=""
        />
        <p className="font-semibold mt-2">{estimate.name}</p>
        <p className="text-gray-500 mb-6">{estimate.standard}</p>

        <div className="w-60">
          <DialogBodyPipeSizesDropdown
            customPipeSizes={estimate.customPipeSizes}
            chosenSize={estimate.customPipeSizes[0]}
            onSizeDropDownChosen={(chosenSize) => {
              // console.log(`estimate.id ${estimate.id}`);
              // console.log(`chosenSize ${chosenSize}`);
              handleSidePipeAdd(estimate.id, chosenSize);
            }}
          />
        </div>

        <span className="h-72" />
      </div>
      {estimates
        .find((est) => est.id === estimate.id)
        .sidePipeOptions.items.map((e) => {
          return (
            <PipeOptionItem
              key={e.size}
              estimate={estimates.find((est) => est.id === estimate.id)}
              pipeSize={e.size}
              remainCount={remainCount}
              type={"side"}
            />
          );
        })}

      <div className="bg-gray-50 px-4 p-5 mt-2">
        <div className="mt-3 text-center sm:mt-0">
          <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
            <div className="flex gap-10 justify-center">
              {activeDialogIndex !== 0 && (
                <Button
                  variant="contained"
                  color="info"
                  onClick={prevSlide}
                  startIcon={<ArrowBackIcon />}
                >
                  이전
                </Button>
              )}

              {activeDialogIndex !== dialogBodies.length - 1 && (
                <Button
                  variant="contained"
                  color="info"
                  disabled={!goodToGo}
                  onClick={nextSlide}
                  endIcon={<ArrowForwardIcon />}
                >
                  다음
                </Button>
              )}
            </div>
          </Dialog.Title>
        </div>
      </div>
    </div>
  );
}
