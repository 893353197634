import React, { useContext } from "react";
import { EstimateContext } from "../context/EstimatesContext";
import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import ToastHelper from "../helpers/ToastHelper";

export default function CouplingPartsDialog({ open, setOpen, couplingParts, msg }) {
  const { handleAdd } = useContext(EstimateContext);
  const cancelButtonRef = useRef(null);
  const handleClick = (est) => {
    const result = handleAdd(est);
    if (result) {
      ToastHelper.showAddedToast();
      setOpen(!open);
    }
  };

  const parts = (
    <ul className="divide-y divide-gray-100 w-full">
      {couplingParts.map((part) => (
        <button
          key={part.id}
          className="flex justify-between py-3 hover:bg-gray-50 w-full cursor-auto"
          onClick={() => handleClick(part)}
        >
          <div className="flex min-w-0 gap-x-4 text-start">
            <img
              className="h-14 w-14 flex-none rounded-full bg-gray-50"
              src={part.thumbnail}
              alt=""
            />
            <div className="min-w-0 flex-auto">
              <p className="text-base font-semibold leading-6 text-gray-900">{part.name}</p>
              <p className="mt-1 text-sm leading-5 text-gray-500">{part.standard}</p>
            </div>
          </div>
        </button>
      ))}
    </ul>
  );

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-gray-50 px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        세트 제품 추천
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">{msg}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-white px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  {parts}
                </div>

                {/* 이 버튼을 빼면 이상하게 콘솔에 경고가 뜸  */}
                <button
                  ref={cancelButtonRef}
                  onClick={() => setOpen(false)}
                  className="ml-3  hidden justify-center px-4 py-2 text-sm font-medium text-indigo-700 bg-indigo-100 border border-transparent rounded-md hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Close
                </button>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
