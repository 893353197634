import React from "react";
import { Typography } from "@material-tailwind/react";

export default function SubText({ partName, autoBoltSet, autoConSocket }) {
  let text = "";
  // 전산 볼트와 연결 소켓이 자동으로 추가됩니다.
  if (partName === "tSocket") {
    if (autoBoltSet && !autoConSocket) {
      text = "전산 볼트가 7개씩 자동 추가 됩니다.";
    } else if (!autoBoltSet && autoConSocket) {
      text = "연결 소켓이 3개씩 자동 추가 됩니다.";
    } else if (!autoBoltSet && !autoConSocket) {
      text = "";
    } else {
      text = "전산 볼트(7개) 와 연결 소켓(3개)이 자동으로 추가됩니다.";
    }
  }
  if (partName === "lSocket") {
    if (autoBoltSet && !autoConSocket) {
      text = "전산 볼트가 5개씩 자동 추가 됩니다.";
    } else if (!autoBoltSet && autoConSocket) {
      text = "연결 소켓이 2개씩 자동 추가 됩니다.";
    } else if (!autoBoltSet && !autoConSocket) {
      text = "";
    } else {
      text = "전산 볼트(5개) 와 연결 소켓(2개)이 자동으로 추가됩니다.";
    }
  }
  if (partName === "crossSocket") {
    if (autoBoltSet && !autoConSocket) {
      text = "전산 볼트가 10개씩 자동 추가 됩니다.";
    } else if (!autoBoltSet && autoConSocket) {
      text = "연결 소켓이 4개씩 자동 추가 됩니다.";
    } else if (!autoBoltSet && !autoConSocket) {
      text = "";
    } else {
      text = "전산 볼트(10개) 와 연결 소켓(4개)이 자동으로 추가됩니다.";
    }
  }
  return (
    <Typography variant="small" color="gray" className="font-normal">
      {text}
    </Typography>
  );
}
