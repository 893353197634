import React, { useContext, useState, useEffect } from "react";
import { ConstructionContext } from "../../context/ConstructionContext";

export default function Forklift() {
  const [selectedOption, setSelectedOption] = useState("");
  const { constEstimate, setAutoUseForklift } = useContext(ConstructionContext);
  const handleChange = (e) => {
    const value = e.target.value;
    setSelectedOption(value);
    if (value === "use") {
      setAutoUseForklift(true);
    } else {
      setAutoUseForklift(false);
    }
  };

  useEffect(() => {
    if (constEstimate.useForklift === null) {
      return;
    }

    if (constEstimate.useForklift === true) {
      setSelectedOption("use");
    } else {
      setSelectedOption("noUse");
    }
  }, [constEstimate.useForklift]);
  return (
    <fieldset className="field-set-style">
      <legend className="label-style">양중시 지게차 사용 유무</legend>
      <div
        className={`mt-4 space-y-6 border p-5  rounded ${
          constEstimate.constructionWay === "dry" ? "sm:py-11" : ""
        }`}
      >
        <div className="flex items-center gap-x-3">
          <input
            id="use"
            name="use"
            type="radio"
            value="use"
            checked={selectedOption === "use"}
            className={`h-4 w-4 border-gray-300 text-red-600 focus:ring-red-600 ${
              selectedOption === "use" ? "accent-red-500" : ""
            }`}
            onChange={handleChange}
          />
          <label htmlFor="use" className="radio-label-style">
            지게차 사용
          </label>
        </div>

        <div className="flex items-center gap-x-3">
          <input
            id="noUse"
            name="noUse"
            type="radio"
            value="noUse"
            checked={selectedOption === "noUse"}
            className={`h-4 w-4 border-gray-300 text-red-600 focus:ring-red-600 ${
              selectedOption === "noUse" ? "accent-red-500" : ""
            }`}
            onChange={handleChange}
          />
          <label htmlFor="noUse" className="radio-label-style">
            지게차 사용 안함
          </label>
        </div>
      </div>
    </fieldset>
  );
}
