import React, { useContext } from "react";
import DatePicker from "react-datepicker";
import ko from "date-fns/locale/ko";
import "react-datepicker/dist/react-datepicker.css";
import { PdfContext } from "../context/PdfContext";

export default function PaymentDate({ selectedDate, setSelectedDate }) {
  const { setPaymentDate } = useContext(PdfContext);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setPaymentDate(date);
  };

  return (
    <div>
      <p className="label-style mb-2">입금 예정일</p>
      <DatePicker
        locale={ko}
        selected={selectedDate}
        onChange={handleDateChange}
        minDate={new Date()}
        dateFormat="yyyy년 MM월 dd일"
        className="p-2 border rounded"
        placeholderText="입금 예정일을 선택해주세요"
      />
    </div>
  );
}
