import { StyleSheet } from '@react-pdf/renderer'

export const styles = StyleSheet.create({
  page: {
    fontSize: 11,
    paddingTop: 30,
    paddingLeft: 10,
    paddingRight: 10,
    lineHeight: 1.5,
    paddingBottom: 30,
    flexDirection: 'column'
  },
  border: {
    position: 'absolute',
    fontSize: 9,
    top: 0,
    bottom: 10,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: '#1f2937',
    height: '783px',
    width: '557px',
    border: '1.5px solid #1f2937',
    borderRadius: '10px'
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 9,
    bottom: 10,
    left: 0,
    right: 0,
    textAlign: 'center'
  }
})
