import React, { useContext, useEffect, useState } from "react";
import { Dialog } from "@headlessui/react";
import { Button } from "@mui/material";
import CompanyName from "./CompanyName";
import Phone from "./construction/Phone";
import Email from "./construction/Email";
import { ReportPDF } from "./PDF/index";
import { PdfContext } from "../context/PdfContext";
import { EstimateContext } from "../context/EstimatesContext";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export default function AllPdfDialogBody({ hasPrevButton }) {
  const { pdfData, dialogBodies, makeAllEstimatesToPdfData } = useContext(PdfContext);
  const { setActiveDialogIndex, activeDialogIndex } = useContext(EstimateContext);
  const [firstInit, setFirstInit] = useState(true);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const prevSlide = () => {
    setActiveDialogIndex(
      (prevSlide) => (prevSlide - 1 + dialogBodies.length) % dialogBodies.length
    );
  };

  useEffect(() => {
    if (activeDialogIndex === dialogBodies.length - 1) {
      if (firstInit) {
        makeAllEstimatesToPdfData();
        setFirstInit(false);
      }
    }
  }, [activeDialogIndex, dialogBodies.length, firstInit, makeAllEstimatesToPdfData]);

  return (
    <div className={`flex flex-col`}>
      <div className="bg-gray-50 px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
        <div className="mt-3 text-center sm:mt-0">
          <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
            고객정보 입력
          </Dialog.Title>
        </div>
      </div>

      <div className="bg-white px-4 py-3 my-4 flex flex-col gap-6">
        <>
          <CompanyName isForPdf={true} />
          <Phone isForPdf={true} />
          <Email isForPdf={true} />
        </>
        <ReportPDF
          disabled={
            pdfData.client.name === "" ||
            !emailRegex.test(pdfData.client.email) ||
            pdfData.client.phone === ""
          }
          title={`코리아트렌치 통합견적서 (${pdfData.client.name})`}
        />
      </div>

      {hasPrevButton && (
        <div className="bg-gray-50 px-4 p-5">
          <div className="mt-3 text-center sm:mt-0">
            <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
              <div className="flex gap-10 justify-center">
                <Button
                  variant="contained"
                  color="info"
                  onClick={prevSlide}
                  startIcon={<ArrowBackIcon />}
                >
                  이전
                </Button>
              </div>
            </Dialog.Title>
          </div>
        </div>
      )}
    </div>
  );
}
