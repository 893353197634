import React, { useContext } from "react";
// import { ConstructionContext } from "../../context/ConstructionContext";
import { PdfContext } from "../../context/PdfContext";

export default function JibunAddress() {
  // const { constEstimate } = useContext(ConstructionContext);
  const { pdfData } = useContext(PdfContext);
  return (
    <div className="sm:col-span-3">
      <label htmlFor="jibun_address" className="label-style">
        지번 주소
      </label>
      <div className="mt-2">
        <input
          type="text"
          name="jibun_address"
          id="jibun_address"
          autoComplete="address-level1"
          className="one-line-input-style bg-gray-50"
          placeholder="주소 찾기 버튼을 누르세요"
          disabled={true}
          readOnly={true}
          value={pdfData.order.jibunAddress}
        />
      </div>
    </div>
  );
}
