import React from "react";
import PartsThumbnail from "./ui/PartsThumbnail";
import ItemTitle from "./ui/ItemTitle";
import Utils from "../Utils";

export default function SummaryConnectSocketPrice({ estimate }) {
  return (
    <div className="flex">
      <PartsThumbnail url={estimate.conSocketThumbnail} />

      <div className="flex-1 flex justify-between items-center px-0 vsp:px-4 py-2">
        {/* button hanler  */}
        <div className="pl-3">
          <ItemTitle title={"연결 소켓"} autoSetCount={estimate.conSocketAutomaticIncrease} />
        </div>

        {/* price and meter  */}
        <div className="flex flex-col justify-center text-right">
          <p className="">{estimate.connectSocketCount}개</p>
          <p className="font-bold">
            {Utils.addComma(estimate.connectSocketCount * estimate.conSocketPrice)}원
          </p>
        </div>
      </div>
    </div>
  );
}
