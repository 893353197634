import PropTypes from "prop-types";
import { styles } from "../styled";
import { Page, Text, View } from "@react-pdf/renderer";
import { PDFGrid } from "../PDFGrid";
import { PDFText } from "../PDFText";

PageThree.propTypes = {
  data: PropTypes.array,
};

export function PageThree({ data }) {
  return (
    <Page size="A4" style={styles.page}>
      <PDFGrid mv={0} pa={0} row="column">
        <PDFGrid mh={9} pa={"2px"} pt={1} pb={10} row="column" pr={20}>
          <View style={styles.border} />
          <View
            style={{
              borderBottomColor: "#1f2937",
              borderBottomWidth: "1.5px",
              padding: 0,
              margin: 0,
              borderLeftColor: "white",
              borderRightColor: "white",
              borderTopColor: "white",
              alignItems: "center",
              justifyContent: "center",
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
            }}
          >
            <PDFText text={"비고"} fontSize="14px" fontFamily="Bold" mv={15} pa={"auto"} />
          </View>
          <PDFGrid mh={0} row="column">
            {data.estimate.extraInfos.map((e, i) => (
              <PDFGrid ma={0} pa={2}>
                <PDFText text={e} fontSize="11px" fontFamily="Regular" />
              </PDFGrid>
            ))}
          </PDFGrid>
        </PDFGrid>
      </PDFGrid>
      <Text
        style={styles.pageNumber}
        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
        fixed
      />
    </Page>
  );
}
