import React from "react";
import PartsThumbnail from "./ui/PartsThumbnail";
import Utils from "../Utils";
import ItemTitle from "./ui/ItemTitle";

export default function SummaryTwoInOneSideCapPrice({ estimate }) {
  return (
    <div className="flex">
      <PartsThumbnail url={estimate.straightPipeThumbnail} />

      <div className="flex-1 flex justify-between items-center px-0 vsp:px-4 py-2  ">
        {/* button handler  */}
        <div className="pl-3">
          <ItemTitle title={"직선 파이프 소켓"} />
        </div>

        {/* price and meter  ✅ */}
        <div className="flex flex-col justify-center text-right">
          <p className="">{estimate.twoInOneSideCapCount}개</p>
          <p className="font-bold">
            {Utils.addComma(estimate.twoInOneSideCapCount * estimate.twoInOneSideCapPrice)}원
          </p>
        </div>
      </div>
    </div>
  );
}
