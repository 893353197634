import { Image } from "@react-pdf/renderer";
// import Logo from '../../../img/Logo.png';
import PropTypes from "prop-types";
import { PDFGrid } from "../PDFGrid";
import { PDFText } from "../PDFText";

PDFPHeader.propTypes = {
  data: PropTypes.array,
};

export function PDFPHeader({ data }) {
  return (
    <PDFGrid mh={2} mv={0} width="72%" row="column">
      <PDFGrid border borderRadius="10px" pa={2}>
        <PDFGrid width="40%" borderTopLeftRadius={"10px"} pl={"12px"}>
          <Image src={data?.company?.logo} style={{ width: "110px", height: "80px" }} />
          {/* <Image src={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRJMePQdhs6U6PN7OhcW_ZeJnasPKSiI6wmp6soPoB2&s'}style={{ width: '110px', height: '80px' }} /> */}
        </PDFGrid>
        <PDFGrid width="60%" row="column" borderTopRightRadius={"10px"}>
          <PDFGrid justifyContent="center" pa={0}>
            <PDFText text={data?.title ?? ""} fontSize="18px" fontFamily="Bold" />
          </PDFGrid>
          <PDFText
            text={`사업자 번호 : ${data?.company?.bizNumber ?? ""}`}
            fontSize="9px"
            textAlign={"left"}
            pt="3px"
            fontFamily="Regular"
          />
          <PDFText
            text={`전화/팩스 : ${data?.company?.phone ?? ""} | ${data?.company?.fax ?? ""}`}
            fontSize="9px"
            textAlign={"left"}
            pt="3px"
            fontFamily="Regular"
          />
          <PDFText
            text={`소재지 : ${data?.company?.address ?? ""}`}
            fontSize="9px"
            textAlign={"left"}
            pt="3px"
            fontFamily="Regular"
          />
        </PDFGrid>
      </PDFGrid>
      <PDFGrid mt={4} border borderRadius="10px" pa={3.5} row="column" pl={"12px"} pv={12}>
        <PDFGrid pa={1}>
          <PDFGrid pa={1} width="12%">
            <PDFText text="고객명 :" fontSize="9px" fontFamily="Bold" />
          </PDFGrid>
          <PDFGrid pa={1} width="88%" alignItems="center">
            <PDFText
              text={data?.client?.name ?? ""}
              fontSize="9px"
              // color='#544a4a'
              fontFamily="Bold"
              pt={0}
            />
          </PDFGrid>
        </PDFGrid>

        <PDFGrid pa={1}>
          <PDFGrid pa={1} width="12%">
            <PDFText text="전화번호 :" fontSize="9px" fontFamily="Bold" />
          </PDFGrid>
          <PDFGrid pa={1} width="88%" alignItems="center">
            <PDFText
              text={data?.client?.phone ?? ""}
              fontSize="9px"
              // color='#544a4a'
              pt={0}
              fontFamily="Regular"
            />
          </PDFGrid>
        </PDFGrid>

        <PDFGrid pa={1}>
          <PDFGrid pa={1} width="12%">
            <PDFText text="이메일 :" fontSize="9px" fontFamily="Bold" />
          </PDFGrid>
          <PDFGrid pa={1} width="88%" alignItems="center">
            <PDFText
              text={data?.client?.email ?? ""}
              fontFamily="Regular"
              fontSize="9px"
              // color='#544a4a'
              pt={0}
            />
          </PDFGrid>
        </PDFGrid>

        {/* <PDFGrid pa={1}>
          <PDFGrid pa={1} width='12%'>
            <PDFText text='도착지 :' fontSize='9px' fontFamily='Bold' />
          </PDFGrid>
          <PDFGrid pa={1} width='88%' alignItems='center'>
            <PDFText
              text={data?.client?.destination ?? ''}
              fontSize='9px'
              // color='#544a4a'
              pt={0}
              fontFamily='Regular'
            />
          </PDFGrid>
        </PDFGrid> */}
      </PDFGrid>
    </PDFGrid>
  );
}
