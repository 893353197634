import React, { useContext, useState } from "react";

import { DocumentTextIcon } from "@heroicons/react/24/solid";
import FindAddress from "../components/FindAddress";
import ManagerName from "../components/construction/ManagerName";
import { ConstructionContext } from "../context/ConstructionContext";
import Phone from "../components/construction/Phone";
import Email from "../components/construction/Email";
import ConstructionName from "../components/construction/ConstructionName";
import RoadAddress from "../components/construction/RoadAddress";
import JibunAddress from "../components/construction/JibunAddress";
import LocationInput from "../components/construction/LocationInput";
import DetailAddress from "../components/construction/DetailAddress";
import ExtraInfo from "../components/construction/ExtraInfo";
import Boyang from "../components/construction/Boyang";
import ConstructionWays from "../components/construction/ConstructionWays";
import Level from "../components/construction/Level";
import Slope from "../components/construction/Slope";
import HeightWithoutIron from "../components/construction/HeightWithoutIron";
import { EstimateContext } from "../context/EstimatesContext";
import SummaryEstimateCard from "../components/SummaryEstimateCard";
import Forklift from "../components/construction/Forklift";
import ForkliftCostBurden from "../components/construction/ForkliftCostBurden";
import CompanyName from "../components/CompanyName";

export default function ConstructionPage() {
  //
  const { setDrawingBase64, constEstimate } = useContext(ConstructionContext);

  const { estimates } = useContext(EstimateContext);
  const [fileName, setFileName] = useState("");
  const [payload, setPayload] = useState({
    managerName: "",
    phone: "",
    email: "",
    constructionName: "",
    roadAddress: "",
    jibunAddress: "",
    detailAddress: "",
    location: "",
    needBoyang: null,
    needLevel: null,
    needSlope: null,
    constructionWay: null,
    heightWithoutIron: null,
    paymentDate: null,
    canSupportCrane: false,
    canSupportForklift: false,
    canGoForkliftToSite: false,
    extraInfo: "",
    drawingBase64: null,
  });

  const TD_LABEL_STYLE =
    "w-2/6 sm:w-1/6 px-15 h-22 text-center bg-gray-200 p-3 border-b border-b-white";
  const TD_VALUE_STYLE =
    "w-4/6 sm:w-5/6 h-22 text-left pl-6 p-5 border-b text-black overflow-x-hidden";

  const handleFileChange = (e) => {
    const files = e.target.files;
    if (files !== null) {
      setFileName(files[0].name);
      // setFileSize(files[0].size);

      let reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onloadend = () => {
        const base64 = reader.result;
        if (base64) {
          let base64Sub = base64.toString();
          // setFileInfo(base64Sub);
          setDrawingBase64(base64Sub);
        }
      };
      // console.log(fileSize, fileInfo);
    }
    // handleFileUpload(files);
  };

  // 폼 제출 시 호출되는 함수
  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload2 = {
      managerName: constEstimate.managerName,
      phone: constEstimate.phone,
      email: constEstimate.email,
      constructionName: constEstimate.constructionName,
      roadAddress: constEstimate.roadAddress,
      jibunAddress: constEstimate.jibunAddress,
      detailAddress: constEstimate.detailAddress,
      location: constEstimate.location,
      needBoyang: constEstimate.needBoyang ? "보양 작업 필요 ✅" : "보양 작업 불필요 ❌",
      needLevel: constEstimate.needLevel ? "레벨 작업 필요 ✅" : "레벨 작업 불필요 ❌",
      needSlope: constEstimate.needSlope ? "구배 작업 필요 ✅" : "구배 작업 불필요 ❌",
      constructionWay: constEstimate.constructionWay === "dry" ? "건식 시공" : "습식 시공",
      heightWithoutIron: `${constEstimate.heightWithoutIron}mm`,
      paymentDate: null,
      canSupportCrane: constEstimate.canSupportCrane
        ? "크레인 지원 가능 ✅"
        : "크레인 지원 불가 ❌",
      canSupportForklift: constEstimate.canSupportForklift
        ? "지게차 지원 가능 ✅"
        : "지게차 지원 불가 ❌",
      canGoForkliftToSite: constEstimate.canGoForkliftToSite
        ? "지게차 현장 진입 가능 ✅"
        : "지게차 현장 진입 불가 ❌",
      extraInfo: constEstimate.extraInfo,
      drawingBase64: constEstimate.drawingBase64,
      fileName: fileName,
    };
    setPayload(payload2);

    return;

    // const host =
    //   window.location.hostname === "localhost" ? "http://koreatrenchorder.cafe24app.com" : "api";

    // try {
    //   // const response = await axios.post(`${host}/estimate/construction`, payload);
    //   const response = await fetch(`${host}/estimate/construction`, {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify(payload),
    //   });

    //   if (response.ok) {
    //     console.log("폼이 성공적으로 제출되었습니다.");
    //   } else {
    //     console.error("폼 제출에 실패했습니다.");
    //   }
    // } catch (error) {
    //   console.error("오류 발생:", error);
    // }
    //
    // console.log("폼이 제출되었습니다!");
  };

  return (
    <>
      <form
        className="max-w-screen-xl mx-auto bg-white border rounded-lg mt-20 p-2 sm:p-8"
        onSubmit={handleSubmit}
      >
        <div className="border-b border-gray-900/10 pb-12">
          <SmallTitle title="담당자 정보" />
          <SmallSubTitle subTitle="담당자분의 정보를 입력해주세요" />

          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <ManagerName />
            <CompanyName />
            <Phone />
            <Email />
          </div>
        </div>
        <div className="space-y-12">
          <div className="py-6">
            <SmallTitle title="시공 현장 정보" />
            <SmallSubTitle subTitle="시공 현장에 대한 정보를 입력해주세요" />

            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <ConstructionName />
              <div className="sm:col-span-3" />

              <RoadAddress />
              <JibunAddress />

              <div className="sm:col-span-3">
                <div className="flex items-end">
                  <DetailAddress />
                  <FindAddress />
                </div>
              </div>

              <LocationInput />
              <ExtraInfo />

              <Boyang />
              <ConstructionWays />

              <Level />
              <Slope />
              {constEstimate.constructionWay === "dry" && <HeightWithoutIron />}
              <Forklift />

              {constEstimate.useForklift && <ForkliftCostBurden />}
              <div className="col-span-full mt-4">
                <div className="flex items-center">
                  <label htmlFor="about" className="label-style">
                    도면 첨부
                  </label>
                  <span className="mr-1" />
                  <p className="text-xs leading-6 text-gray-600">( 선택 사항 )</p>
                </div>
                <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                  <div className="text-center">
                    <DocumentTextIcon
                      className="mx-auto h-12 w-12 text-gray-300"
                      aria-hidden="true"
                    />
                    <div className="mt-4 flex text-sm leading-6 text-gray-600 flex-col">
                      <label
                        htmlFor="file-upload"
                        className="relative cursor-pointer rounded-md bg-white font-semibold text-blue-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-600 focus-within:ring-offset-2 hover:text-blue-500"
                      >
                        <span>도면 업로드</span>
                        <input
                          id="file-upload"
                          name="file-upload"
                          accept=".dwg, .dxf, .zip"
                          type="file"
                          className="sr-only"
                          onChange={handleFileChange}
                        />
                      </label>
                      {fileName === "" ? (
                        <div>
                          <p className="">.dwg .dxf .zip 파일만 가능합니다</p>
                        </div>
                      ) : (
                        <p className="pl-2">{fileName}</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img src="../../email.png" alt="" />
        <p>{estimates.length}</p>
        <div className="">
          {estimates && estimates.map((est) => <SummaryEstimateCard key={est.id} estimate={est} />)}
        </div>
        <div className="mt-12 flex items-center justify-center gap-x-6 ">
          <button
            type="submit"
            // type="button"
            className=" text-gray-900  hover:text-white bg-white hover:bg-green-500 dark:hover:bg-green-700  border border-green-100 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-10 py-3 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white mb-2 transition ease-in-out delay-300  hover:-translate-y-1 hover:scale-101"
          >
            <img className="mr-3" src="../../estimate.png" width={50} height={50} alt="" />
            <p className="text-lg">시공 견적 요청</p>
          </button>
        </div>
        {/* 
          밑에는 mail HTML 
        */}
        <table className="border-collapse w-full border" border="1">
          <tbody>
            <tr className="h-22 p-4 border-b">
              <td className={TD_LABEL_STYLE}>담당자 이름</td>
              <td className={TD_VALUE_STYLE}>{payload.managerName}</td>
            </tr>
            <tr className="h-22 p-4 text-center">
              <td className={TD_LABEL_STYLE}>연락처</td>
              <td className={TD_VALUE_STYLE}>{payload.phone}</td>
            </tr>
            <tr className="h-22 text-center">
              <td className={TD_LABEL_STYLE}>이메일</td>
              <td className={TD_VALUE_STYLE}>{payload.email}</td>
            </tr>
            <tr className="h-22 text-center">
              <td className={TD_LABEL_STYLE}>공사명</td>
              <td className={TD_VALUE_STYLE}>{payload.constructionName}</td>
            </tr>
            <tr className="h-22 text-center">
              <td className={TD_LABEL_STYLE}>지번 주소</td>
              <td className={TD_VALUE_STYLE}>{payload.jibunAddress}</td>
            </tr>
            <tr className="h-22 text-center">
              <td className={TD_LABEL_STYLE}>도로명 주소</td>
              <td className={TD_VALUE_STYLE}>{payload.roadAddress}</td>
            </tr>
            <tr className="h-22 text-center">
              <td className={TD_LABEL_STYLE}>상세 주소</td>
              <td className={TD_VALUE_STYLE}>{payload.detailAddress}</td>
            </tr>
            <tr className="h-22 text-center">
              <td className={TD_LABEL_STYLE}>시공 위치</td>
              <td className={TD_VALUE_STYLE}>{payload.location}</td>
            </tr>
            <tr className="h-22 text-center">
              <td className={TD_LABEL_STYLE}>시공 방식</td>
              <td className={TD_VALUE_STYLE}>{payload.constructionWay}</td>
            </tr>
            <tr className="h-22 text-center">
              <td className={TD_LABEL_STYLE}>무근고 높이</td>
              <td className={TD_VALUE_STYLE}>{payload.heightWithoutIron}</td>
            </tr>
            <tr className="h-22 text-center">
              <td className={TD_LABEL_STYLE}>보양 작업 유무</td>
              <td className={TD_VALUE_STYLE}>{payload.needBoyang}</td>
            </tr>
            <tr className="h-22 text-center">
              <td className={TD_LABEL_STYLE}>레벨 작업 유무</td>
              <td className={TD_VALUE_STYLE}>{payload.needLevel}</td>
            </tr>
            <tr className="h-22 text-center">
              <td className={TD_LABEL_STYLE}>구배 유무</td>
              <td className={TD_VALUE_STYLE}>{payload.needSlope}</td>
            </tr>
            <tr className="h-22 text-center">
              <td className={TD_LABEL_STYLE}>양중 여건</td>
              <td className={TD_VALUE_STYLE}>
                <p className="my-4">{payload.canSupportCrane}</p>
                <p className="mb-4">{payload.canSupportForklift}</p>
                <p className="mb-4">{payload.canGoForkliftToSite}</p>
              </td>
            </tr>
            <tr className="h-22 text-center">
              <td className={TD_LABEL_STYLE}>특이사항</td>
              <td className={TD_VALUE_STYLE}>{payload.extraInfo}</td>
            </tr>
          </tbody>
        </table>
      </form>
    </>
  );
}

function SmallTitle({ title }) {
  return <h2 className="text-md font-semibold leading-7 text-gray-900">{title}</h2>;
}

function SmallSubTitle({ subTitle }) {
  return <p className="mt-0.5 text-sm leading-6 text-gray-600"> {subTitle}</p>;
}
