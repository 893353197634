import Swal from "sweetalert2";

export default class DialogHelper {
  static showDialog({ icon = "success", title, msg }) {
    return Swal.fire({
      icon: icon,
      title: title,
      html: msg,
      showCancelButton: false,
      confirmButtonText: "확인",
      confirmButtonColor: "#2196F3",
      padding: 24,
    });
  }

  static showInfoText(title) {
    Swal.fire({
      icon: "warning",
      text: title,
      showCancelButton: false,
      confirmButtonText: "확인",
      confirmButtonColor: "#FF0000",
      cancelButtonText: "취소",
      padding: 24,
    });
  }

  static showDeleteDialog() {
    return Swal.fire({
      icon: "warning",
      text: "정말 삭제하시겠어요?",
      showCancelButton: true,
      confirmButtonText: "삭제",
      confirmButtonColor: "#FF0000",
      cancelButtonText: "취소",
      padding: 24,
    });
  }

  static showConSocketAutoDialog() {
    return Swal.fire({
      icon: "info",
      text: `연결 소켓은 트렌치 본 수에 따라 자동추가됩니다. 수동으로 전환하시겠습니까?`,
      showCancelButton: true,
      confirmButtonText: "수동 전환",
      confirmButtonColor: "#2196F3",
      cancelButtonText: "취소",
      padding: 24,
    });
  }

  static showSentOrder() {
    return Swal.fire({
      icon: "success",
      title: "발주 완료",
      html: `발주서가 코리아트렌치 메일로 전송되었습니다.<br>입금 확인 후 제품 발송 준비가 시작됩니다.`,
      showCancelButton: false,
      confirmButtonText: "확인",
      confirmButtonColor: "#2196F3",
      padding: 24,
    });
  }

  static showboltSetAutoDialog() {
    return Swal.fire({
      icon: "info",
      text: `전산 볼트셋은 트렌치 본 수에 따라 자동추가됩니다. 수동으로 전환하시겠습니까?`,
      showCancelButton: true,
      confirmButtonText: "수동 전환",
      confirmButtonColor: "#2196F3",
      cancelButtonText: "취소",
      padding: 24,
    });
  }

  static showInqueryDialog() {
    return Swal.fire({
      icon: "info",
      titleText: "032-561-0303",
      text: `해당 제품은 회사에 문의 주세요`,
      showCancelButton: false,
      confirmButtonText: "확인",
      confirmButtonColor: "#2196F3",
      padding: 24,
    });
  }
}
