import { Fragment, useContext } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { ConstructionContext } from "../../context/ConstructionContext";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function LocationDropdown() {
  const { locations, chosenLocation, onLocationChosen } = useContext(ConstructionContext);

  const handleChange = (e) => {
    onLocationChosen(e);
  };

  return (
    <fieldset className="mt-2 sm:col-span-3 sm:mt-0">
      <legend className="label-style">시공 위치</legend>
      <Listbox value={chosenLocation} onChange={handleChange}>
        {({ open }) => (
          <>
            <div className=" relative pt-2 md:pt-3">
              <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
                <div className="flex items-center">
                  {chosenLocation.id !== 0 ? (
                    <img
                      src={chosenLocation.thumbnail}
                      alt=""
                      className="h-10 w-10 flex-shrink-0 rounded-full bg-white"
                    />
                  ) : (
                    <div className="h-10"></div>
                  )}
                  <span className="ml-3 block truncate">{chosenLocation.name}</span>
                </div>
                <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                  <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {locations.map((location) => (
                    <Listbox.Option
                      key={location.id}
                      className={({ active }) =>
                        classNames(
                          active ? "bg-indigo-600 text-white" : "text-gray-900",
                          "relative cursor-default select-none py-2 pl-3 pr-9 "
                        )
                      }
                      value={location}
                    >
                      {({ chosenLocation, active }) => {
                        return (
                          <>
                            <div className="flex items-center">
                              {location.id !== 0 ? (
                                <img
                                  src={location.thumbnail}
                                  alt=""
                                  className="h-10 w-10 flex-shrink-0 rounded-full bg-white"
                                />
                              ) : (
                                <div className="h-10"></div>
                              )}
                              <span
                                className={classNames(
                                  chosenLocation ? "font-semibold " : "font-normal",
                                  "ml-3 block truncate"
                                )}
                              >
                                {location.name}
                              </span>
                            </div>

                            {chosenLocation ? (
                              <span
                                className={classNames(
                                  active ? "text-white" : "text-indigo-600",
                                  "absolute inset-y-0 right-0 flex items-center pr-4 "
                                )}
                              >
                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                              </span>
                            ) : null}
                          </>
                        );
                      }}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </fieldset>
  );
}
