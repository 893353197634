import React, { useContext } from "react";
import { PdfContext } from "../context/PdfContext";

export default function Note() {
  const { setNote, pdfData } = useContext(PdfContext);
  const handleChange = (e) => {
    const value = e.target.value;
    setNote(value);
  };

  return (
    <div>
      <div className="flex items-center justify-between">
        <label htmlFor="company-name" className="label-style">
          비고
        </label>
        <span className="mr-1" />
      </div>

      <div className="mt-2">
        <textarea
          required
          type="text"
          name="note"
          id="note"
          placeholder="제품 가공시 요청사항이나 특이사항을 자세히 적어주세요"
          autoComplete="note"
          className="one-line-input-style"
          onChange={handleChange}
          value={pdfData.order.note}
        />
      </div>
    </div>
  );
}
