import React, { useContext, useEffect, useState } from "react";
import { ConstructionContext } from "../../context/ConstructionContext";

export default function Boyang() {
  const { constEstimate, setNeedBoyang } = useContext(ConstructionContext);
  const [selectedOption, setSelectedOption] = useState("");

  const handleChange = (e) => {
    const value = e.target.value;
    setSelectedOption(value);
    if (value === "needBoyang") {
      setNeedBoyang(true);
    } else {
      setNeedBoyang(false);
    }
  };

  useEffect(() => {
    if (constEstimate.needBoyang === null) {
      return;
    }
    if (constEstimate.needBoyang) {
      setSelectedOption("needBoyang");
    } else {
      setSelectedOption("noNeedBoyang");
    }
  }, [constEstimate.needBoyang]);

  return (
    <fieldset className="field-set-style">
      <legend className="label-style">보양 작업 유무</legend>

      <div className="mt-4 space-y-6 border p-5 rounded">
        <div className="flex items-center gap-x-3">
          <input
            id="boyang-you"
            name="boyangs"
            type="radio"
            value="needBoyang"
            checked={selectedOption === "needBoyang"}
            className={`h-4 w-4 border-gray-300 text-red-600 focus:ring-red-600 ${
              selectedOption === "needBoyang" ? "accent-red-500" : ""
            }`}
            onChange={handleChange}
          />
          <label htmlFor="boyang-you" className="label-style">
            보양작업 유
          </label>
        </div>
        <div className="flex items-center gap-x-3">
          <input
            id="boyang-mu"
            name="boyangs"
            type="radio"
            value="noNeedBoyang"
            checked={selectedOption === "noNeedBoyang"}
            className={`h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600 ${
              selectedOption === "noNeedBoyang" ? "accent-red-500" : ""
            }`}
            onChange={handleChange}
          />
          <label htmlFor="boyang-mu" className="label-style">
            보양작업 무
          </label>
        </div>
      </div>
    </fieldset>
  );
}
