import React, { useContext } from "react";
import { ConstructionContext } from "../../context/ConstructionContext";
import { PdfContext } from "../../context/PdfContext";

export default function Phone({ isForPdf = false }) {
  const { setPhone, constEstimate } = useContext(ConstructionContext);
  const { setClientPhone, pdfData } = useContext(PdfContext);

  const handleChange = (e) => {
    const value = e.target.value;
    if (isForPdf) {
      setClientPhone(value);
    } else {
      setPhone(value);
    }
  };
  return (
    <div className="sm:col-span-3">
      <label htmlFor="phone" className="label-style">
        연락처
      </label>
      <div className="mt-2">
        <input
          required
          type="text"
          name="phone"
          id="phone"
          autoComplete="phone"
          className="one-line-input-style"
          onChange={handleChange}
          value={isForPdf ? pdfData.client.phone : constEstimate.phone}
        />
      </div>
    </div>
  );
}
