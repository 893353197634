import React, { useContext } from "react";
import { PdfContext } from "../../context/PdfContext";

export default function DetailAddress() {
  const { pdfData, setDetailAddress } = useContext(PdfContext);
  const handleChange = (e) => {
    const value = e.target.value;
    setDetailAddress(value);
  };
  return (
    <div className="flex-1">
      <label htmlFor="detail-address" className="label-style">
        상세 주소
      </label>
      <div className="mt-2 mr-4">
        <input
          type="text"
          name="detail-address"
          id="detail-address"
          autoComplete="detail-address"
          className="one-line-input-style"
          onChange={handleChange}
          value={pdfData.order.detailAddress}
        />
      </div>
    </div>
  );
}
