import React, { useContext } from "react";
import { useState } from "react";
import Dropdowns from "./Dropdowns";
import { EstimateContext } from "../context/EstimatesContext";
import SearchTextField from "./SearchTextField";

export default function SearchTabs() {
  const [selectedTab, setSelectedTab] = useState("검색");
  const { estimates } = useContext(EstimateContext);

  return (
    <div
      className={`flex-1  ${estimates.length === 0 ? "lg:max-w-md" : "w-full"} p-4 md:p-0 md:mx-4`}
    >
      <ul className=" text-sm font-medium text-center text-gray-500  shadow flex  dark:text-gray-400">
        <li
          className={`w-full focus-within:z-10 ${
            selectedTab === "검색" ? "border-b-2 border-red-500 " : ""
          }`}
        >
          <p
            className={`inline-block w-full p-4 ${
              selectedTab === "검색" ? "text-gray-900 bg-gray-100" : "bg-white"
            }  dark:border-gray-700    active  dark:bg-gray-700 dark:text-white cursor-pointer`}
            onClick={() => setSelectedTab("검색")}
          >
            검색
          </p>
        </li>

        <li
          className={`w-full focus-within:z-10  ${
            selectedTab === "선택" ? "border-b-2 border-red-500 " : ""
          }`}
        >
          <p
            className={`inline-block w-full p-4 ${
              selectedTab === "선택" ? "text-gray-900 bg-gray-100" : "bg-white"
            }  dark:border-gray-700 hover:text-gray-700 hover:bg-gray-50  dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700 cursor-pointer`}
            onClick={() => setSelectedTab("선택")}
          >
            선택
          </p>
        </li>
      </ul>

      <div className="pt-2">
        {selectedTab === "검색" && <SearchTextField />}
        {selectedTab === "선택" && <Dropdowns />}
      </div>
    </div>
  );
}
