import React from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";

export default function PlusButton({ onClick }) {
  return (
    <button tabIndex={-1} onClick={onClick}>
      <AiOutlinePlusCircle className="w-7 h-7 text-gray-800 hover:text-gray-600" />
    </button>
  );
}
