import React from "react";
import PartsThumbnail from "./ui/PartsThumbnail";
import Utils from "../Utils";
import ItemTitle from "./ui/ItemTitle";

export default function SummaryStraightPartPrice({ estimate }) {
  return (
    <div className="flex">
      <PartsThumbnail url={estimate.straightPipeThumbnail} />

      <div className="flex-1 flex justify-between items-center px-0 vsp:px-4 py-2">
        <div className="pl-3">
          <ItemTitle title={"파이프 가공 (측면)"} />
        </div>

        <div className="flex flex-col justify-center text-right">
          <p className="">{estimate.pipeStraightCount}개</p>
          <p className="font-bold">
            {Utils.addComma(estimate.pipeStraightCount * estimate.pipePrice)}원
          </p>
        </div>
      </div>
    </div>
  );
}
