import React, { useContext } from "react";
import { EstimateContext } from "../context/EstimatesContext";
import Utils from "../Utils";
import PlusButton from "./ui/PlusButton";
import MinusButton from "./ui/MinusButton";
import PartsThumbnail from "./ui/PartsThumbnail";
import ItemTitle from "./ui/ItemTitle";
import CountInput from "./ui/CountInput";

export default function LPartCountAndPrice({ estimate }) {
  const { setLPartCount, handleIncreaseLPartCount, handleDecreaseLPartCount } =
    useContext(EstimateContext);

  const blurHandler = (e) => {
    const value = e.target.value;
    if (value === "" || value === "0") {
      setLPartCount(estimate.id, 0);
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;

    // 오직 숫자만
    if (/^[0-9]*$/.test(value) && value !== "") {
      const count = parseInt(value);
      setLPartCount(estimate.id, count);
      return;
    }

    // 숫자가 0이면 -> 1로 변경
    if (value === "0") {
      setLPartCount(estimate.id, 0);
      return;
    }

    if (value === "") {
      setLPartCount(estimate.id, "");
    }
  };

  const handleIncrease = () => {
    handleIncreaseLPartCount(estimate.id);
  };

  const handleDecrease = () => {
    handleDecreaseLPartCount(estimate.id);
  };

  return (
    <div className="flex">
      <PartsThumbnail url={estimate.lThumbnail} />

      <div className="flex-1 flex justify-between items-center px-0 vsp:px-4 py-2">
        {/* button handler  */}
        <div>
          <ItemTitle title={"L자 가공"} />

          <div className="flex items-center">
            <MinusButton onClick={handleDecrease} />

            <CountInput
              value={estimate.lSocketCount}
              onBlur={blurHandler}
              onChange={handleChange}
            />

            <PlusButton onClick={handleIncrease} />
          </div>
        </div>

        {/* price and meter  */}
        <div className="flex flex-col justify-center text-right">
          <p className="">{estimate.lSocketCount}개</p>
          <p className="font-bold">{Utils.addComma(estimate.lSocketCount * estimate.lPrice)}원</p>
        </div>
      </div>
    </div>
  );
}
