import React, { useContext } from "react";
import { EstimateContext } from "../context/EstimatesContext";
import Utils from "../Utils";
import MinusButton from "./ui/MinusButton";
import PlusButton from "./ui/PlusButton";
import PartsThumbnail from "./ui/PartsThumbnail";
import ItemTitle from "./ui/ItemTitle";
import CountInput from "./ui/CountInput";

export default function TwoInOneBottomCountAndPrice({ estimate }) {
  const {
    set2in1BottomCapCount,
    handleIncrease2in1BottomCapCount,
    handleDecrease2in1BottomCapCount,
  } = useContext(EstimateContext);

  const blurHandler = (e) => {
    const value = e.target.value;
    if (value === "" || value === "0") {
      set2in1BottomCapCount(estimate.id, 0);
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;

    // 오직 숫자만
    if (/^[0-9]*$/.test(value) && value !== "") {
      const count = parseInt(value);
      set2in1BottomCapCount(estimate.id, count);
      return;
    }

    // 숫자가 0이면 -> 1로 변경
    if (value === "0") {
      set2in1BottomCapCount(estimate.id, 0);
      return;
    }
    if (value === "") {
      set2in1BottomCapCount(estimate.id, "");
    }
  };

  const handleIncrease = () => {
    handleIncrease2in1BottomCapCount(estimate.id);
  };

  const handleDecrease = () => {
    handleDecrease2in1BottomCapCount(estimate.id);
  };

  return (
    <div className="flex">
      <PartsThumbnail url={estimate.downPipeThumbnail} />

      <div className="flex-1 flex justify-between items-center px-0 vsp:px-4 py-2">
        {/* button handler  */}
        <div>
          <ItemTitle title={"하부 파이프 소켓"} />

          <div className="flex items-center">
            <MinusButton onClick={handleDecrease} />

            <CountInput
              value={estimate.twoInOneBottomCapCount} // ✅
              onBlur={blurHandler}
              onChange={handleChange}
            />

            <PlusButton onClick={handleIncrease} />
          </div>
        </div>

        {/* price and meter  ✅ */}
        <div className="flex flex-col justify-center text-right">
          <p className="">{estimate.twoInOneBottomCapCount}개</p>
          <p className="font-bold">
            {Utils.addComma(estimate.twoInOneBottomCapCount * estimate.twoInOneBottomCapPrice)}원
          </p>
        </div>
      </div>
    </div>
  );
}
