import React from "react";
import PartsThumbnail from "./ui/PartsThumbnail";
import ItemTitle from "./ui/ItemTitle";
import Utils from "../Utils";

export default function SummaryBoltSetPrice({ estimate }) {
  return (
    <div className="flex">
      <PartsThumbnail url={estimate.boltSetThumbnail} />

      <div className="flex-1 flex justify-between items-center px-0 vsp:px-4 py-2">
        {/* button hanler  */}
        <div className="pl-3">
          {/* <p>{estimate.boltSetAutomaticIncrease.toString()}</p> */}
          <ItemTitle title={"볼트 세트"} autoSetCount={estimate.boltSetAutomaticIncrease} />
        </div>

        {/* Price and meter  */}
        <div className="flex flex-col justify-center text-right">
          <p className="">{estimate.boltSetCount}개</p>
          <p className="font-bold">
            {Utils.addComma(estimate.boltSetCount * estimate.boltSetPrice)}원
          </p>
        </div>
      </div>
    </div>
  );
}
