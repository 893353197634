import { DocumentTextIcon } from "@heroicons/react/20/solid";
import React, { useContext, useState } from "react";
import { PdfContext } from "../context/PdfContext";
import ToastHelper from "../helpers/ToastHelper";

export default function BizCertificate() {
  const { setBizCertificateBase64, setFileName } = useContext(PdfContext);

  const [fileName, setLocalFileName] = useState("");

  const handleFileChange = (e) => {
    try {
      const files = e.target.files;

      if (files !== null) {
        // 파일 용량 제한
        if (files[0].size > 5000000) {
          ToastHelper.showErrorMsg("파일사이즈 5mb 이하만 업로드 가능합니다");
          return;
        }

        // 파일 이름, 확장자 설정
        setLocalFileName(files[0].name); // local
        setFileName(files[0].name); // context

        // 파일 설정
        let reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onloadend = () => {
          const base64 = reader.result;
          if (base64) {
            let base64Sub = base64.toString();
            setBizCertificateBase64(base64Sub);
          }
        };
      }
    } catch {
      // 사용자 파일을 선택하고 취소하면 여기에 Catch
    }
  };

  return (
    <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
      <div className="text-center">
        <DocumentTextIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
        <div className="mt-4 flex text-sm leading-6 text-gray-600 flex-col">
          <label
            htmlFor="file-upload"
            className="relative cursor-pointer rounded-md bg-white font-semibold text-blue-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-600 focus-within:ring-offset-2 hover:text-blue-500"
          >
            <span>사업자 등록증 업로드 (JPG,PNG)</span>

            <input
              id="file-upload"
              name="file-upload"
              accept=".jpg, .png"
              type="file"
              className="sr-only"
              onChange={handleFileChange}
            />
          </label>

          {fileName === "" ? (
            <div>
              <p className="">
                사업자등록증 사진 없이도 발주는 가능합니다만,
                <br />
                추후 직원이 사업자등록증 사진을 요청할 수 있습니다.
              </p>
            </div>
          ) : (
            <p className="pl-2">{fileName}</p>
          )}
        </div>
      </div>
    </div>
  );
}
