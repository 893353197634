import React, { useContext } from "react";
import BigCategory from "./BigCategory";
import SubCategory from "./SubCategory";
import ProductsCategory from "./ProductsCategory";
import { EstimateContext } from "../context/EstimatesContext";
import RecommendationSwitch from "./\bRecommendationSwitch";
import RecommendProductItem from "./RecommendProductItem";

export default function Dropdowns() {
  const { estimates } = useContext(EstimateContext);

  return (
    <div className={`flex-1  ${estimates.length === 0 ? "lg:max-w-md" : "w-full"} px-2 md:pt-3 `}>
      <BigCategory />
      <div className="md:h-3" />
      <SubCategory />
      <div className="md:h-3" />
      <ProductsCategory />
      <div className="md:h-3" />
      {estimates.length === 0 && (
        <p className="text-gray-600 px-2 mt-3 md:mt-0">
          자동 견적 시스템은 고객 여러분들께 24시간 편리하게 견적 서비스를 제공하기 위해
          제작되었습니다. 코리아트렌치는 고객분들의 니즈와 편리한 서비스를 위해 항상 노력하겠습니다.
        </p>
      )}
      <RecommendationSwitch />

      {estimates.length === 0 && <RecommendProductItem />}

      {estimates.length !== 0 && (
        <div className="text-gray-700 mt-4 md:mt-2">
          <p className="mt-1">
            전화 :
            <b>
              <a href="tel:+0325610303"> 032-561-0303</a>
            </b>
          </p>
          <p className="mt-2">
            팩스 : <b>032-561-3586</b>
          </p>
          <p className="mt-2">
            이메일 :
            <b>
              <a href="mailto:jisan6565@hanmail.net"> jisan6565@hanmail.net</a>
            </b>
          </p>
          <p className="mt-2">
            해당 견적에 노출되는 금액은 <b className="text-red-500">배송비 별도</b> 입니다.
          </p>
        </div>
      )}

      {/* {estimates.length === 0 && <VideoButton />} */}
    </div>
  );
}
