import React, { useContext, useEffect, useState } from "react";
import { ConstructionContext } from "../../context/ConstructionContext";

export default function ConstructionWays() {
  const [selectedOption, setSelectedOption] = useState("");
  const { setHeightWithoutIron, setConstructionWay, constEstimate } =
    useContext(ConstructionContext);
  const handleChange = (e) => {
    const value = e.target.value;
    setSelectedOption(value);
    if (value === "dry") {
      setConstructionWay("dry");
    } else {
      setConstructionWay("wet");
      setHeightWithoutIron(null);
    }
  };
  useEffect(() => {
    if (constEstimate.constructionWay === null) {
      return;
    }
    if (constEstimate.constructionWay === "dry") {
      setSelectedOption("dry");
    } else {
      setSelectedOption("wet");
      setHeightWithoutIron(null);
    }
  }, [constEstimate.constructionWay, setHeightWithoutIron]);
  return (
    <fieldset className="field-set-style">
      <legend className="label-style">시공방식</legend>

      <div className="mt-4 space-y-6 border p-5 rounded">
        <div className="flex items-center gap-x-3">
          <input
            id="dry-way"
            name="construction-ways"
            type="radio"
            value="dry"
            checked={selectedOption === "dry"}
            className={`h-4 w-4 border-gray-300 text-red-600 focus:ring-red-600 ${
              selectedOption === "dry" ? "accent-red-500" : ""
            }`}
            onChange={handleChange}
          />
          <label htmlFor="dry-way" className="label-style">
            건식 시공
          </label>
        </div>
        <div className="flex items-center gap-x-3">
          <input
            id="wet-way"
            name="construction-ways"
            type="radio"
            value="wet"
            checked={selectedOption === "wet"}
            className={`h-4 w-4 border-gray-300 text-red-600 focus:ring-red-600 ${
              selectedOption === "wet" ? "accent-red-500" : ""
            }`}
            onChange={handleChange}
          />
          <label htmlFor="wet-way" className="label-style">
            습식 시공
          </label>
        </div>
      </div>
    </fieldset>
  );
}
