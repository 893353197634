import React, { useContext } from "react";
import { ConstructionContext } from "../../context/ConstructionContext";

export default function ConstructionName() {
  const { setConstructionName, constEstimate } = useContext(ConstructionContext);

  const handleChange = (e) => {
    const value = e.target.value;
    setConstructionName(value);
  };
  return (
    <div className="sm:col-span-3">
      <label htmlFor="construction_name" className="label-style">
        공사명
      </label>
      <div className="mt-2">
        <input
          type="text"
          name="construction_name"
          id="construction_name"
          autoComplete="construction_name"
          className="one-line-input-style"
          onChange={handleChange}
          value={constEstimate.constructionName}
        />
      </div>
    </div>
  );
}
