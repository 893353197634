import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import React, { Fragment } from "react";

export default function DialogBodyPipeLocationDropdown({
  pipeLocations,
  chosenLocation,
  onLocationDropDownChosen,
}) {
  const handleChange = (e) => {
    onLocationDropDownChosen(e);
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <Listbox value={chosenLocation} onChange={handleChange}>
      {({ open }) => (
        <>
          <div className="relative pt-4 md:pt-0">
            <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
              <div className="flex items-center">
                {chosenLocation !== "파이프의 위치를 선택해주세요" ? (
                  <img
                    src="../../bottomPipe.png"
                    alt=""
                    className="h-10 w-10 flex-shrink-0 rounded-full bg-white"
                  />
                ) : (
                  <div className="h-10"></div>
                )}
                <span className="ml-3 block truncate">{chosenLocation}</span>
              </div>
              <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {pipeLocations.map((size) => (
                  <Listbox.Option
                    key={size}
                    className={({ active }) =>
                      classNames(
                        active ? "bg-indigo-600 text-white" : "text-gray-900",
                        "relative cursor-default select-none py-2 pl-3 pr-9 "
                      )
                    }
                    value={size}
                  >
                    {({ chosenBigCategory, active }) => {
                      const imagePath =
                        size === "중앙" ? "../../pipe_center.png" : "../../bottomPipe.png";

                      return (
                        <>
                          <div className="flex items-center">
                            {size !== "파이프의 위치를 선택해주세요" ? (
                              <img
                                src={imagePath}
                                alt=""
                                className="h-10 w-10 flex-shrink-0 rounded-full bg-white"
                              />
                            ) : (
                              <div className="h-10"></div>
                            )}
                            <span
                              className={classNames(
                                chosenBigCategory ? "font-semibold " : "font-normal",
                                "ml-3 block truncate"
                              )}
                            >
                              {size}
                            </span>
                          </div>

                          {chosenBigCategory ? (
                            <span
                              className={classNames(
                                active ? "text-white" : "text-indigo-600",
                                "absolute inset-y-0 right-0 flex items-center pr-4 "
                              )}
                            >
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      );
                    }}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
