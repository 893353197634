import React from "react";
import ItemTitle from "./ItemTitle";

export default function AutoItemTitle({ title, autoSetCount }) {
  return (
    <div className="flex items-center">
      <ItemTitle title={title} />

      {autoSetCount ? (
        <span className="pl-2 pb-1">
          <p className="p-px px-1 bg-blue-500 rounded-md text-white font-semibold">
            자동
          </p>
        </span>
      ) : (
        <span className="pl-2 pb-1">
          <p className="p-px px-1 bg-green-500 rounded-md text-white font-semibold">
            수동
          </p>
        </span>
      )}
    </div>
  );
}
