import React, { useContext } from "react";
import { PdfContext } from "../context/PdfContext";

import { EstimateContext } from "../context/EstimatesContext";

export default function OrderButton({ disabled = false }) {
  const { generateDialogBodies, setDialogOpen } = useContext(PdfContext);
  const { setActiveDialogIndex, resetEstimatesPipeOptions } = useContext(EstimateContext);
  let buttonTitle = "제품 발주";
  let imageSrc = "../../pay.png";

  return (
    <div className="text-center my-2 lg:my-12 w-full sm:w-auto">
      <button
        type="button"
        disabled={disabled}
        className={`w-full sm:w-auto justify-center bg-white ${
          disabled
            ? "bg-gray-400 text-gray-400"
            : "hover:bg-blue-700 border-blue-100 hover:-translate-y-1 hover:scale-105 hover:text-white text-gray-900"
        }  dark:hover:bg-green-700  border  focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-10 py-3 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white transition ease-in-out delay-200  `}
        onClick={() => {
          resetEstimatesPipeOptions();
          setActiveDialogIndex(0);
          generateDialogBodies(false);
          setDialogOpen(true);
        }}
      >
        <div className="flex flex-col lg:flex-row items-center justify-center">
          <img
            className="mr-0 lg:mr-3 mb-2 lg:mb-0"
            src={imageSrc}
            width={50}
            height={50}
            alt="Order Button"
          />
          <p className="text-base lg:text-lg">{buttonTitle}</p>
        </div>
      </button>
    </div>
  );
}
