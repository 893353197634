import PropTypes from "prop-types";
import { styles } from "../styled";
import { Page, Text } from "@react-pdf/renderer";
import { PDFGrid } from "../PDFGrid";
import { PDFImageTable } from "../PDFTable/tableImage";

PageTwo.propTypes = {
  data: PropTypes.array,
};

export function PageTwo({ data }) {
  return (
    <Page size="A4" style={styles.page}>
      <PDFGrid mv={0} pa={0} row="column">
        <PDFImageTable data={data} />
      </PDFGrid>
      <Text
        style={styles.pageNumber}
        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
        fixed
      />
    </Page>
  );
}
