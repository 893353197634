import React, { useState, useEffect, useContext } from "react";
import { LuCircleEqual, LuPlusSquare } from "react-icons/lu";

import Utils from "../../Utils";
import { EstimateContext } from "../../context/EstimatesContext";
import { ConstructionContext } from "../../context/ConstructionContext";

export default function TotalConstruction() {
  const { cTotal } = useContext(ConstructionContext);
  const { getPossibleMeter } = useContext(EstimateContext);

  const [isFixed, setIsFixed] = useState(false); // 초기 값: 고정되지 않음

  useEffect(() => {
    // 창 크기가 변경될 때 호출되는 이벤트 핸들러
    const handleResize = () => {
      // 현재 창의 너비를 검사하여 sm (예: 640px) 이하인 경우 고정 해제
      if (window.innerWidth <= 720) {
        setIsFixed(false);
      } else {
        setIsFixed(true); // 그렇지 않으면 고정
      }
    };

    // 초기 렌더링 시 이벤트 핸들러 등록
    handleResize();

    // 창 크기 변경 이벤트 리스너 등록
    window.addEventListener("resize", handleResize);

    // 컴포넌트 언마운트 시 이벤트 리스너 제거
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="flex flex-col">
      <div
        className={`w-full ,  px-2 np:px-3 eme:px-7  md:px-8 md:py-5 pt-2 pb-5 , md:mt-8 , flex flex-col items-end gap-1 md:items-center md:flex-row justify-end md:justify-center  ${
          isFixed ? "fixed bottom-1 left-0 right-0 bg-white z-999 border" : ""
        }`}
      >
        <Total price={cTotal} meters={getPossibleMeter()} />
        <Fee price={cTotal} />
        <LastTotal price={cTotal} />
        {cTotal !== 1300000 && (
          <p className="block lg:hidden">( m당 {Utils.addComma(cTotal / getPossibleMeter())}원 )</p>
        )}
      </div>
    </div>
  );
}

function Total({ price, meters }) {
  return (
    <div className="flex items-center">
      <div className="flex items-center">
        <p className="pr-2">시공 견적</p>
        <p className="text-lg md:text-2xl font-bold pr-1">{Utils.addComma(price)}</p>
        <p className="">원</p>
      </div>
      {price !== 1300000 && (
        <p className="mx-2 hidden md:block">( m당 {Utils.addComma(price / meters)}원 )</p>
      )}
      <LuPlusSquare className="w-7 h-7 text-gray-800 hover:text-gray-600 mx-4 hidden md:block" />
    </div>
  );
}

function Fee({ price }) {
  return (
    <div className="flex items-center">
      <p className="pr-2">부가세</p>
      <p className="text-lg md:text-2xl font-bold pr-1">{Utils.addComma(price / 10)}</p>
      <p className="">원</p>
      <LuCircleEqual className="w-7 h-7 text-gray-800 hover:text-gray-600 mx-4  hidden md:block" />
    </div>
  );
}

function LastTotal({ price }) {
  return (
    <div className="flex items-center">
      <p className="pr-2 text-semibold">최종 시공 금액</p>
      <p className="text-xl md:text-3xl font-bold pr-1 text-red-500">
        {Utils.addComma(price + price / 10)}
      </p>
      <p className="">원</p>
    </div>
  );
}

/* 
  <div
        className={`w-full ,  px-2 np:px-3 eme:px-7  md:px-8 md:py-10 pt-2 pb-5 , md:mt-8 , flex flex-col items-end gap-1 md:items-center md:flex-row justify-end md:justify-center  ${
          isFixed ? "fixed bottom-0 left-0 right-0 bg-white z-999 border" : ""
        }`}
      >
        <Total price={total} meters={getPossibleMeter()} />

        <Fee price={total} />
        <LastTotal price={total} />
        {total !== 1300000 && (
          <p className="block lg:hidden">( m당 {Utils.addComma(total / getPossibleMeter())}원 )</p>
        )}
      </div> 
      */
