import React, { useState, useContext, useRef } from "react";
import SearchIcon from "@mui/icons-material/Search";
import RecommendationSwitch from "./\bRecommendationSwitch";
import { EstimateContext } from "../context/EstimatesContext";
import { ProductsContext } from "../context/ProductsContext";
import CouplingPartsDialog from "./CouplingPartsDialog";
import { CategoryContext } from "../context/CategoryContext";
import RecommendProductItem from "./RecommendProductItem";

export default function SearchTextField() {
  const inputRef = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const [couplingParts, setCouplingParts] = useState([]);
  const [msg, setMsg] = useState("msg");
  const { estimates } = useContext(EstimateContext);
  const {
    searchWord,
    setSearchWord,
    selectedIndex,
    increaseSelectedIndex,
    decreaseSelectedIndex,
    setSelectedIndex,
  } = useContext(ProductsContext);
  const {
    filterSearchedProducts,
    searchedProducts,
    getCouplingProducts,
    recommendation,
    products,
    setSearchedProducts,
  } = useContext(ProductsContext);
  const { onProductChosen } = useContext(CategoryContext);
  const [focused, setFocused] = useState(false);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchWord(value);
    if (value) {
      filterSearchedProducts(value);
    } else {
      filterSearchedProducts("");
    }
  };

  const handleFocused = () => {
    setFocused(true);
    setSelectedIndex(-1);
  };

  const handleUnfocused = () => {
    setTimeout(() => {
      setFocused(false);
    }, 100);
  };

  const addProduct = (product) => {
    const result = onProductChosen({ product });
    setFocused(false); // unfocus
    setSearchWord(""); // 검색어 초기화
    setSearchedProducts([]); // 검색 결과 초기화

    if (product.normalPrice === 0) return;
    if (recommendation === false) return;
    if (!result) return;

    const a = getCouplingProducts(product.id);
    setCouplingParts(a);

    if (a.length !== 0) {
      setMsg(() => {
        if (product.upOrDown === "상부") {
          return "선택하신 제품과 세트로 선택하실 수 있는 하부 트렌치(배수로) 리스트 입니다.";
        } else {
          return "선택하신 제품과 세트로 선택하실 수 있는 상부 그레이팅(덮개) 리스트 입니다";
        }
      });
      setOpen(true);
    }
  };

  const handleClicked = (product) => {
    addProduct(product);
  };

  const handleKeyDown = (e) => {
    const key = e.key;
    const selectedProduct = searchedProducts[selectedIndex];
    console.log(`selectedIndex is ${selectedIndex}`);
    if (key === "ArrowDown") {
      increaseSelectedIndex();
    } else if (key === "ArrowUp") {
      decreaseSelectedIndex();
    } else if (key === "Enter" && focused && selectedIndex !== -1) {
      addProduct(selectedProduct);
    } else if (key === "Escape") {
      setFocused(false);
      if (inputRef.current) {
        inputRef.current.blur();
      }
    }
  };

  return (
    <>
      {isOpen && (
        <CouplingPartsDialog
          open={isOpen}
          setOpen={setOpen}
          couplingParts={couplingParts}
          msg={msg}
        />
      )}
      <div className="relative mb-2 mt-3">
        <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
          <SearchIcon style={{ fontSize: 20, color: "grey" }} />
        </div>
        <input
          type="text"
          value={searchWord}
          ref={inputRef}
          className="border border-gray-300 text-gray-900  rounded-lg  block w-full ps-10 p-4 "
          placeholder="제품을 검색해보세요"
          onChange={handleInputChange}
          onFocus={handleFocused}
          onBlur={handleUnfocused}
          onKeyDown={handleKeyDown}
        />

        {focused && (
          <ul className="absolute left-0 top-[65px] w-full bg-white z-10  flex flex-col  rounded shadow-sm max-h-[50rem] overflow-auto items-center">
            {searchedProducts.map((e, index) => (
              <li
                onClick={() => {
                  handleClicked(e);
                }}
                key={index}
                className={`shadow-sm  border-t  p-1 flex items-center hover:bg-gray-200 w-full ${
                  index === selectedIndex ? "bg-gray-200" : "bg-white"
                }`}
              >
                <img
                  className="rounded-full w-11 h-11 object-cover mx-2 my-1"
                  src={e.thumbnail}
                  alt="img"
                />
                <div className="flex flex-col">
                  <p>{e.name}</p>
                  <p className="text-gray-500 text-sm">{e.standard}</p>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>

      {estimates.length === 0 && <div className="md:h-3" />}

      {estimates.length === 0 && (
        <p className="text-gray-600 px-2 mt-3 md:mt-0">
          자동 견적 시스템은 고객 여러분들께 24시간 편리하게 견적 서비스를 제공하기 위해
          제작되었습니다. 코리아트렌치는 고객분들의 니즈와 편리한 서비스를 위해 항상 노력하겠습니다.
        </p>
      )}

      <RecommendationSwitch />

      {estimates.length === 0 && (
        <RecommendProductItem product={products.find((e) => e.id === 125)} />
      )}

      {estimates.length !== 0 && (
        <div className="text-gray-700 mt-4 md:mt-2">
          <p className="mt-1">
            전화 :
            <b>
              <a href="tel:+0325610303"> 032-561-0303</a>
            </b>
          </p>
          <p className="mt-2">
            팩스 : <b>032-561-3586</b>
          </p>
          <p className="mt-2">
            이메일 :
            <b>
              <a href="mailto:jisan6565@hanmail.net"> jisan6565@hanmail.net</a>
            </b>
          </p>
          <p className="mt-2">
            해당 견적에 노출되는 금액은 <b className="text-red-500">배송비 별도</b> 입니다.
          </p>
        </div>
      )}
    </>
  );
}
