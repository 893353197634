import React, { useContext } from "react";
import { PdfContext } from "../../context/PdfContext";

export default function RoadAddress() {
  const { pdfData } = useContext(PdfContext);
  return (
    <div className="sm:col-span-3">
      <label htmlFor="road_address" className="label-style">
        도로명 주소
      </label>
      <div className="mt-2">
        <input
          type="text"
          name="road_address"
          id="road_address"
          autoComplete="address-level1"
          className="one-line-input-style bg-gray-50"
          placeholder="주소 찾기 버튼을 누르세요"
          disabled={true}
          readOnly={true}
          value={pdfData.order.roadAddress}
        />
      </div>
    </div>
  );
}
