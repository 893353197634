import React, { useContext } from "react";
import { ConstructionContext } from "../../context/ConstructionContext";

export default function LocationInput() {
  const { setLocation, constEstimate } = useContext(ConstructionContext);

  const handleChange = (e) => {
    const value = e.target.value;
    setLocation(value);
  };
  return (
    <div className="sm:col-span-3">
      <div className="flex items-center">
        <label htmlFor="location" className="label-style">
          시공 위치
        </label>
        <span className="mr-1" />
        <p className="text-xs leading-6 text-gray-600">( 예시 : 옥상, 지하, 건물 내부.. )</p>
      </div>
      <div className="mt-2 ">
        <input
          required={true}
          type="text"
          name="location"
          id="location"
          autoComplete="location"
          className="one-line-input-style"
          onChange={handleChange}
          value={constEstimate.location}
        />
      </div>
    </div>
  );
}
