import { PDFGrid } from '../PDFGrid';
import { PDFText } from '../PDFText';
import PropTypes from 'prop-types';
import { View } from '@react-pdf/renderer';

PDFTotal.propTypes = {
  data: PropTypes.array
};

export function PDFTotal({ data }) {
  return (
    <PDFGrid mv={4} pa={0} row="column">
      <PDFGrid pa={0}>
        <PDFGrid border borderRadius='10px' mh={7} row='column' width='65%'>
          {data.estimate.generalInfo.map((e, i) => (
            <PDFGrid ma={0} pa={2} pl={3}>
              <PDFText
                text={e}
                fontSize="11px"
                fontFamily="Regular"
              />
            </PDFGrid>
          ))}

        </PDFGrid>
        <PDFGrid mh={8} width='35%' pa={0} row='column'>
          <PDFGrid border borderRadius='10px' row='column' ma={0} height='auto' pa={0}>
            <View style={{
              padding: 0,
              margin: 0,
              borderLeftColor: 'white',
              borderRightColor: 'white',
              borderTopColor: 'white',
              borderBottomColor: 'white',
              borderTopLeftRadius: '10px',
              borderTopRightRadius: '10px',
              flexDirection: 'row'
            }}>
              <View style={{
                borderRightColor: '#1f2937',
                borderRightWidth: '1px',
                width: '40%',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 5,
                margin: 0,
                borderLeftColor: 'white',
                borderTopColor: 'white',
                borderBottomColor: 'white',
                borderTopLeftRadius: '10px',
                flexDirection: 'row'
              }}>
                <PDFText text={'합계'} fontSize='10px' fontFamily='Bold' />
              </View>
              <View style={{
                width: '60%',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 5,
                margin: 0,
                borderColor: 'white',
                borderTopLeftRadius: '10px',
                flexDirection: 'row',
              }}>
                <PDFText
                  text={`${data.estimate.total}`}
                  fontSize='10px'
                  fontFamily='Bold'
                  textAlign='center'
                />
              </View>
            </View>

            <PDFGrid pa={0} borderTop borderWidth='1px'>
              <PDFGrid
                borderWidth='1px'
                borderRight
                width='40%'
                alignItems='center'
                justifyContent='center'
              >
                <PDFText text={'부가세'} fontSize='10px' fontFamily='Bold' />
              </PDFGrid>
              <PDFGrid width='60%' alignItems='center' justifyContent='center'>
                <PDFText
                  text={`${data.estimate.vat}`}
                  fontSize='10px'
                  fontFamily='Bold'
                  textAlign='center'
                />
              </PDFGrid>
            </PDFGrid>

            <View style={{
              padding: 0,
              margin: 0,
              borderLeftColor: 'white',
              borderRightColor: 'white',
              borderTopColor: '#1f2937',
              borderTopWidth: '1px',
              borderBottomLeftRadius: '10px',
              borderBottomRightRadius: '10px',
              flexDirection: 'row'
            }}>
              <View style={{
                borderRightColor: '#1f2937',
                borderRightWidth: '1px',
                width: '40%',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 5,
                margin: 0,
                borderLeftColor: 'white',
                borderTopColor: 'white',
                borderBottomColor: 'white',
                borderBottomLeftRadius: '10px',
                flexDirection: 'row'
              }}>
                <PDFText text={'최종 금액'} fontSize='10px' fontFamily='Bold' />
              </View>
              <View style={{
                width: '60%',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 5,
                margin: 0,
                borderColor: 'white',
                borderBottomLeftRadius: '10px',
                flexDirection: 'row',
              }}>
                <PDFText
                  text={`${data.estimate.finalTotal}`}
                  fontSize='10px'
                  fontFamily='Bold'
                  textAlign='center'
                  color='red'
                />
              </View>
            </View>
          </PDFGrid>
          <PDFGrid mt={2} mb={2} pa={0} ml={5}>
            <PDFText
              text={`${data?.company?.bankName ?? ''} ${data?.company?.bankClientName ?? ''}`}
              fontSize='10px'
              textAlign={'left'}
              fontFamily='Regular'
            />
          </PDFGrid>
          <PDFGrid mt={0} mb={2} pa={0} ml={5}>
            <PDFText
              text={data?.company?.bankNumber ?? ''}
              fontSize='12px'
              textAlign={'left'}
              fontFamily='Bold'
            />
          </PDFGrid>
        </PDFGrid>
      </PDFGrid>
    </PDFGrid>
  )
}
