import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import NotFound from "./pages/NotFound";
import EstimatePage from "./pages/EstimatePage";
import ConstructionPage from "./pages/ConstructionPage";
import { createBrowserRouter, RouterProvider } from "react-router-dom"; //
import { ThemeProvider } from "@material-tailwind/react";
import AutoConstructionPage from "./pages/AutoConstructionPage";
import ReactGA from "react-ga4";

ReactGA.initialize("G-RZTH6FW9P2");
// ReactGA.send({ hitType: "pageview", page: "/", title: "홈페이지" });

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <NotFound />,
    children: [
      { index: true, element: <EstimatePage /> },
      { path: "/construction", element: <ConstructionPage /> },
      { path: "/auto", element: <AutoConstructionPage /> },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider>
      <RouterProvider router={router} />
    </ThemeProvider>
  </React.StrictMode>
);

reportWebVitals();
