import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useState, useEffect, useContext } from "react";
import { EstimateContext } from "../context/EstimatesContext";

export default function ConstructionInfoDialog() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { sawInfoDialog, setSawInfoDialog } = useContext(EstimateContext);

  useEffect(() => {
    if (!sawInfoDialog) {
      setModalIsOpen(true);
      setSawInfoDialog(true);
    }

    // 로컬 스토리지에서 값을 읽어옵니다.
    // const hasVisitedBefore = localStorage.getItem("hasVisitedBefore");
    // 만약 처음 방문이라면 다이얼로그를 엽니다.
    // if (!hasVisitedBefore) {
    //   setModalIsOpen(true);
    //   // 로컬 스토리지에 방문 여부를 기록합니다.
    //   localStorage.setItem("hasVisitedBefore", "true");
    // }
  }, [sawInfoDialog, setSawInfoDialog]); // 빈 배열을 전달하여 최초 한 번만 실행되도록 합니다.

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <Transition show={modalIsOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={closeModal}>
        {/* 모달 오버레이 */}
        <Dialog.Overlay className="fixed inset-0 " />

        {/* 모달 콘텐츠 */}
        <div className="flex items-center justify-center min-h-screen bg-white ">
          <div className="bg-white p-4 sm:p-8 max-w-md w-full border rounded-lg">
            {/* 모달 헤더 */}
            <Dialog.Title className="text-lg sm:text-xl font-bold mb-4 ">
              코리아트렌치 실시간자동견적 서비스
            </Dialog.Title>

            {/* 모달 내용 */}
            <p className="text-gray-700 mb-4">
              필요한 자재와 수량을 먼저 확인해 주세요
              <br />
              대략적인 시공비를 바로 확인하실 수 있습니다.
              <br />
              정확한 시공비는 코리아트렌치 전문시공팀을 통해서 견적요청이 가능합니다.
            </p>

            {/* 모달 닫기 버튼 */}
            <button
              onClick={closeModal}
              className="bg-blue-500 text-white px-4 py-2 rounded-md w-full"
            >
              확인
            </button>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
