import React, { useContext, useEffect, useState } from "react";
import { ConstructionContext } from "../../context/ConstructionContext";

export default function HeightWithoutIron() {
  const [selectedOption, setSelectedOption] = useState("");
  const { constEstimate, setHeightWithoutIron } = useContext(ConstructionContext);
  const handleChange = (e) => {
    const value = e.target.value;
    setSelectedOption(value);
    if (value === "50-100") {
      setHeightWithoutIron("50-100");
    } else if (value === "100-150") {
      setHeightWithoutIron("100-150");
    } else if (value === "150-200") {
      setHeightWithoutIron("150-200");
    } else {
      setHeightWithoutIron(null);
    }
  };

  useEffect(() => {
    setSelectedOption(constEstimate.heightWithoutIron);
    if (constEstimate.heightWithoutIron === "50-100") {
      setHeightWithoutIron("50-100");
    } else if (constEstimate.heightWithoutIron === "100-150") {
      setHeightWithoutIron("100-150");
    } else if (constEstimate.heightWithoutIron === "150-200") {
      setHeightWithoutIron("150-200");
    } else {
      setHeightWithoutIron(null);
    }
  }, [constEstimate.heightWithoutIron, setHeightWithoutIron]);

  return (
    <fieldset className="field-set-style">
      <legend className="label-style">무근고 높이</legend>

      <div className="mt-4 space-y-6 border p-5 rounded">
        <div className="flex items-center gap-x-3">
          <input
            id="mugn-50-100"
            name="mugns"
            type="radio"
            value="50-100"
            checked={selectedOption === "50-100"}
            onChange={handleChange}
            className={`h-4 w-4 border-gray-300 text-red-600 focus:ring-red-600 ${
              selectedOption === "50-100" ? "accent-red-500" : ""
            }`}
          />
          <label htmlFor="mugn-50-100" className="label-style">
            50mm - 100mm
          </label>
        </div>
        <div className="flex items-center gap-x-3">
          <input
            id="mugn-100-150"
            name="mugns"
            type="radio"
            value="100-150"
            onChange={handleChange}
            checked={selectedOption === "100-150"}
            className={`h-4 w-4 border-gray-300 text-red-600 focus:ring-red-600 ${
              selectedOption === "100-150" ? "accent-red-500" : ""
            }`}
          />
          <label htmlFor="mugn-100-150" className="label-style">
            100mm - 150mm
          </label>
        </div>
        <div className="flex items-center gap-x-3">
          <input
            id="mugn-150-200"
            name="mugns"
            type="radio"
            value="150-200"
            checked={selectedOption === "150-200"}
            onChange={handleChange}
            className={`h-4 w-4 border-gray-300 text-red-600 focus:ring-red-600 ${
              selectedOption === "150-200" ? "accent-red-500" : ""
            }`}
          />
          <label htmlFor="mugn-150-200" className="label-style">
            150mm - 200mm
          </label>
        </div>
      </div>
    </fieldset>
  );
}
