import React, { useContext } from "react";
import { ConstructionContext } from "../context/ConstructionContext";
import { PdfContext } from "../context/PdfContext";

export default function CompanyName({ isForPdf = false, isForOrder = false }) {
  const { setCompanyName, constEstimate } = useContext(ConstructionContext);
  const { setClientName, pdfData } = useContext(PdfContext);

  const handleChange = (e) => {
    const value = e.target.value;
    if (isForPdf) {
      setClientName(value);
    } else {
      setCompanyName(value);
    }
  };

  return (
    <div className={isForPdf ? "" : `sm:col-span-3`}>
      <div className="flex items-center ">
        <label htmlFor="company-name" className="label-style">
          {isForOrder ? "주문자명" : "회사명"}
        </label>
        <span className="mr-1" />
        <p className="text-xs leading-6 text-gray-600 hidden vsp:block">
          {isForOrder ? "" : "( 개인 의뢰시 성함을 입력해주세요 )"}
        </p>
      </div>

      <div className="mt-2">
        <input
          required
          type="text"
          name="company-name"
          id="company-name"
          autoComplete="company-name"
          className="one-line-input-style"
          onChange={handleChange}
          value={isForPdf ? pdfData.client.name : constEstimate.companyName}
        />
      </div>
    </div>
  );
}
