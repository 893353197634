import React, { useContext } from "react";
import { EstimateContext } from "../context/EstimatesContext";

import Utils from "../Utils";
import DialogHelper from "../helpers/DialogHelper";
import PlusButton from "./ui/PlusButton";
import MinusButton from "./ui/MinusButton";
import PartsThumbnail from "./ui/PartsThumbnail";
import AutoItemTitle from "./ui/AutoItemTitle";
import CountInput from "./ui/CountInput";

export default function BoltSetCountAndPrice({ estimate }) {
  const {
    setBoltSetCount,
    handleIncreaseBoltSetCount,
    handleDecreaseBoltSetCount,
    boltSetDisableAutoIncrease,
  } = useContext(EstimateContext);

  const blurHandler = (e) => {
    const value = e.target.value;
    if (value === "" || value === "0") {
      setBoltSetCount(estimate.id, 0);
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;

    // 오직 숫자만
    if (/^[0-9]*$/.test(value) && value !== "") {
      const count = parseInt(value);
      setBoltSetCount(estimate.id, count);
      return;
    }

    // 숫자가 0이면 -> 1로 변경
    if (value === "0") {
      setBoltSetCount(estimate.id, 0);
      return;
    }
    if (value === "") {
      setBoltSetCount(estimate.id, "");
    }
  };

  const handleIncrease = async () => {
    if (estimate.boltSetAutomaticIncrease) {
      const result = await DialogHelper.showboltSetAutoDialog();
      if (result.isConfirmed) {
        boltSetDisableAutoIncrease(estimate.id);
      }
    } else {
      handleIncreaseBoltSetCount(estimate.id);
    }
  };

  const handleDecrease = async () => {
    if (estimate.boltSetAutomaticIncrease) {
      const result = await DialogHelper.showboltSetAutoDialog();

      if (result.isConfirmed) {
        boltSetDisableAutoIncrease(estimate.id);
      }
    } else {
      handleDecreaseBoltSetCount(estimate.id);
    }
  };

  return (
    <div className="flex">
      <PartsThumbnail url={estimate.boltSetThumbnail} />

      <div className="flex-1 flex justify-between items-center px-0 vsp:px-4 py-2">
        {/* button hanler  */}
        <div>
          {/* <p>{estimate.boltSetAutomaticIncrease.toString()}</p> */}
          <AutoItemTitle title={"볼트 세트"} autoSetCount={estimate.boltSetAutomaticIncrease} />

          <div className="flex items-center">
            <MinusButton onClick={handleDecrease} />
            <CountInput
              disabled={estimate.boltSetAutomaticIncrease}
              value={estimate.boltSetCount}
              onBlur={blurHandler}
              onChange={handleChange}
            />

            <PlusButton onClick={handleIncrease} />
          </div>
        </div>

        {/* Price and meter  */}
        <div className="flex flex-col justify-center text-right">
          <p className="">{estimate.boltSetCount}개</p>
          <p className="font-bold">
            {Utils.addComma(estimate.boltSetCount * estimate.boltSetPrice)}원
          </p>
        </div>
      </div>
    </div>
  );
}
