import React from "react";
// import { useNavigate } from "react-router-dom";

export default function AutoConstructionInfo() {
  // const navigate = useNavigate();
  return (
    <div className="max-w-screen-xl mx-auto text-center bg-white border rounded-lg mt-4 p-2 sm:p-8">
      <p className="text-xl text-gray-800 font-bold  mb-6">주의 사항</p>
      <p></p>
      <p>해당 시공 견적은 가견적 입니다. 해당 가견적은,</p>

      <ol className="pl-4 mx-auto flex flex-col justify-center items-center mb-2">
        <div>
          <div className="pt-2 flex">
            <img src="../../dark-one.png" width={23} height={23} alt="" />
            <p className="pl-2 font-bold">현장 양수, 양전 가능</p>
          </div>
          <div className="pt-2 flex">
            <img src="../../dark-two.png" width={23} height={23} alt="" />
            <p className="pl-2 font-bold">현장 청소 상태 양호</p>
          </div>
          <div className="pt-2 flex">
            <img src="../../dark-three.png" width={23} height={23} alt="" />
            <p className="pl-2 font-bold">기본 2인 1조 기준</p>
          </div>
          {/* <div className="pt-2 flex">
            <img src="../../dark-four.png" width={23} height={23} alt="" />
            <p className="pl-2 font-bold"></p>
          </div> */}
        </div>
      </ol>
      <p className="pb-2">전제하에 만들어진 가견적 입니다.</p>
      <p>
        정확한 견적을 위해서는 <b className="text-red-500">정식 시공 견적</b>을 이용하세요.
      </p>
      {/* <div className="mt-12 flex items-center justify-center gap-x-6 ">
        <button
          // type="submit"
          className="flex-col text-gray-900  hover:text-white bg-white hover:bg-blue-500 dark:hover:bg-blue-700  border border-gray-300 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-10 py-3 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white mb-2 transition ease-in-out delay-300  hover:-translate-y-1 hover:scale-101         
          justify-center 
          "
          onClick={() => {
            navigate("/construction");
          }}
        >
          <img className="mb-2" src="../../helmet.png" width={50} height={50} alt="hello" />
          <p className="text-lg">정식 시공 견적 요청</p>
        </button>
      </div> */}
    </div>
  );
}
