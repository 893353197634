import React, { useContext } from "react";
import { ConstructionContext } from "../../context/ConstructionContext";

export default function ManagerName() {
  const { setManagerName, constEstimate } = useContext(ConstructionContext);

  const handleChange = (e) => {
    const value = e.target.value;
    setManagerName(value);
  };

  return (
    <div className="sm:col-span-3">
      <label htmlFor="manager-name" className="label-style">
        이름
      </label>
      <div className="mt-2">
        <input
          required
          type="text"
          name="manager-name"
          id="manager-name"
          autoComplete="manager-name"
          className="one-line-input-style"
          onChange={handleChange}
          value={constEstimate.managerName}
        />
      </div>
    </div>
  );
}
