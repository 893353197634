import React, { useContext } from "react";
import { PdfContext } from "../context/PdfContext";
import { EstimateContext } from "../context/EstimatesContext";
import { DcocumentPDFTable } from "./PDF/document";
import { pdf } from "@react-pdf/renderer";

export default function PdfButton({ purpose = "product", disabled = false }) {
  const {
    pdfData,
    generateDialogBodies,
    generateConstructionDialogBody,
    setDialogOpen,
    generateAllDialogBody,
  } = useContext(PdfContext);
  const { setActiveDialogIndex, resetEstimatesPipeOptions } = useContext(EstimateContext);
  let buttonTitle = "PDF 제품 견적서 출력";
  let imageSrc = "../../pdf.svg";
  if (purpose === "construction") {
    buttonTitle = "PDF 시공 견적서";
  } else if (purpose === "all") {
    buttonTitle = `PDF 통합 견적서 (제품 + 시공 견적서)`;
  }

  return (
    <>
      <div className="text-center my-2 lg:my-12 w-full sm:w-auto">
        <button
          type="button"
          disabled={disabled}
          className={`w-full sm:w-auto justify-center bg-white ${
            disabled
              ? "bg-gray-400 text-gray-400"
              : "hover:bg-red-500 border-red-100 hover:-translate-y-1 hover:scale-105 hover:text-white text-gray-900"
          }  dark:hover:bg-green-700  border   focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm flex-auto px-10 py-3 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white  transition ease-in-out delay-200  `}
          onClick={() => {
            resetEstimatesPipeOptions();
            setActiveDialogIndex(0);
            // 왜 미리 호출하는지는 모르겠지만 일단 그냥 내비둠.
            generateDialogBodies();

            if (purpose === "product") {
              generateDialogBodies();
            } else if (purpose === "construction") {
              generateConstructionDialogBody();
            } else if (purpose === "all") {
              generateAllDialogBody(); // (제품,시공 DialogBody 생성)
            }

            setDialogOpen(true);

            // PDF 처음 생성시 엄청 오래 기다리게 되는데 그거 방지용 코드임.
            // 아래 코드를 비동기로 실행하면 유저 디바이스에 미리 Pdf setup이 되는 거 같다.
            const doc = <DcocumentPDFTable data={pdfData} title={"title"} />;
            const asPdf = pdf([]);
            asPdf.updateContainer(doc);
            asPdf.toBlob();
          }}
        >
          <div className="flex flex-col lg:flex-row items-center justify-center ">
            <img
              className="mr-0 lg:mr-3 mb-2 lg:mb-0"
              src={imageSrc}
              width={50}
              height={50}
              alt="Pdf Button"
            />
            {purpose === "all" ? (
              <div className="flex flex-col items-center justify-center ">
                <p className="text-base lg:text-lg  ">PDF 통합 견적서</p>
                <p className="text ">( 제품 + 시공 견적서 )</p>
              </div>
            ) : (
              <p className="text-base lg:text-lg">{buttonTitle}</p>
            )}
          </div>
        </button>
      </div>
    </>
  );
}
