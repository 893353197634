import React, { useContext } from "react";
import { ConstructionContext } from "../../context/ConstructionContext";

export default function ExtraInfo() {
  const { setExtraInfo, constEstimate } = useContext(ConstructionContext);

  const handleChange = (e) => {
    const value = e.target.value;
    setExtraInfo(value);
  };
  return (
    <div className="col-span-full mb-6">
      <div className="flex items-center">
        <label htmlFor="extra-info" className="label-style">
          특이 사항
        </label>
        <span className="mr-1" />
        <p className="text-xs leading-6 text-gray-600">
          ( 가능한 상세히 적어주셔야 정확한 견적이 가능합니다 )
        </p>
      </div>

      <div className="mt-2">
        <textarea
          id="extra-info"
          name="extra-info"
          rows={3}
          className="px-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          onChange={handleChange}
          value={constEstimate.extraInfo}
        />
      </div>
    </div>
  );
}
