import React, { useContext, useState, useEffect } from "react";
import { ConstructionContext } from "../../context/ConstructionContext";

export default function ForkliftCostBurden() {
  const [selectedOption, setSelectedOption] = useState("");
  const { constEstimate, setAutoForkliftCostBurden } = useContext(ConstructionContext);
  const handleChange = (e) => {
    const value = e.target.value;
    setSelectedOption(value);
    if (value === "client") {
      setAutoForkliftCostBurden("건설사");
    } else {
      setAutoForkliftCostBurden("시공사");
    }
  };

  useEffect(() => {
    if (constEstimate.forkliftCostBurden === null) {
      return;
    }

    if (constEstimate.forkliftCostBurden === "건설사") {
      setSelectedOption("client");
    } else {
      setSelectedOption("server");
    }
  }, [constEstimate.forkliftCostBurden]);
  return (
    <fieldset className="field-set-style">
      <legend className="label-style">지게차 비용 부담</legend>

      <div className="mt-4 space-y-6 border p-5  rounded">
        <div className="flex items-center gap-x-3">
          <input
            id="client"
            name="client"
            type="radio"
            value="client"
            checked={selectedOption === "client"}
            className={`h-4 w-4 border-gray-300 text-red-600 focus:ring-red-600 ${
              selectedOption === "client" ? "accent-red-500" : ""
            }`}
            onChange={handleChange}
          />
          <label htmlFor="client" className="radio-label-style">
            발주처에서 부담
          </label>
        </div>
        <div className="flex items-center gap-x-3">
          <input
            id="server"
            name="server"
            type="radio"
            value="server"
            checked={selectedOption === "server"}
            className={`h-4 w-4 border-gray-300 text-red-600 focus:ring-red-600 ${
              selectedOption === "server" ? "accent-red-500" : ""
            }`}
            onChange={handleChange}
          />
          <label htmlFor="server" className="radio-label-style">
            시공사에서 부담
          </label>
        </div>
      </div>
    </fieldset>
  );
}
