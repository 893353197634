import { PDFGrid } from "../PDFGrid";
import { PDFText } from "../PDFText";
import PropTypes from "prop-types";
import { View } from "@react-pdf/renderer";

PDFPHeaderInfo.propTypes = {
  data: PropTypes.array,
};

export function PDFPHeaderInfo({ data }) {
  return (
    <PDFGrid mh={4} mv={0} width="28%" row="column">
      <PDFGrid border borderRadius="10px" pa={0} row="column">
        <View
          style={{
            borderLeftColor: "white",
            borderRightColor: "white",
            borderTopColor: "white",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
            padding: 7,
            paddingHorizontal: 7,
            paddingVertical: 15,
            flexDirection: "column",
            borderBottomWidth: "1.5px",
            borderBottomColor: "#1f2937",
          }}
        >
          <PDFText text={"견적 번호"} fontSize="12px" textAlign={"center"} fontFamily={"Bold"} />
          <PDFText
            text={data?.estimate?.id ?? ""}
            fontSize="13px"
            textAlign={"center"}
            fontFamily="Regular"
          />
        </View>
        <PDFGrid pa={7} row="column" borderButton pv={15}>
          <PDFText text={"담당자"} fontSize="12px" textAlign={"center"} fontFamily={"Bold"} />
          <PDFText
            text={data?.estimate?.manager ?? ""}
            fontSize="13px"
            textAlign={"center"}
            fontFamily="Regular"
          />
        </PDFGrid>
        <View
          style={{
            borderLeftColor: "white",
            borderRightColor: "white",
            borderBottomColor: "white",
            borderBottomLeftRadius: "10px",
            borderBottomRightRadius: "10px",
            paddingTop: 6,
            paddingHorizontal: 7,
            flexDirection: "column",
          }}
        >
          <PDFGrid pt={0} pa={0}>
            <PDFGrid pa={0} width="50%" justifyContent="flex-end">
              <PDFText text="견적 발행일 : " fontSize="9px" fontFamily="Bold" />
            </PDFGrid>
            <PDFGrid pa={0} width="50%" alignItems="center">
              <PDFText
                text={data?.estimate?.issuedAt ?? ""}
                fontSize="9px"
                // color='#544a4a'
                pt={0}
                fontFamily="Regular"
              />
            </PDFGrid>
          </PDFGrid>
          <PDFGrid pa={0} pb={7}>
            <PDFGrid pa={0} width="50%" justifyContent="flex-end">
              <PDFText text="견적 만료일 : " fontSize="9px" fontFamily="Bold" />
            </PDFGrid>
            <PDFGrid pa={0} width="50%" alignItems="center">
              <PDFText
                text={data?.estimate?.expiredAt ?? ""}
                fontSize="9px"
                // color='#544a4a'
                pt={0}
                fontFamily="Regular"
              />
            </PDFGrid>
          </PDFGrid>
        </View>
      </PDFGrid>
    </PDFGrid>
  );
}
