import { PDFGrid } from "../PDFGrid";
import { PDFText } from "../PDFText";
import PropTypes from "prop-types";
import { Image, View } from "@react-pdf/renderer";
import { styles } from "../styled";

PDFImageTable.propTypes = {
  data: PropTypes.array,
};

export const divideArrayIntoParts = (array, numb = 6) => {
  const nuevoArray = [];
  for (let i = 0; i < array.length; i += numb) {
    nuevoArray.push(array.slice(i, i + numb));
  }
  return nuevoArray;
};

export function PDFImageTable({ data }) {
  const splitDataIntoParts = divideArrayIntoParts(data.estimate.photos, 12);

  return splitDataIntoParts.map((dataTable, index2) => {
    return (
      <View
        break={index2 === 0 ? false : true}
        style={{
          marginHorizontal: 9,
          flexDirection: "column",
          padding: "2px",
          paddingTop: 0,
          paddingBottom: 5,
        }}
      >
        <View style={styles.border} />

        <View style={{ height: "100%" }}>
          <View
            style={{
              // borderBottomColor: "#1f2937",
              borderBottomWidth: "1.5px",
              padding: 0,
              margin: 0,
              borderLeftColor: "white",
              borderRightColor: "white",
              borderTopColor: "white",
              alignItems: "center",
              justifyContent: "center",
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
            }}
          >
            <PDFText text={"제품 사진"} fontSize="14px" fontFamily="Bold" mv={15} pb={3} />
          </View>
          <View style={{ height: "100%" }}>
            <PDFGrid flexWrap="wrap" mh={"auto"} pl={12} pt={10}>
              {dataTable.map((e, i) => (
                <View
                  key={i}
                  style={{
                    position: "relative",
                    margin: 8,
                    width: "160px",
                    height: "160px",
                    borderLeft: "0.3px",
                    borderRight: "0.3px",
                    borderBottom: "0.3px",
                    borderLeftColor: "#9c9c9c",
                    borderRightColor: "#9c9c9c",
                    borderBottomColor: "#9c9c9c",
                    backgroundColor: "#ffffff",
                    // borderTopLeftRadius: "5px",
                    // borderTopRightRadius: "5px",
                  }}
                >
                  <View
                    style={{
                      backgroundColor: "#3c3a39",
                      padding: 0,
                      margin: 0,
                      paddingVertical: 3,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <PDFText
                      text={e.name}
                      fontSize="10px"
                      fontFamily="Bold"
                      color="#ffffff"
                      pb={5}
                    />
                  </View>

                  {/* Index */}
                  <View
                    style={{
                      position: "absolute",
                      top: 26,
                      left: 7,
                      width: 16,
                      height: 16,
                      backgroundColor: "#f2f2f2",
                      borderRadius: 10,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <PDFText text={e.index} fontSize="9px" fontFamily="Bold" />
                  </View>

                  {e.standard ? (
                    <Image
                      src={e.thumbnail}
                      style={{
                        width: "100%",
                        height: "100%",
                        zIndex: "-1",
                        paddingBottom: 20,
                        backgroundColor: "#ffffff",
                      }}
                    />
                  ) : (
                    <Image
                      src={e.thumbnail}
                      style={{
                        width: "100%",
                        height: "100%",
                        zIndex: "-1",
                        backgroundColor: "#ffffff",
                      }}
                    />
                  )}
                  {e.standard && (
                    <View
                      style={{
                        position: "absolute",
                        bottom: 0,
                        backgroundColor: "#e2e1e0",
                        width: "100%",
                        padding: 0,
                        margin: 0,
                        paddingVertical: 2,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <PDFText
                        text={e.standard}
                        fontSize="10px"
                        fontFamily="Regular"
                        color="#3c3a39"
                        // pb={7}
                      />
                    </View>
                  )}
                </View>
              ))}
            </PDFGrid>
          </View>
        </View>
      </View>
    );
  });
}
