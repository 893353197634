import { Button } from "@mui/material";
import { DcocumentPDFTable } from "./document";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { pdf } from "@react-pdf/renderer";
import saveAs from "file-saver";
import { useContext } from "react";
import PropTypes from "prop-types";
import { PdfContext } from "../../context/PdfContext";

ReportPDF.propTypes = {
  data: PropTypes.array,
  title: PropTypes.string,
};

export function ReportPDF({ title, disabled }) {
  const { pdfData, setLoading, setDialogOpen } = useContext(PdfContext);

  const DownloadPDF = async () => {
    setLoading(true);
    sendEstimateEmail(pdfData);
    setTimeout(async () => {
      const doc = <DcocumentPDFTable data={pdfData} title={title} />;
      const asPdf = pdf([]);
      asPdf.updateContainer(doc);
      const blob = await asPdf.toBlob();
      saveAs(blob, `${title}.pdf`);
      setDialogOpen(false);
      setLoading(false);
      // clearClientEmailPhoneNameAddress();
    }, 1400);
  };

  return (
    <Button
      onClick={DownloadPDF}
      disabled={disabled}
      variant="contained"
      size="large"
      color="error"
      startIcon={<CloudDownloadIcon />}
    >
      <h6 className="font-bold py-1">&nbsp;{`PDF 견적서 다운로드`}</h6>
    </Button>
  );
}

async function sendEstimateEmail(pdfData) {
  const host =
    window.location.hostname === "localhost" ? "http://koreatrenchorder.cafe24app.com" : "api";

  try {
    fetch(`${host}/estimate/autoEstimate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(pdfData),
    });
  } catch (error) {
    console.error("오류 발생:", error);
  }
}
