import React, { useContext } from "react";
import EstimateList from "../components/EstimateList";
import { EstimateContext } from "../context/EstimatesContext";
import TotalProducts from "../components/TotalProducts";
import PdfButton from "../components/PdfButton";
import OrderButton from "../components/OrderButton";
import { Link } from "react-router-dom";
import ConstructionInfoDialog from "../components/ConstructionInfoDialog";
import SearchTabs from "../components/SearchTabs";
import SlideDialog from "../components/SlideDialog";

import { PdfContext } from "../context/PdfContext";

export default function EstimatePage() {
  const { estimates, canConstruct } = useContext(EstimateContext);
  const { dialogOpen } = useContext(PdfContext);

  return (
    <div className="max-w-screen-xl mx-auto bg-white border rounded-lg mt-20 ">
      <section
        className={`md:flex sm:p-6 mt-0  ${
          estimates.length === 0 ? "justify-center" : "justify-between"
        }`}
      >
        <ConstructionInfoDialog />

        {/* 검색 기능 추가 */}
        <SearchTabs />
        <>{dialogOpen && <SlideDialog />}</>
        {/* <Dropdowns /> */}
        {estimates.length !== 0 && <InnerDivider />}
        {estimates.length !== 0 && <EstimateList />}
      </section>
      {estimates.length !== 0 && <TotalProducts />}

      {/* {estimates.length !== 0 && <PdfButton />}            
      {estimates.length !== 0 && <OrderButton />} */}

      {estimates.length !== 0 && (
        <div className="flex flex-col mx-2 vsp:mx-4 sp:mx-12 md:mx-20 sm:flex-row items-center justify-center mb-12 lg:mb-0">
          <PdfButton />
          <span className="w-4 lg:w-8" />
          {canConstruct && (
            <Link to="/auto" className="w-full sm:w-auto">
              <div className="text-center my-2 lg:my-12 items-center justify-center ">
                <button className="w-full sm:w-auto justify-center  text-gray-900  hover:text-white bg-white hover:bg-green-500 dark:hover:bg-green-700  border border-green-100 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-10 py-3 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white  transition ease-in-out delay-300  hover:-translate-y-1 hover:scale-101 ">
                  <div className="flex flex-col lg:flex-row items-center justify-center">
                    <img
                      className="mr-0 lg:mr-3 mb-2 lg:mb-0"
                      src="../../estimate.png"
                      width={50}
                      height={50}
                      alt=""
                    />
                    <p className="text-base lg:text-lg">시공비 확인</p>
                  </div>
                </button>
              </div>
            </Link>
          )}

          <span className="w-4 lg:w-8" />
          <OrderButton />
        </div>
      )}
    </div>
  );
}

function InnerDivider() {
  return <div className={`w-full my-4 sp:my-8 md:my-8 sm:h-1 bg-transparent md:hidden `} />;
}
