import React, { useContext } from "react";
import EstimateCard from "./EstimateCard";
import { EstimateContext } from "../context/EstimatesContext";

export default function EstimateList() {
  const { estimates } = useContext(EstimateContext); // 사용할 context 정의

  return (
    <div className="flex-1">
      {estimates && estimates.map((est) => <EstimateCard key={est.id} estimate={est} />)}
    </div>
  );
}
