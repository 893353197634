import React, { useContext } from "react";
import { EstimateContext } from "../context/EstimatesContext";
import { ProductsContext } from "../context/ProductsContext";

export default function RecommendProductItem() {
  const { handleAdd } = useContext(EstimateContext);
  const { products } = useContext(ProductsContext);
  return (
    <img
      onClick={() => {
        //
        handleAdd(products.find((e) => e.id === 125));
      }}
      src="/recommend.png"
      alt=""
      className={`w-full rounded-xl my-4 cursor-pointer`}
    />
  );
}
