import { createContext, useContext, useState } from "react";
import { useImmer } from "use-immer";
import { ProductsContext } from "./ProductsContext";
import { EstimateContext } from "./EstimatesContext";
import DialogHelper from "../helpers/DialogHelper";

import ToastHelper from "../helpers/ToastHelper";

export const CategoryContext = createContext();

export function CategoryProvider({ children }) {
  //! Categories
  const defaultSubCategory = [
    {
      id: 0,
      name: "제품의 이름을 선택해주세요",
      thumbnail:
        "https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
  ];
  const defaultProductCategory = [
    {
      id: 0,
      name: "제품 규격을 선택해주세요",
      thumbnail:
        "https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
  ];
  const trenchList = [
    {
      id: 0,
      name: "트렌치를 선택해주세요",
      thumbnail:
        "https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
    {
      id: 1,
      name: "물도랑 (보도용)",
      thumbnail: "https://koreatrench.com/images/thumbnail/p-sub1/alt150x60.png",
    },
    {
      id: 2,
      name: "물도랑 (보도, 차도용)",
      thumbnail: "https://koreatrench.com/images/thumbnail/p-sub1/alt200x100.png",
    },

    // {
    //   id: 4,
    //   name: "구배트렌치",
    //   thumbnail: "https://www.koreatrench.com/images/thumbnail/p-sub1/grTrench.png",
    // },
    {
      id: 5,
      name: "석재(판석)트렌치",
      thumbnail: "https://koreatrench.com/images/thumbnail/p-sub1/stone200x150.png",
    },
    {
      id: 6,
      name: "커버트렌치",
      thumbnail: "https://koreatrench.com/images/thumbnail/p-sub1/cover100x70.png",
    },
  ];
  const soundlessList = [
    {
      id: 0,
      name: "무소음트렌치를 선택해주세요",
      thumbnail:
        "https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
    {
      id: 1,
      name: "하나로",

      // thumbnail: "https://koreatrench.com/images/thumbnail/p-sub1/alt200x60.png",
      thumbnail: "https://koreatrench.com/images/thumbnail/p-sub2/hanaro200x100.png",
    },
    {
      id: 2,
      name: "거북이",
      thumbnail: "https://koreatrench.com/images/thumbnail/p-sub2/turtle300.png",
    },
    {
      id: 3,
      name: "기러기",
      thumbnail: "https://koreatrench.com/images/thumbnail/p-sub2/girugi300.png",
    },
    {
      id: 4,
      name: "연결형",
      thumbnail: "https://koreatrench.com/images/thumbnail/p-sub2/chain300.png",
    },
  ];
  const gratingList = [
    {
      id: 0,
      name: "그레이팅을 선택해주세요",
      thumbnail:
        "https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
    {
      id: 1,
      name: "AL데크그레이팅",
      thumbnail: "https://koreatrench.com/images/thumbnail/p-sub3/aldeck150.png",
    },
    {
      id: 2,
      name: "AL디자인그레이팅",
      thumbnail: "https://koreatrench.com/images/thumbnail/p-sub3/aldesign150.png",
    },
    {
      id: 3,
      name: "AL안전그레이팅",
      thumbnail: "https://koreatrench.com/images/thumbnail/p-sub3/alsafe150.png",
    },
    {
      id: 4,
      name: "스테인리스그레이팅",
      thumbnail: "https://koreatrench.com/images/thumbnail/p-sub3/sussquare200.png",
    },
    {
      id: 5,
      name: "엠보싱다이아논슬립트렌치",
      thumbnail: "https://koreatrench.com/images/thumbnail/p-sub3/sdg_grating.png",
    },
    {
      id: 6,
      name: "디자인그레이팅",
      thumbnail: "https://koreatrench.com/images/thumbnail/p-sub3/design_grating.png",
    },
    {
      id: 7,
      name: "스틸그레이팅",
      thumbnail: "https://koreatrench.com/images/thumbnail/p-sub3/still_grating.png",
    },
    {
      id: 8,
      name: "아연도강판그레이팅",
      thumbnail:
        "https://koreatrench.com/images/thumbnail_500x500/category02/아연도강판그레이팅.jpg",
    },

    {
      id: 9,
      name: "주철디자인그레이팅",
      thumbnail:
        "https://koreatrench.com/data/file/ez_trench/8b6242787be4cf9d89135f966f451c51_gDyuOMmK_587cbd2fb4f365388110d6461be60ab390cb1a5f.jpg",
    },
    {
      id: 10,
      name: "판석그레이팅",
      thumbnail:
        "https://koreatrench.com/data/file/ez_trench/8b6242787be4cf9d89135f966f451c51_MN5lvXPy_b4d627eb74136df5b75a027e5e3e54265873668f.jpg",
    },
  ];
  const etcList = [
    {
      id: 0,
      name: "제품을 선택해주세요",
      thumbnail:
        "https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
    {
      id: 1,
      name: "판상유공관트렌치", // ✅
      thumbnail: "https://koreatrench.com/images/thumbnail_500x500/category04/판상배수판유공관.jpg",
    },

    {
      id: 2,
      name: "2in1",
      thumbnail: "https://koreatrench.com/images/thumbnail/p-sub4/2in1_bottom.png",
    },
    {
      id: 3,
      name: "라인트렌치",
      thumbnail: "https://koreatrench.com/images/thumbnail/p-sub4/line_gutter.png",
    },

    {
      id: 4,
      name: "AL수로관트렌치",
      thumbnail: "https://koreatrench.com/images/thumbnail/p-sub4/alw160-1.png",
    },
    {
      id: 5,
      name: "AL원심력수로관트렌치",
      thumbnail: "https://koreatrench.com/images/thumbnail/p-sub4/cir300-1.png",
    },
    {
      id: 6,
      name: "AL원심력침투수로관",
      thumbnail:
        "https://koreatrench.com/images/thumbnail_500x500/category04/AL원심력침투수로관트렌치.jpg",
    },
    {
      id: 33,
      name: "AL원심력트렌치 (차도용)",
      thumbnail: "https://koreatrench.com/images/thumbnail_500x500/category04/원심력트렌치.jpg",
    },
    {
      id: 7,
      name: "자갈트렌치",
      thumbnail: "https://koreatrench.com/images/thumbnail_500x500/category04/자갈트렌치.jpg",
    },
    {
      id: 8,
      name: "조경블럭트렌치",
      thumbnail: "https://koreatrench.com/images/thumbnail_500x500/category04/조경블럭트렌치.jpg",
    },
    {
      id: 9,
      name: "결로트렌치",
      thumbnail: "https://koreatrench.com/images/thumbnail/p-sub4/jo-trehcj11.jpg",
    },
    {
      id: 10,
      name: "MB",
      thumbnail: "https://koreatrench.com/images/thumbnail_500x500/category04/MB340.jpg",
    },
  ];
  const bigCategory = [
    {
      id: 0,
      name: "제품의 카테고리를 선택해주세요",
      thumbnail:
        "https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
    {
      id: 1,
      name: "트렌치",
      thumbnail: "https://koreatrench.com/images/thumbnail/p-sub1/alt150x40.png",
    },
    {
      id: 2,
      name: "무소음트렌치",

      thumbnail: "https://koreatrench.com/images/thumbnail/p-sub2/hanaro200x100.png",
    },
    {
      id: 3,
      name: "트렌치커버(그레이팅)",
      thumbnail:
        "https://koreatrench.com/images/thumbnail_500x500/category02/AL데크그레이팅150.jpg",
    },
    {
      id: 4,
      name: "토목/건축/조경트렌치",
      thumbnail: "https://koreatrench.com/images/thumbnail/p-sub4/cir300-1.png",
    },
  ];

  const { getRelatedProducts } = useContext(ProductsContext);
  const { handleAdd } = useContext(EstimateContext);
  const [subCategories, updateSubCategories] = useState(defaultSubCategory);
  const [relatedProducts, updateRelatedProducts] = useState(defaultProductCategory);

  //! Chosen ones (선택된 카테고리)
  const [chosenBigCategory, updateChosenBigCategory] = useImmer(bigCategory[0]);
  const [chosenSubCategory, updateChosenSubCategory] = useImmer(defaultSubCategory[0]);
  const [chosenProduct, updateChosenProduct] = useImmer(defaultProductCategory[0]);

  //! Disable 설정
  const [subDisable, updateSubDisable] = useImmer(true);
  const [productDisable, updateProductDisable] = useImmer(true);

  //! ------------------- Functions --------------------------------------------
  //* BigCategory
  const onBigCategoryChosen = (category) => {
    // disabled 설정
    updateSubDisable(false);
    updateProductDisable(true);

    // subCategory
    updateSubDisable(false); // disable 설정

    updateChosenSubCategory(defaultSubCategory[0]);
    _setProperSubCategory(category); // 적절한 subCategory 셋팅하기.
    updateChosenBigCategory(category);

    // chosenSub, product 리셋하기.
    if (category.id === 0) {
      _resetAllCategories();
    }
  };

  //* SubCategory
  const onSubCategoryChosen = (subCategory) => {
    if (subCategory.id !== 0) {
      //! sizeCategory
      updateProductDisable(false);

      //! chosen
      updateChosenSubCategory(subCategory);

      //! Update related products category
      updateRelatedProducts(defaultProductCategory[0]);

      const rawRelatedProducts = getRelatedProducts(subCategory.name);
      const relatedProducts = rawRelatedProducts.map((product, index) => ({
        id: product.id,
        name: product.standard,
        thumbnail: product.thumbnail,
        product,
      }));
      relatedProducts.unshift(defaultProductCategory[0]); // 디폴트 값 넣기

      updateRelatedProducts(relatedProducts);
    }
  };

  const onProductChosen = (product) => {
    let result = true;
    if (product.product.normalPrice === 0) {
      DialogHelper.showInqueryDialog();
    } else {
      const resultOfAdd = handleAdd(product.product);
      if (resultOfAdd) {
        ToastHelper.showAddedToast();
      } else {
        result = false;
      }
    }

    _resetAllCategories();
    return result;
  };

  function _setProperSubCategory(category) {
    if (category.name === "제품의 카테고리를 선택해주세요") {
      updateSubCategories((e) => {
        return [...defaultSubCategory];
      });
    } else if (category.name === "트렌치") {
      updateSubCategories((e) => {
        return [...trenchList];
      });
    } else if (category.name === "무소음트렌치") {
      updateSubCategories((e) => {
        return [...soundlessList];
      });
    } else if (category.name === "트렌치커버(그레이팅)") {
      updateSubCategories((e) => {
        return [...gratingList];
      });
    } else if (category.name === "토목/건축/조경트렌치") {
      updateSubCategories((e) => {
        return [...etcList];
      });
    }
  }

  function _resetAllCategories() {
    updateSubCategories(defaultSubCategory);
    updateRelatedProducts(defaultProductCategory);
    updateChosenBigCategory(bigCategory[0]);
    updateChosenSubCategory(defaultSubCategory[0]);
    updateChosenProduct(defaultProductCategory[0]);
    updateSubDisable(true);
    updateProductDisable(true);
  }
  return (
    <CategoryContext.Provider
      value={{
        subDisable,
        productDisable,
        bigCategory,
        subCategory: subCategories,
        chosenBigCategory,
        relatedProducts,
        updateChosenBigCategory,
        chosenSubCategory,
        chosenProduct,
        onBigCategoryChosen,
        onSubCategoryChosen,
        onProductChosen,
        updateChosenSubCategory,
      }}
    >
      {children}
    </CategoryContext.Provider>
  );
}
