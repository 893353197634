import PropTypes from "prop-types";
import { styles } from "../styled";
import { Page, Text } from "@react-pdf/renderer";
import { PDFGrid } from "../PDFGrid";
import { PDFPHeader } from "../PDFHeader";
import { PDFPHeaderInfo } from "../PDFHeaderInfo";
import { PDFTable } from "../PDFTable/table";
import { PDFTotal } from "../PDFTotal";

PageOne.propTypes = {
  data: PropTypes.array,
};

export function PageOne({ data }) {
  return (
    <Page size="A4" style={styles.page}>
      <PDFGrid mv={0} pa={0}>
        <PDFPHeader data={data} />
        <PDFPHeaderInfo data={data} />
      </PDFGrid>
      <PDFGrid mv={2} pa={0}>
        <PDFTable data={data} />
      </PDFGrid>
      <PDFGrid mv={2} pa={0}>
        <PDFTotal data={data} />
      </PDFGrid>
      <Text
        style={styles.pageNumber}
        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
        fixed
      />
    </Page>
  );
}
