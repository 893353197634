import Divider from "./Divider";

// import SummaryEstimateTotalPrice from "./SummaryEstimateTotalPrice";
import SummaryConnectSocketPrice from "./SummaryConnectSocketPrice";
import SummaryEndSocketPrice from "./SummaryEndSocketPrice";
import SummaryBoltSetPrice from "./SummaryBoltSetPrice";
import SummaryTPartPrice from "./SummaryTPartPrice";
import SummaryLPartPrice from "./SummaryLPartPrice";
import SummaryCrossPartPrice from "./SummaryCrossPartPrice";
import SummaryStraightPartPrice from "./SummaryStraightPartPrice";
import SummaryBottomPipePrice from "./SummaryBottomPipePrice";
import SummaryTwoInOneSideCapPrice from "./SummaryTwoInOneSideCapPrice";
import SummaryProductInfo from "./SummaryProductInfo";
import SummaryTwoInOneBottomPrice from "./SummaryTwoInOneBottomPrice";

export default function SummaryEstimateCard({ estimate }) {
  return (
    <main
      key={estimate.id}
      className={`p-2 sm:p-4 mb-6  mx-1 p:mx-0 rouned-md bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700`}
    >
      <SummaryProductInfo estimate={estimate} />

      {estimate.connectSocketCount >= 0 && (
        <div>
          <Divider />
          <SummaryConnectSocketPrice estimate={estimate} />
        </div>
      )}

      {estimate.endSocketCount >= 0 && (
        <div>
          <Divider />
          <SummaryEndSocketPrice estimate={estimate} />
        </div>
      )}

      {estimate.boltSetAdded && (
        <div>
          <Divider />
          <SummaryBoltSetPrice estimate={estimate} />
        </div>
      )}

      {estimate.tSocketAdded && (
        <div>
          <Divider />
          <SummaryTPartPrice estimate={estimate} />
        </div>
      )}

      {estimate.lSocketAdded && (
        <div>
          <Divider />
          <SummaryLPartPrice estimate={estimate} />
        </div>
      )}

      {estimate.crossSocketAdded && (
        <div>
          <Divider />
          <SummaryCrossPartPrice estimate={estimate} />
        </div>
      )}

      {estimate.straightPipeAdded && (
        <div>
          <Divider />
          <SummaryStraightPartPrice estimate={estimate} />
        </div>
      )}

      {estimate.downPipeAdded && (
        <div>
          <Divider />
          <SummaryBottomPipePrice estimate={estimate} />
        </div>
      )}

      {estimate.twoInOneStraightPipeAdded && (
        <div>
          <Divider />
          <SummaryTwoInOneSideCapPrice estimate={estimate} />
        </div>
      )}

      {estimate.twoInOneDownPipeAdded && (
        <div>
          <Divider />
          <SummaryTwoInOneBottomPrice estimate={estimate} />
        </div>
      )}

      <div>
        <Divider thick={true} />
        {/* <SummaryEstimateTotalPrice estimate={estimate} /> */}
      </div>
    </main>
  );
}
