import { createContext, useCallback, useContext, useEffect } from "react";
import { useImmer } from "use-immer";
import { EstimateContext } from "./EstimatesContext";
import Utils from "../Utils";

// context 생성 (여기에 데이터를 보관)
export const ConstructionContext = createContext();
// Provider도 컴포넌트로 Wrapper 같은 거 같음.
// wrapper안에 children들은 모두 Context 데이터에 접근이 가능.✅
// 그 데이터 = 밑에 value이다.
export function ConstructionProvider({ children }) {
  const [constEstimate, updateConstEstimate] = useImmer({
    managerName: "",
    companyName: "",
    phone: "",
    email: "",
    constructionName: "",
    roadAddress: "",
    jibunAddress: "",
    detailAddress: "",
    location: "",
    forkliftCostBurden: null, // 건설사  시공사
    useForklift: null, //
    needBoyang: null,
    needLevel: null,
    needSlope: null,
    constructionWay: "wet", // 시공 방식 ('dry', 'wet')
    projectType: "new", // 신축인지, 리모델링인지 ('new', 'remodeling')
    heightWithoutIron: null,
    paymentDate: null,
    canSupportCrane: false,
    canSupportForklift: false,
    canGoForkliftToSite: false,
    extraInfo: "",
    drawingBase64: null,
  });

  //! Locations
  const locations = [
    {
      id: 0,
      name: "시공 위치를 선택해주세요",
      thumbnail:
        "https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
    {
      id: 1,
      name: "건물 내부",
      thumbnail: "../indoor.png",
    },
    {
      id: 2,
      name: "건물 외부",
      thumbnail: "../outdoor.png",
    },
    {
      id: 3,
      name: "옥상",
      thumbnail: "../roof.png",
    },
    {
      id: 4,
      name: "도로",
      thumbnail: "../road.png",
    },
    {
      id: 5,
      name: "기타",
      thumbnail: "../etc.png",
    },
  ];

  const sidos = [
    {
      id: 0,
      name: "시공할 지역을 선택해주세요",
      thumbnail:
        "https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
      tripCost: 0,
    },
    {
      id: 1,
      name: "서울특별시",
      thumbnail: "../../sido/seoul.png",
      tripCost: 100000,
    },
    {
      id: 3,
      name: "부산광역시",
      thumbnail: "../../sido/busan.png",
      tripCost: 300000,
    },
    {
      id: 200,
      name: "대구광역시",
      thumbnail: "../../sido/daegu.png",
      tripCost: 300000,
    },
    {
      id: 2,
      name: "인천광역시",
      thumbnail: "../../sido/incheon.png",
      tripCost: 100000,
    },

    {
      id: 4,
      name: "광주광역시",
      thumbnail: "../../sido/gwangju.png",
      tripCost: 200000,
    },

    {
      id: 201,
      name: "대전광역시",
      thumbnail: "../../sido/daejeon.png",
      tripCost: 200000,
    },
    {
      id: 5,
      name: "울산광역시",
      thumbnail: "../../sido/ulsan.png",
      tripCost: 300000,
    },
    {
      id: 100,
      name: "경기도",
      thumbnail: "../../sido/gyeonggido.png",
      tripCost: 100000,
    },
    {
      id: 8,
      name: "충청북도",
      thumbnail: "../../sido/chungbuk.png",
      tripCost: 200000,
    },
    {
      id: 111,
      name: "충청남도",
      thumbnail: "../../sido/chungnam.png",
      tripCost: 200000,
    },
    {
      id: 9,
      name: "전라북도",
      thumbnail: "../../sido/jeollabukdo.png",
      tripCost: 300000,
    },
    {
      id: 10,
      name: "전라남도",
      thumbnail: "../../sido/jeollanamdo.png",

      tripCost: 300000,
    },
    {
      id: 311,
      name: "경상북도",
      thumbnail: "../../sido/gyeongbuk.png",
      tripCost: 300000,
    },
    {
      id: 11,
      name: "경상남도",
      thumbnail: "../../sido/gyeongnam.png",
      tripCost: 300000,
    },
    {
      id: 7,
      name: "강원특별자치지도",
      thumbnail: "../../sido/gangwon.png",
      tripCost: 300000,
    },
    {
      id: 12,
      name: "제주특별자치도",
      thumbnail: "../../sido/jeju.png",
      tripCost: 1000000,
    },
    {
      id: 6,
      name: "세종특별자치시",
      thumbnail: "../../sido/sejong.png",
      tripCost: 200000,
    },
  ];

  //! Chosen ones (선택된 카테고리)
  const [chosenLocation, updateChosenLocation] = useImmer(locations[0]);
  const [chosenSido, updateChosenSido] = useImmer(sidos[0]);
  const [cTotal, updateTotal] = useImmer(1300000); // 기본 시공비 130만원
  const {
    getRemodelingTotalPartsConstructionCost,
    getTotalPartsConstructionCost,
    getPossibleMeter,
    getBagsPrice,
    getBagsCount,
  } = useContext(EstimateContext);

  const getConstructionItems = () => {
    const items = [];

    const totalMeters = getPossibleMeter();

    // 기본 시공비
    if (constEstimate.projectType === "new") {
      // 신축 기본 시공비
      items.push({
        name: "기본 시공비",
        standard: constEstimate.constructionWay === "dry" ? "건식 시공" : "습식 시공",
        unit: "m",
        count: totalMeters,
        price: Utils.addComma(
          Math.round(getTotalPartsConstructionCost(constEstimate.constructionWay) / totalMeters)
        ),
        total: Utils.addComma(getTotalPartsConstructionCost(constEstimate.constructionWay)),
      });
    } else if (constEstimate.projectType === "remodeling") {
      // 리모델링 기본 시공비
      items.push({
        name: "리모델링 기본 시공비",
        standard: constEstimate.constructionWay === "dry" ? "건식 시공" : "습식 시공",
        unit: "m",
        count: totalMeters,
        price: Utils.addComma(Math.round(getRemodelingTotalPartsConstructionCost() / totalMeters)),
        total: Utils.addComma(getRemodelingTotalPartsConstructionCost()),
      });
    }

    // 출장비
    items.push({
      name: "출장비",
      standard: chosenSido.name,
      unit: "ea",
      count: "1",
      price: Utils.addComma(chosenSido.tripCost),
      total: Utils.addComma(chosenSido.tripCost),
    });

    // 보양작업 유무
    if (constEstimate.needBoyang) {
      items.push({
        name: "보양작업",
        standard: "시공",
        unit: "m",
        count: Utils.addComma(totalMeters),
        price: Utils.addComma(5000),
        total: Utils.addComma(totalMeters * 5000),
      });
    }
    // 레벨 작업 | 구배 작업 유무
    if (constEstimate.needLevel || constEstimate.needSlope) {
      let title = "";
      if (constEstimate.needLevel && constEstimate.needSlope) {
        title = "레벨작업 + 구배작업";
      } else if (constEstimate.needLevel) {
        title = "레벨작업";
      } else if (constEstimate.needSlope) {
        title = "구배작업";
      }

      items.push({
        name: title,
        standard: "시공",
        unit: "m",
        count: Utils.addComma(totalMeters),
        price: Utils.addComma(5000),
        total: Utils.addComma(totalMeters * 5000),
      });
    }

    // 지게차 사용 비용
    if (constEstimate.useForklift && constEstimate.forkliftCostBurden === "시공사") {
      items.push({
        name: "지게차 사용",
        standard: "",
        unit: "ea",
        count: 1,
        price: Utils.addComma(70000),
        total: Utils.addComma(70000),
      });
    }

    if (constEstimate.heightWithoutIron === "150-200") {
      items.push({
        name: "무근고 작업",
        standard: "150-200",
        unit: "m",
        count: Utils.addComma(totalMeters),
        price: Utils.addComma(1000),
        total: Utils.addComma(totalMeters * 1000),
      });
    }
    if (constEstimate.projectType === "remodeling") {
      items.push({
        name: "폐기물 처리비",
        standard: "",
        unit: "m",
        count: Utils.addComma(totalMeters),
        price: Utils.addComma(50000), // m당 폐기물 기본 처리비
        total: Utils.addComma(totalMeters * 50000),
      });
    }
    if (constEstimate.projectType === "remodeling") {
      items.push({
        name: "무수축 초경 몰탈",
        standard: "",
        unit: "ea",
        count: Utils.addComma(getBagsCount()), // 몇포가 들어가는지 나타나야 함.
        price: Utils.addComma(30000), // 1포당/m 30,000원
        total: Utils.addComma(getBagsPrice()),
      });
    }

    return items;
  };

  const getTotalConstructionCost = useCallback(() => {
    const basicConstructionCost = 1300000;
    let sumTotal = 0;

    if (constEstimate.projectType === "new") {
      sumTotal = getTotalPartsConstructionCost(constEstimate.constructionWay);
    } else {
      sumTotal = getRemodelingTotalPartsConstructionCost();
      sumTotal += getBagsPrice(); // 초경 몰탈 포대 가격
    }

    const totalMeters = getPossibleMeter();

    // 출장비
    sumTotal += chosenSido.tripCost;

    // 보양작업 유무
    if (constEstimate.needBoyang) {
      sumTotal += 5000 * totalMeters;
    }

    // 레벨 작업 | 구배 작업 유무
    if (constEstimate.needLevel || constEstimate.needSlope) {
      sumTotal += 5000 * totalMeters;
    }

    // 지게차 사용 비용
    if (constEstimate.useForklift && constEstimate.forkliftCostBurden === "시공사") {
      sumTotal += 70000;
    }

    if (constEstimate.heightWithoutIron === "150-200") {
      sumTotal += 1000 * totalMeters;
    }

    // 기본료 미만이면 기본료로 시공비 책정
    if (sumTotal < basicConstructionCost) {
      sumTotal = basicConstructionCost;
    }

    updateTotal(sumTotal);
  }, [
    constEstimate,
    chosenSido,
    getTotalPartsConstructionCost,
    getPossibleMeter,
    getBagsPrice,
    getRemodelingTotalPartsConstructionCost,
    updateTotal,
    // chosenLocation,
  ]);

  useEffect(() => {
    getTotalConstructionCost();
  }, [constEstimate, chosenLocation, chosenSido, getTotalConstructionCost]);

  const onLocationChosen = (location) => {
    updateChosenLocation(location);
  };

  const onSidoChosen = (sido) => {
    updateChosenSido(sido);
  };
  /////////////////////////////////////////////

  const setHeightWithoutIron = (value) => {
    updateConstEstimate((est) => {
      est.heightWithoutIron = value;
    });
  };

  const setRoadJibun = (address) => {
    updateConstEstimate((est) => {
      est.roadAddress = address.road;
      est.jibunAddress = address.jibun;
    });
  };

  const setManagerName = (name) => {
    updateConstEstimate((est) => {
      est.managerName = name;
    });
  };
  const setCompanyName = (name) => {
    updateConstEstimate((est) => {
      est.companyName = name;
    });
  };

  const setPhone = (phone) => {
    updateConstEstimate((est) => {
      est.phone = phone;
    });
  };

  const setEmail = (value) => {
    updateConstEstimate((est) => {
      est.email = value;
    });
  };
  const setProjectType = (value) => {
    updateConstEstimate((est) => {
      est.projectType = value;
    });
  };

  const setConstructionName = (value) => {
    updateConstEstimate((est) => {
      est.constructionName = value;
    });
  };

  const setDetailAddress = (value) => {
    updateConstEstimate((est) => {
      est.detailAddress = value;
    });
  };

  const setLocation = (value) => {
    updateConstEstimate((est) => {
      est.location = value;
    });
  };

  const setNeedBoyang = (value) => {
    updateConstEstimate((est) => {
      est.needBoyang = value;
    });
  };

  const setNeedLevel = (value) => {
    updateConstEstimate((est) => {
      est.needLevel = value;
    });
  };

  const setNeedSlope = (value) => {
    updateConstEstimate((est) => {
      est.needSlope = value;
    });
  };

  const setConstructionWay = (value) => {
    updateConstEstimate((est) => {
      est.constructionWay = value;
    });
  };

  const setAutoForkliftCostBurden = (value) => {
    updateConstEstimate((est) => {
      est.forkliftCostBurden = value;
    });
  };

  const setAutoUseForklift = (value) => {
    updateConstEstimate((est) => {
      est.useForklift = value;
    });
  };

  const setPaymentDate = (value) => {
    updateConstEstimate((est) => {
      est.paymentDate = value;
    });
  };

  const setCanSupportCrane = (value) => {
    updateConstEstimate((est) => {
      est.canSupportCrane = value;
    });
  };

  const setCanSupportForklift = (value) => {
    updateConstEstimate((est) => {
      est.canSupportForklift = value;
    });
  };

  const setCanGoForkliftToSite = (value) => {
    updateConstEstimate((est) => {
      est.canGoForkliftToSite = value;
    });
  };
  const setExtraInfo = (value) => {
    updateConstEstimate((est) => {
      est.extraInfo = value;
    });
  };
  const setDrawingBase64 = (value) => {
    updateConstEstimate((est) => {
      est.drawingBase64 = value;
    });
  };

  const printEst = () => {
    // console.log(constEstimate);
  };

  const isDisabled = () => {
    let disabled = false;

    if (chosenSido === locations[0]) {
      disabled = true;
    }
    if (constEstimate.needBoyang === null) {
      disabled = true;
    }
    if (constEstimate.needLevel === null) {
      disabled = true;
    }
    if (constEstimate.needSlope === null) {
      disabled = true;
    }
    if (constEstimate.heightWithoutIron === null && constEstimate.constructionWay === "dry") {
      disabled = true;
    }
    if (constEstimate.useForklift === null) {
      disabled = true;
    }
    if (constEstimate.useForklift && constEstimate.forkliftCostBurden === null) {
      disabled = true;
    }

    return disabled;
  };

  return (
    // value = 자식 컴포들이 사용할 것들을 명시하면 된다.
    <ConstructionContext.Provider
      value={{
        locations,
        chosenLocation,
        chosenSido,
        sidos,
        constEstimate,
        setRoadJibun,
        setManagerName,
        getTotalConstructionCost,
        printEst,
        setPhone,
        setEmail,
        setConstructionName,
        setDetailAddress,
        setLocation,
        setNeedBoyang,
        setNeedLevel,
        setNeedSlope,
        setConstructionWay,
        setHeightWithoutIron,
        setPaymentDate,
        setCanSupportCrane,
        setCanSupportForklift,
        setCanGoForkliftToSite,
        setExtraInfo,
        setDrawingBase64,
        setProjectType,
        onLocationChosen,
        onSidoChosen,
        setAutoForkliftCostBurden,
        setAutoUseForklift,
        setCompanyName,
        cTotal,
        getConstructionItems,
        isDisabled,
      }}
    >
      {children}
    </ConstructionContext.Provider>
  );
}
