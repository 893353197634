import React, { useContext, useEffect, useState } from "react";
import { ConstructionContext } from "../../context/ConstructionContext";

export default function Slope() {
  const [selectedOption, setSelectedOption] = useState("");
  const { setNeedSlope, constEstimate } = useContext(ConstructionContext);
  const handleChange = (e) => {
    const value = e.target.value;
    setSelectedOption(value);
    if (value === "need") {
      setNeedSlope(true);
    } else {
      setNeedSlope(false);
    }
  };
  useEffect(() => {
    if (constEstimate.needSlope === null) {
      return;
    }
    if (constEstimate.needSlope) {
      setSelectedOption("need");
    } else {
      setSelectedOption("noNeed");
    }
  }, [constEstimate.needSlope]);
  return (
    <fieldset className="field-set-style">
      <legend className="label-style">구배 유무</legend>

      <div className="mt-4 space-y-6 border p-5  rounded">
        <div className="flex items-center gap-x-3">
          <input
            id="need-slope"
            name="slopes"
            type="radio"
            value="need"
            checked={selectedOption === "need"}
            className={`h-4 w-4 border-gray-300 text-red-600 focus:ring-red-600 ${
              selectedOption === "need" ? "accent-red-500" : ""
            }`}
            onChange={handleChange}
          />
          <label htmlFor="need-slope" className="label-style">
            구배 작업 필요
          </label>
        </div>
        <div className="flex items-center gap-x-3">
          <input
            id="no-need-slope"
            name="slopes"
            type="radio"
            value="noNeed"
            checked={selectedOption === "noNeed"}
            className={`h-4 w-4 border-gray-300 text-red-600 focus:ring-red-600 ${
              selectedOption === "noNeed" ? "accent-red-500" : ""
            }`}
            onChange={handleChange}
          />
          <label htmlFor="no-need-slope" className="label-style">
            구배 작업 불필요
          </label>
        </div>
      </div>
    </fieldset>
  );
}
