import React from "react";
import PartsThumbnail from "./ui/PartsThumbnail";
import Utils from "../Utils";
import ItemTitle from "./ui/ItemTitle";

export default function SummaryBottomPipePrice({ estimate }) {
  return (
    <div className="flex">
      <PartsThumbnail url={estimate.tThumbnail} />

      <div className="flex-1 flex justify-between items-center px-0 vsp:px-4 py-2">
        {/* button handler  */}
        <div className="pl-3">
          <ItemTitle title={"파이프 가공 (하부)"} />
        </div>

        {/* price and meter  ✅ */}
        <div className="flex flex-col justify-center text-right">
          <p className="">{estimate.pipeDownCount}개</p>
          <p className="font-bold">
            {Utils.addComma(estimate.pipeDownCount * estimate.pipePrice)}원
          </p>
        </div>
      </div>
    </div>
  );
}
