import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import React, { Fragment } from "react";

export default function DialogBodyPipeSizesDropdown({
  customPipeSizes,
  chosenSize,
  onSizeDropDownChosen,
}) {
  const handleChange = (e) => {
    onSizeDropDownChosen(e);
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  return (
    <Listbox value={chosenSize} onChange={handleChange}>
      {({ open }) => {
        const chosenImagePath =
          chosenSize === "Ø30"
            ? "../../pipe30.png"
            : chosenSize === "Ø50"
            ? "../../pipe50.png"
            : chosenSize === "Ø70"
            ? "../../pipe70.png"
            : chosenSize === "Ø90"
            ? "../../pipe90.png"
            : chosenSize === "Ø100"
            ? "../../pipe100.png"
            : "../../pipe.jpg";
        return (
          <>
            <div className="relative pt-4 md:pt-0">
              <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
                <div className="flex items-center">
                  {chosenSize !== "파이프 규격을 선택해주세요" ? (
                    <img
                      src={chosenImagePath}
                      alt=""
                      className="h-10 w-10 flex-shrink-0 rounded-full bg-white"
                    />
                  ) : (
                    <div className="h-10" />
                  )}
                  <span className="ml-3 block truncate">{chosenSize}</span>
                </div>
                <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                  <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {customPipeSizes.map((size) => (
                    <Listbox.Option
                      key={size}
                      className={({ active }) =>
                        classNames(
                          active ? "bg-indigo-600 text-white" : "text-gray-900",
                          "relative cursor-default select-none py-2 pl-3 pr-9 "
                        )
                      }
                      value={size}
                    >
                      {({ chosenBigCategory, active }) => {
                        const imagePath =
                          size === "Ø30"
                            ? "../../pipe30.png"
                            : size === "Ø50"
                            ? "../../pipe50.png"
                            : size === "Ø70"
                            ? "../../pipe70.png"
                            : size === "Ø90"
                            ? "../../pipe90.png"
                            : size === "Ø100"
                            ? "../../pipe100.png"
                            : "../../pipe.jpg";
                        return (
                          <>
                            <div className="flex items-center">
                              {size !== "파이프 규격을 선택해주세요" ? (
                                <img
                                  src={imagePath}
                                  alt=""
                                  className="h-10 w-10 flex-shrink-0 rounded-full bg-white"
                                />
                              ) : (
                                <div className="h-10"></div>
                              )}
                              <span
                                className={classNames(
                                  chosenBigCategory ? "font-semibold " : "font-normal",
                                  "ml-3 block truncate"
                                )}
                              >
                                {size}
                              </span>
                            </div>

                            {chosenBigCategory ? (
                              <span
                                className={classNames(
                                  active ? "text-white" : "text-indigo-600",
                                  "absolute inset-y-0 right-0 flex items-center pr-4 "
                                )}
                              >
                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                              </span>
                            ) : null}
                          </>
                        );
                      }}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        );
      }}
    </Listbox>
  );
}
