import React, { useState } from "react";

import { Fragment, useContext } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { CategoryContext } from "../context/CategoryContext";
import CouplingPartsDialog from "./CouplingPartsDialog";
import { ProductsContext } from "../context/ProductsContext";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ProductCategory() {
  const [isOpen, setOpen] = useState(false);
  const [couplingParts, setCouplingParts] = useState([]);
  const [msg, setMsg] = useState("msg");
  const { productDisable, relatedProducts, chosenProduct, onProductChosen } =
    useContext(CategoryContext);
  const { getCouplingProducts, recommendation } = useContext(ProductsContext);

  const handleChange = (e) => {
    const result = onProductChosen(e);
    if (result === false) return;
    if (e.product.normalPrice === 0) return;
    if (recommendation === false) return;

    const a = getCouplingProducts(e.id);
    setCouplingParts(a);

    if (a.length !== 0) {
      setMsg(() => {
        if (e.product.upOrDown === "상부") {
          return "선택하신 제품과 세트로 선택하실 수 있는 하부 트렌치(배수로) 리스트 입니다.";
        } else {
          return "선택하신 제품과 세트로 선택하실 수 있는 상부 그레이팅(덮개) 리스트 입니다";
        }
      });
      setOpen(true);
    }
  };

  return (
    <div>
      <>
        {isOpen && (
          <CouplingPartsDialog
            open={isOpen}
            setOpen={setOpen}
            couplingParts={couplingParts}
            msg={msg}
          />
        )}
      </>
      <Listbox value={chosenProduct} onChange={handleChange} disabled={productDisable}>
        {({ open }) => (
          <>
            <div className="relative pt-4 md:pt-0">
              <Listbox.Button
                className={`relative w-full cursor-default rounded-md ${
                  productDisable ? "bg-gray-100" : "bg-white"
                } py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6`}
              >
                <div className="flex items-center">
                  {chosenProduct.id !== 0 ? (
                    <img
                      src={chosenProduct.thumbnail}
                      alt=""
                      className="h-10 w-10 flex-shrink-0 rounded-full bg-white"
                    />
                  ) : (
                    <div className="h-10"></div>
                  )}
                  <span className="ml-3 block truncate">{chosenProduct.name}</span>
                </div>
                <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                  <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {relatedProducts.map((product) => (
                    <Listbox.Option
                      key={product.id}
                      className={({ active }) =>
                        classNames(
                          active ? "bg-indigo-600 text-white" : "text-gray-900",
                          "relative cursor-default select-none py-2 pl-3 pr-9 "
                        )
                      }
                      value={product}
                    >
                      {({ chosenProduct, active }) => {
                        return (
                          <>
                            <div className="flex items-center">
                              {product.id !== 0 ? (
                                <img
                                  src={product.thumbnail}
                                  alt=""
                                  className="h-10 w-10 flex-shrink-0 rounded-full bg-white"
                                />
                              ) : (
                                <div className="h-10"></div>
                              )}
                              <span
                                className={classNames(
                                  chosenProduct ? "font-semibold " : "font-normal",
                                  "ml-3 block truncate"
                                )}
                              >
                                {product.name}
                              </span>
                            </div>

                            {chosenProduct ? (
                              <span
                                className={classNames(
                                  active ? "text-white" : "text-indigo-600",
                                  "absolute inset-y-0 right-0 flex items-center pr-4 "
                                )}
                              >
                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                              </span>
                            ) : null}
                          </>
                        );
                      }}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
  );
}
