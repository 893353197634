import toast from "react-hot-toast";

export default class ToastHelper {
  static showErrorMsg(msg) {
    toast.error(msg, {
      style: {
        border: "1px solid #713200",
        padding: "16px",
        color: "#713200",
      },
    });
  }

  static showAddedToast() {
    toast.success("추가 완료 !", {
      style: {
        border: "1px solid #713200",
        padding: "16px",
        color: "#713200",
      },
    });
  }
  static showToast(msg) {
    toast.success(msg, {
      position: "bottom-center", // 화면 중앙에 위치
      style: {
        border: "1px solid #713200",
        padding: "16px",
        color: "#713200",
      },
    });
  }

  static showReachedMaximumSize() {
    toast.error("최대치에 도달했습니다.", {
      style: {
        border: "1px solid #713200",
        padding: "16px",
        color: "#713200",
      },
    });
  }

  static showPDFToast() {
    toast.success("개발 예정 입니다", {
      style: {
        border: "1px solid #FF0000",
        padding: "16px",
        // color: "#FFFFFF",
        fontWeight: "bold",
        // backgroundColor: "#FF0000",
      },
      position: "bottom-center",
      duration: 3000,
      icon: "🛠️",
    });
  }

  static showDeletedToast() {
    toast.error("삭제 완료 !", {
      style: {
        border: "1px solid #713200",
        padding: "16px",
        color: "#713200",
      },
      duration: 1700,
    });
  }

  static showMinimumOrderToast(count) {
    toast(`이 제품은 최소 주문 수량이 있습니다 (${count})`, {
      style: {
        border: "1px solid #713200",
        padding: "16px",
        color: "#713200",
      },
      duration: 3000,
      icon: "📌",
    });
  }
}
