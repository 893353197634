import React, { useContext } from "react";
import { ConstructionContext } from "../../context/ConstructionContext";
import { PdfContext } from "../../context/PdfContext";

export default function Email({ isForPdf = false }) {
  const { setEmail, constEstimate } = useContext(ConstructionContext);
  const { setClientEmail, pdfData } = useContext(PdfContext);

  const handleChange = (e) => {
    const value = e.target.value;
    if (isForPdf) {
      setClientEmail(value);
    } else {
      setEmail(value);
    }
  };
  return (
    <div className={`sm:col-span-3 ${isForPdf ? "mb-2" : ""}`}>
      <label htmlFor="email" className="label-style">
        이메일
      </label>
      <div className="mt-2">
        <input
          id="email"
          name="email"
          type="email"
          autoComplete="email"
          className="one-line-input-style"
          onChange={handleChange}
          value={isForPdf ? pdfData.client.email : constEstimate.email}
        />
      </div>
    </div>
  );
}
