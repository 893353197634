import React from "react";

export default function CountInput({ onBlur, value, onChange, disabled = false }) {
  return (
    <input
      type="number"
      id="unit"
      name="unit"
      disabled={disabled}
      onBlur={onBlur}
      value={value}
      onChange={onChange}
      className="bg-transparent w-14 np:w-16 text-center border-b-2"
    />
  );
}
