import { createContext, useContext } from "react";
import { useImmer } from "use-immer";
import { EstimateContext } from "./EstimatesContext";
import Utils from "../Utils";
import SidePipeOptionDialogBody from "../components/SidePipeOptionDialogBody";
import BottomPipeOptionDialogBody from "../components/BottomPipeOptionDialogBody";
import PdfDialogBody from "../components/PdfDialogBody";
import { ConstructionContext } from "./ConstructionContext";
import OrderDialogBody from "../components/OrderDialogBody";
import AllPdfDialogBody from "../components/AllPdfDialogBody";

export const PdfContext = createContext();

export function PdfProvider({ children }) {
  const { getSumPrice, estimates } = useContext(EstimateContext);

  const { getTotalConstructionCost, getConstructionItems, cTotal } =
    useContext(ConstructionContext);

  const [pdfData, updatePdfData] = useImmer({
    title: "제품 견적서",

    company: {
      phone: "032-561-0303",
      fax: "032-561-3586",
      bizNumber: "137-81-41694",
      address: "인천 서구 마중로 174 ( 오류동 )",
      bankName: "기업은행 (예금주)",
      bankClientName: "코리아트렌치 주식회사",
      bankNumber: "237-037410-01-017",
      logo: "full_logo.png",
    },

    order: {
      bizCertificateBase64: "", // 사업자 등록증 base64
      fileName: "", // 파일 이름
      depositorName: "", // 입금자명
      paymentDate: "", // 입금 예정일
      receiverName: "", // 받는 분 이름
      receiverPhone: "", // 받는 분 핸드폰
      roadAddress: "", // 받는 분 도로
      jibunAddress: "", // 받는 분 지번
      detailAddress: "", // 받는 분 상세
      deliveryWay: "", // 배송 방법
      note: "", // 비고
    },

    client: {
      name: "",
      phone: "",
      email: "",
      ip: "",
      device: "",
    },

    estimate: {
      id: "",
      manager: "자동 견적",
      issuedAt: "",
      expiredAt: "",
      generalInfo: [
        "",
        "해당 견적에 노출되는 금액은 배송비 별도 입니다.",
        "색인 번호는 다음 장에서 이미지로 확인하실 수 있습니다.",
        "반품시 사용여부와 관계없이 기본 30% 감가상각 적용됩니다.",
        "자동 견적임으로 실제 견적과 차이가 있을 수 있습니다.",
      ],
      total: "",
      vat: "",
      finalTotal: "",
      items: [
        {
          name: "",
          standard: "",
          unit: "",
          count: "",
          price: "",
          total: "",
        },
      ],
      photos: [],

      // TODO : Make Function ( estiamte 비고 to extraInfos ) ✅
      extraInfos: [],
    },

    productEstimateForConstruction: {
      total: "",
      vat: "",
      finalTotal: "",
      items: [
        {
          name: "",
          standard: "",
          unit: "",
          count: "",
          price: "",
          total: "",
        },
      ],
    },

    constructionEstimateForAll: {
      total: "",
      vat: "",
      finalTotal: "",
      items: [
        {
          name: "",
          standard: "",
          unit: "",
          count: "",
          price: "",
          total: "",
        },
      ],
    },
  });

  const [dialogBodies, updateDialogBodies] = useImmer([]);

  const [loading, setLoading] = useImmer(false);

  const [dialogOpen, setDialogOpen] = useImmer(false);

  // 제품 다이얼로그 바디 생성
  const generateDialogBodies = (forPDF = true, withClientInfo = true) => {
    const items = [];
    let hasPrevButton = false;

    estimates.forEach((e) => {
      if (e.pipeStraightCount > 0) {
        items.push(<SidePipeOptionDialogBody estimate={e} length={e.pipeStraightCount} />);
        hasPrevButton = true;
      }
      if (e.pipeDownCount > 0) {
        items.push(<BottomPipeOptionDialogBody estimate={e} length={e.pipeDownCount} />);
        hasPrevButton = true;
      }
    });

    if (forPDF && withClientInfo) {
      items.push(<PdfDialogBody hasPrevButton={hasPrevButton} />);
    } else if (!forPDF && withClientInfo) {
      items.push(<OrderDialogBody hasPrevButton={hasPrevButton} />);
    }

    updateDialogBodies((data) => {
      data.splice(0, data.length, ...items);
    });
  };

  // 시공 다이얼로그 바디 생성
  const generateConstructionDialogBody = () => {
    const body = <PdfDialogBody hasPrevButton={false} isForConstruction={true} />;
    updateDialogBodies((data) => {
      data.splice(0, data.length, body);
    });
  };

  // 통합(제품 + 시공) 다이얼로그 바디 생성
  const generateAllDialogBody = () => {
    const items = [];
    let hasPrevButton = false;

    estimates.forEach((e) => {
      if (e.pipeStraightCount > 0) {
        items.push(<SidePipeOptionDialogBody estimate={e} length={e.pipeStraightCount} />);
        hasPrevButton = true;
      }
      if (e.pipeDownCount > 0) {
        items.push(<BottomPipeOptionDialogBody estimate={e} length={e.pipeDownCount} />);
        hasPrevButton = true;
      }
    });

    items.push(<AllPdfDialogBody hasPrevButton={hasPrevButton} />);
    updateDialogBodies((data) => {
      data.splice(0, data.length, ...items);
    });
  };

  const setClientName = (value) => {
    updatePdfData((data) => {
      data.client.name = value;
    });
  };

  const setFileName = (value) => {
    updatePdfData((data) => {
      data.order.fileName = value;
    });
  };

  const setPaymentDate = (value) => {
    updatePdfData((data) => {
      const originalDate = new Date(value);
      const daysOfWeek = ["일요일", "월요일", "화요일", "수요일", "목요일", "금요일", "토요일"];

      // 원하는 형식에 맞게 날짜를 문자열로 변환
      const formattedDateString = `${originalDate.getFullYear()}년 ${String(
        originalDate.getMonth() + 1
      ).padStart(2, "0")}월 ${String(originalDate.getDate()).padStart(2, "0")}일 (${
        daysOfWeek[originalDate.getDay()]
      })`;
      data.order.paymentDate = formattedDateString;
    });
  };

  const setClientAddress = (value) => {
    updatePdfData((data) => {
      data.client.address = value;
    });
  };

  const setReceiverName = (value) => {
    updatePdfData((data) => {
      data.order.receiverName = value;
    });
  };

  const setReceiverPhone = (value) => {
    updatePdfData((data) => {
      data.order.receiverPhone = value;
    });
  };

  const setClientPhone = (value) => {
    updatePdfData((data) => {
      data.client.phone = value;
    });
  };

  const setClientEmail = (value) => {
    updatePdfData((data) => {
      data.client.email = value;
    });
  };

  const setDetailAddress = (value) => {
    updatePdfData((data) => {
      data.order.detailAddress = value;
    });
  };

  const setBizCertificateBase64 = (value) => {
    updatePdfData((data) => {
      data.order.bizCertificateBase64 = value;
    });
  };

  const setDepositorName = (value) => {
    updatePdfData((data) => {
      data.order.depositorName = value;
    });
  };

  const setDeliveryWay = (value) => {
    updatePdfData((data) => {
      data.order.deliveryWay = value;
    });
  };

  const setRoadJibun = (address) => {
    updatePdfData((data) => {
      data.order.roadAddress = address.road;
      data.order.jibunAddress = address.jibun;
    });
  };

  const setNote = (note) => {
    updatePdfData((data) => {
      data.order.note = note;
    });
    console.log(pdfData.order.note);
  };

  const clearClientEmailPhoneNameAddress = () => {
    updatePdfData((data) => {
      data.client.email = "";
      data.client.phone = "";
      data.client.address = "";
      data.client.name = "";
    });
  };

  const makeConstructionEstimatesToPdfData = () => {
    const id = getPdfId();
    getTotalConstructionCost();
    const items = getConstructionItems();
    const prodTotal = getSumPrice();
    const prodItems = estimatesToItems();
    const photos = [];

    updatePdfData((data) => {
      data.estimate.id = id;
      data.title = "시공 견적서";
      data.estimate.generalInfo = [
        "",
        "",
        "",
        "시공 견적은 현장 조건에 따라 가격이 매우 달라집니다",
        "대략적인 참고만 해주세요",
      ];
      data.estimate.issuedAt = getIssuedAt();
      data.estimate.expiredAt = getExpiredAt();
      data.estimate.total = Utils.addComma(cTotal);
      data.estimate.vat = Utils.addComma(cTotal / 10);
      data.estimate.finalTotal = Utils.addComma(cTotal / 10 + cTotal);
      data.estimate.items = items;
      data.estimate.photos = photos;
      data.productEstimateForConstruction.total = Utils.addComma(prodTotal);
      data.productEstimateForConstruction.vat = Utils.addComma(prodTotal / 10);
      data.productEstimateForConstruction.finalTotal = Utils.addComma(prodTotal / 10 + prodTotal);
      data.productEstimateForConstruction.items = prodItems;
    });

    // updatePdfData((data) => {
    //   data.constEstimate.id = id;
    //   data.title = "시공 견적서";
    //   data.constEstimate.generalInfo = [
    //     "",
    //     "",
    //     "",
    //     "시공 견적은 현장 조건에 따라 가격이 매우 달라집니다",
    //     "대략적인 참고만 해주세요",
    //   ];
    //   data.constEstimate.issuedAt = getIssuedAt();
    //   data.constEstimate.expiredAt = getExpiredAt();
    //   data.constEstimate.total = Utils.addComma(total);
    //   data.constEstimate.vat = Utils.addComma(total / 10);
    //   data.constEstimate.finalTotal = Utils.addComma(total / 10 + total);
    //   data.constEstimate.items = items;
    //   data.constEstimate.photos = photos;
    // });
  };

  const makeEstimatesToPdfData = async () => {
    const id = getPdfId();
    const total = getSumPrice();
    const items = estimatesToItems();
    const photos = estimatesToPhotos();

    updatePdfData((data) => {
      data.estimate.id = id;
      data.title = "제품 견적서";
      data.estimate.issuedAt = getIssuedAt();
      data.estimate.expiredAt = getExpiredAt();
      data.estimate.total = Utils.addComma(total);
      data.estimate.vat = Utils.addComma(total / 10);
      data.estimate.finalTotal = Utils.addComma(total / 10 + total);
      data.estimate.items = items;
      data.estimate.photos = photos;
    });
  };

  // TODO : 종합 PDF Data 만들기.
  const makeAllEstimatesToPdfData = async () => {
    const id = getPdfId();
    getTotalConstructionCost();
    const cItems = getConstructionItems();
    const pItems = estimatesToItems();
    const allItems = [...pItems, ...cItems];
    const photos = estimatesToPhotos();
    const pTotal = getSumPrice();
    const allTotal = cTotal + pTotal;

    updatePdfData((data) => {
      data.estimate.id = id;
      data.title = "통합 견적서";
      data.estimate.generalInfo = [
        "해당 견적에 노출되는 금액은 배송비 별도 입니다.",
        "색인 번호는 다음 장에서 이미지로 확인하실 수 있습니다.",
        "반품시 사용여부와 관계없이 기본 30% 감가상각 적용됩니다.",
        "제품 견적은 실제 견적과 차이가 있을 수 있습니다.",
        "시공 견적은 현장 조건에 따라 가격이 매우 달라집니다",
      ];
      data.estimate.issuedAt = getIssuedAt();
      data.estimate.expiredAt = getExpiredAt();
      data.estimate.total = Utils.addComma(allTotal);
      data.estimate.vat = Utils.addComma(allTotal / 10);
      data.estimate.finalTotal = Utils.addComma(allTotal / 10 + allTotal);
      data.estimate.items = allItems;
      data.estimate.photos = photos;

      data.productEstimateForConstruction.total = Utils.addComma(pTotal);
      data.productEstimateForConstruction.vat = Utils.addComma(pTotal / 10);
      data.productEstimateForConstruction.finalTotal = Utils.addComma(pTotal / 10 + pTotal);
      data.productEstimateForConstruction.items = pItems;

      data.constructionEstimateForAll.total = Utils.addComma(cTotal);
      data.constructionEstimateForAll.vat = Utils.addComma(cTotal / 10);
      data.constructionEstimateForAll.finalTotal = Utils.addComma(cTotal / 10 + cTotal);
      data.constructionEstimateForAll.items = cItems;

      // data.constructionEstimateForAll.total =
    });
  };

  const setClientIp = async () => {
    try {
      const response = await fetch("https://api.ip.pe.kr/");
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.text();

      updatePdfData((value) => {
        value.client.ip = data;
      });

      console.log("IP 주소:", data);
    } catch (error) {
      console.error("IP 조회 오류:", error.message);
    }
  };

  //! 내부 함수들
  function estimatesToItems() {
    const items = [];

    estimates.forEach((p) => {
      // console.log(`${p.name} 제품의 갯수 ${p.productCount}`); // ✅
      const item = {
        name: p.name,
        standard: p.standard,
        unit: p.unit === "개" ? "ea" : "m",
        count: Utils.addComma(p.productCount * p.displayMeter),
        price: Utils.addComma(p.normalPrice),
        total: Utils.addComma(p.normalPrice * p.productCount * p.displayMeter),
        thumbnail: p.thumbnail,
      };
      items.push(item);

      if (p.connectSocketCount > 0) {
        // console.log(`연결 소켓 갯수 ${p.connectSocketCount}`); // ✅
        const item = {
          name: "연결 소켓",
          standard: "",
          unit: "ea",
          count: Utils.addComma(p.connectSocketCount),
          price: Utils.addComma(p.conSocketPrice),
          total: Utils.addComma(p.conSocketPrice * p.connectSocketCount),
          thumbnail: p.conSocketThumbnail,
        };
        items.push(item);
      }

      if (p.endSocketCount > 0) {
        // console.log(`마구리 갯수 ${p.endSocketCount}`); // ✅
        const item = {
          name: "엔드 소켓 (마구리)",
          standard: "",
          unit: "ea",
          count: Utils.addComma(p.endSocketCount),
          // count: p.endSocketCount,
          price: Utils.addComma(p.endSocketPrice),
          total: Utils.addComma(p.endSocketPrice * p.endSocketCount),
        };
        items.push(item);
      }

      if (p.boltSetCount > 0 && p.boltSetAdded) {
        // console.log(`볼트셋트 갯수 ${p.boltSetCount}`); // ✅
        const item = {
          name: "볼트세트",
          standard: "",
          unit: "ea",
          count: Utils.addComma(p.boltSetCount),
          price: Utils.addComma(p.boltSetPrice),
          total: Utils.addComma(p.boltSetPrice * p.boltSetCount),
        };
        items.push(item);
      }
      if (p.safeCoverCount > 0 && p.safeCoverAdded) {
        // console.log(`볼트셋트 갯수 ${p.boltSetCount}`); // ✅
        const item = {
          name: "안전 커버",
          standard: "",
          unit: "ea",
          count: Utils.addComma(p.safeCoverCount),
          price: Utils.addComma(p.safeCoverPrice),
          total: Utils.addComma(p.safeCoverPrice * p.safeCoverCount),
        };
        items.push(item);
      }
      if (p.tSocketCount > 0) {
        // console.log(`T자 가공 갯수 ${p.tSocketCount}`); // ✅
        const item = {
          name: "T자 가공",
          standard: "",
          unit: "ea",
          count: Utils.addComma(p.tSocketCount),
          price: Utils.addComma(p.tPrice),
          total: Utils.addComma(p.tPrice * p.tSocketCount),
        };
        items.push(item);
      }
      if (p.lSocketCount > 0) {
        // console.log(`코너 가공 갯수 ${p.lSocketCount}`); // ✅
        const item = {
          name: "L자 가공",
          standard: "",
          unit: "ea",
          count: Utils.addComma(p.lSocketCount),
          price: Utils.addComma(p.lPrice),
          total: Utils.addComma(p.lPrice * p.lSocketCount),
        };
        items.push(item);
      }

      if (p.crossSocketCount > 0) {
        // console.log(`크로스 가공 갯수 ${p.crossSocketCount}`); // ✅
        const item = {
          name: "크로스 가공",
          standard: "",
          unit: "ea",
          count: Utils.addComma(p.crossSocketCount),
          price: Utils.addComma(p.crossPrice),
          total: Utils.addComma(p.crossPrice * p.crossSocketCount),
        };
        items.push(item);
      }

      if (p.pipeStraightCount > 0) {
        const filteredArray = p.sidePipeOptions.items.filter((item) => item.count !== 0);
        for (let i = 0; i < filteredArray.length; i++) {
          const option = filteredArray[i];
          const size = option.size;
          const count = option.count;

          const item = {
            name: "파이프 가공 (측면)",
            standard: `${size}`,
            unit: "ea",
            count: Utils.addComma(count),
            price: Utils.addComma(p.pipePrice),
            total: Utils.addComma(p.pipePrice * count),
          };
          items.push(item);
        }
      }
      if (p.pipeDownCount > 0) {
        const filteredArray = p.downPipeOptions.items.filter((item) => item.count !== 0);
        for (let i = 0; i < filteredArray.length; i++) {
          const option = filteredArray[i];
          const size = option.size;
          const location = option.location;
          const count = option.count;

          const item = {
            name: "파이프 가공 (하부)",
            standard: `${size} ${location}`,
            unit: "ea",
            count: Utils.addComma(count),
            price: Utils.addComma(p.pipePrice),
            total: Utils.addComma(p.pipePrice * count),
          };
          items.push(item);
        }
      }

      if (p.twoInOneSideCapCount > 0) {
        // console.log(`2in1 측면 갯수 ${p.twoInOneSideCapCount}`); // ✅
        const item = {
          name: "2in1 사이드 캡",
          standard: "",
          unit: "ea",
          count: Utils.addComma(p.twoInOneSideCapCount),
          price: Utils.addComma(p.twoInOneSideCapPrice),
          total: Utils.addComma(p.twoInOneSideCapPrice * p.twoInOneSideCapCount),
        };
        items.push(item);
      }

      if (p.twoInOneBottomCapCount > 0) {
        // console.log(`2in1 하부 갯수 ${p.twoInOneBottomCapCount}`); // ✅
        const item = {
          name: "2in1 하부 캡",
          standard: "",
          unit: "ea",
          count: Utils.addComma(p.twoInOneBottomCapCount),
          price: Utils.addComma(p.twoInOneBottomCapPrice),
          total: Utils.addComma(p.twoInOneBottomCapPrice * p.twoInOneBottomCapCount),
        };
        items.push(item);
      }
    });

    return items;
  }

  function estimatesToPhotos() {
    const photos = [];
    let index = 1;
    estimates.forEach(async (p) => {
      /// 제품 이미지 추가
      // if (p.thumbnail.includes("https://koreatrench.com/")) {
      //   p.thumbnail = "http://cors-anywhere.herokuapp.com/" + p.thumbnail;
      //   console.log(p.thumbnail);
      // }

      const item = {
        name: p.name,
        standard: p.standard,
        thumbnail: "https://corsproxy.io/?" + encodeURIComponent(p.thumbnail),
        index: index,
      };
      photos.push(item);

      /// 단면도 추가
      if (p.section !== "") {
        const item = {
          name: p.name,
          standard: "단면도",
          // thumbnail: "http://cors-anywhere.herokuapp.com/" + p.section,
          thumbnail: "https://corsproxy.io/?" + encodeURIComponent(p.section),
          index: index,
        };
        photos.push(item);
      }
      index += 1;

      if (p.connectSocketCount > 0) {
        const item = {
          name: "연결 소켓",
          thumbnail: "https://corsproxy.io/?" + encodeURIComponent(p.conSocketThumbnail),
          // thumbnail: "http://cors-anywhere.herokuapp.com/" + p.conSocketThumbnail,
          index: index,
        };
        photos.push(item);
        index += 1;
      }

      if (p.endSocketCount > 0) {
        const item = {
          name: "엔드 소켓 (마구리)",
          thumbnail: "https://corsproxy.io/?" + encodeURIComponent(p.endSocketThumbnail),
          // thumbnail: "http://cors-anywhere.herokuapp.com/" + p.endSocketThumbnail,
          index: index,
        };
        photos.push(item);
        index += 1;
      }

      if (p.boltSetCount > 0 && p.boltSetAdded) {
        const item = {
          name: "볼트세트",
          // thumbnail: "http://cors-anywhere.herokuapp.com/" + p.boltSetThumbnail,
          thumbnail: "https://corsproxy.io/?" + encodeURIComponent(p.boltSetThumbnail),
          index: index,
        };
        photos.push(item);
        index += 1;
      }
      if (p.safeCoverCount > 0 && p.safeCoverAdded) {
        const item = {
          name: "안전 커버",
          // thumbnail: "http://cors-anywhere.herokuapp.com/" + p.boltSetThumbnail,
          thumbnail: "https://corsproxy.io/?" + encodeURIComponent(p.safeCoverThumbnail),
          index: index,
        };
        photos.push(item);
        index += 1;
      }

      if (p.tSocketCount > 0) {
        const item = {
          name: "T자 가공",
          thumbnail: "https://corsproxy.io/?" + encodeURIComponent(p.tThumbnail),
          // thumbnail: "http://cors-anywhere.herokuapp.com/" + p.tThumbnail,
          index: index,
        };
        photos.push(item);
        index += 1;
      }

      if (p.lSocketCount > 0) {
        const item = {
          name: "L자 가공",
          thumbnail: "https://corsproxy.io/?" + encodeURIComponent(p.lThumbnail),
          // thumbnail: "http://cors-anywhere.herokuapp.com/" + p.lThumbnail,
          index: index,
        };
        photos.push(item);
        index += 1;
      }

      if (p.crossSocketCount > 0) {
        const item = {
          name: "크로스 가공",
          thumbnail: "https://corsproxy.io/?" + encodeURIComponent(p.crossThumbnail),
          index: index,
        };
        photos.push(item);
        index += 1;
      }

      if (p.pipeStraightCount > 0) {
        const filteredArray = p.sidePipeOptions.items.filter((item) => item.count !== 0);
        for (let i = 0; i < filteredArray.length; i++) {
          const option = filteredArray[i];
          const size = option.size;

          const item = {
            name: "파이프 가공 (측면)",
            thumbnail: "../../sidePipe.png",
            index: index,
            standard: size,
          };
          photos.push(item);
          index += 1;
        }
      }
      if (p.pipeDownCount > 0) {
        const filteredArray = p.downPipeOptions.items.filter((item) => item.count !== 0);
        for (let i = 0; i < filteredArray.length; i++) {
          const option = filteredArray[i];
          const size = option.size;
          const location = option.location;

          const item = {
            name: "파이프 가공 (하부)",
            thumbnail: location === "중앙" ? "../../pipe_center.png" : "../../bottomPipe.png",
            index: index,
            standard: `${size} ${location}`,
          };
          photos.push(item);
          index += 1;
        }
      }

      if (p.twoInOneSideCapCount > 0) {
        const item = {
          name: "2in1 사이드 캡",
          thumbnail: "https://corsproxy.io/?" + encodeURIComponent(p.straightPipeThumbnail),
          index: index,
        };
        photos.push(item);
        index += 1;
      }

      if (p.twoInOneBottomCapCount > 0) {
        const item = {
          name: "2in1 하부 캡",
          thumbnail: "https://corsproxy.io/?" + encodeURIComponent(p.downPipeThumbnail),
          index: index,
        };
        photos.push(item);
        index += 1;
      }
    });

    return photos;
  }

  function getPdfId() {
    const now = new Date();
    const year = now.getFullYear() % 100; // 년도의 뒤 두 자리
    const month = now.getMonth() + 1; // 월 (0부터 시작하므로 1을 더함)
    const day = now.getDate(); // 일
    const hours = now.getHours(); // 시간
    const minutes = now.getMinutes(); // 분
    const seconds = now.getSeconds(); // 초
    const milliseconds = now.getMilliseconds(); // 밀리초

    // 각 값이 10 미만일 경우 앞에 0을 추가합니다.
    const formattedYear = year < 10 ? `0${year}` : year;
    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedHours = hours < 10 ? `0${hours}` : hours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
    const formattedMilliseconds =
      milliseconds < 10
        ? `00${milliseconds}`
        : milliseconds < 100
        ? `0${milliseconds}`
        : milliseconds;

    // 아이디 값을 문자열로 조합하여 반환합니다.
    const id = `${formattedYear}${formattedMonth}${formattedDay}${formattedHours}${formattedMinutes}_${formattedSeconds}${formattedMilliseconds}`;
    return id;
  }

  function getIssuedAt() {
    const now = new Date();
    const year = now.getFullYear() % 100; // 년도의 뒤 두 자리
    const month = now.getMonth() + 1; // 월 (0부터 시작하므로 1을 더함)
    const day = now.getDate(); // 일

    // 각 값이 10 미만일 경우 앞에 0을 추가합니다.
    const formattedYear = year < 10 ? `0${year}` : year;
    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedDay = day < 10 ? `0${day}` : day;

    // 'yy. MM. dd' 형식으로 날짜를 조합하여 반환합니다.
    return `${formattedYear}. ${formattedMonth}. ${formattedDay}`;
  }

  function getExpiredAt() {
    const now = new Date();
    const sevenDaysLater = new Date(now);
    sevenDaysLater.setDate(now.getDate() + 7);

    const year = sevenDaysLater.getFullYear() % 100; // 년도의 뒤 두 자리
    const month = sevenDaysLater.getMonth() + 1; // 월 (0부터 시작하므로 1을 더함)
    const day = sevenDaysLater.getDate(); // 일

    // 각 값이 10 미만일 경우 앞에 0을 추가합니다.
    const formattedYear = year < 10 ? `0${year}` : year;
    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedDay = day < 10 ? `0${day}` : day;

    // 'yy. MM. dd' 형식으로 날짜를 조합하여 반환합니다.
    return `${formattedYear}. ${formattedMonth}. ${formattedDay}`;
  }

  return (
    <PdfContext.Provider
      value={{
        pdfData,
        dialogBodies,
        loading,
        dialogOpen,
        setDialogOpen,
        setLoading,
        setNote,
        setRoadJibun,
        setClientAddress,
        setClientName,
        setDetailAddress,
        setReceiverName,
        setReceiverPhone,
        setClientPhone,
        setDepositorName,
        setDeliveryWay,
        setFileName,
        setPaymentDate,
        setBizCertificateBase64,
        setClientEmail,
        setClientIp,
        clearClientEmailPhoneNameAddress,
        makeConstructionEstimatesToPdfData,
        makeEstimatesToPdfData,
        makeAllEstimatesToPdfData,
        estimatesToItems,
        generateDialogBodies,
        generateConstructionDialogBody,
        generateAllDialogBody,
      }}
    >
      {children}
    </PdfContext.Provider>
  );
}
