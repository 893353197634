import React, { useContext } from "react";
import { EstimateContext } from "../context/EstimatesContext";
import Utils from "../Utils";
import PlusButton from "./ui/PlusButton";
import MinusButton from "./ui/MinusButton";
import CountInput from "./ui/CountInput";
import ToastHelper from "../helpers/ToastHelper";

export default function ProductCountAndPrice({ estimate }) {
  const { setEstimateProductCount, handleIncreaseProductCount, handleDecreaseProductCount } =
    useContext(EstimateContext); // 사용할 context 정의

  const blurHandler = (e) => {
    const value = e.target.value;
    if (value === "" || value === "0") {
      if (estimate.minimumOrder !== 0) {
        setEstimateProductCount(estimate.id, estimate.minimumOrder);
        ToastHelper.showMinimumOrderToast(estimate.minimumOrder);
      } else {
        setEstimateProductCount(estimate.id, 1);
      }
    }

    if (estimate.minimumOrder > parseInt(value)) {
      setEstimateProductCount(estimate.id, estimate.minimumOrder);
      ToastHelper.showMinimumOrderToast(estimate.minimumOrder);
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;

    // 오직 숫자만
    if (/^[0-9]*$/.test(value) && value !== "") {
      const count = parseInt(value);

      //* 두번 호출하는 이유는 한번만 하면 제일 처음에 연결소켓이 추가 안되는 버그가 있음.
      setEstimateProductCount(estimate.id, count);
      setEstimateProductCount(estimate.id, count);
    }
    if (value === "") {
      setEstimateProductCount(estimate.id, "");
    }
  };

  const handleIncrease = () => {
    handleIncreaseProductCount(estimate.id);
  };

  const handleDecrease = () => {
    handleDecreaseProductCount(estimate.id);
  };

  return (
    <div className="flex px-0 vsp:px-4 ">
      <span className="w-0 h-0 sp:w-20 sp:h-18 se:w-24 md:w-28 " />
      <div className="flex-1 flex justify-between items-center">
        {/* Counting  */}
        <div className="flex items-center">
          <MinusButton onClick={handleDecrease} />
          <CountInput onBlur={blurHandler} value={estimate.productCount} onChange={handleChange} />
          <PlusButton onClick={handleIncrease} />
        </div>

        {/* price and meter  */}
        <div className="flex flex-col justify-center text-right">
          <p className="">{estimate.productCount * estimate.displayMeter}m</p>
          <p className="font-bold">
            {Utils.addComma(estimate.productCount * estimate.displayMeter * estimate.normalPrice)}원
          </p>
        </div>
      </div>
    </div>
  );
}
