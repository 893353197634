import React, { useContext } from "react";
import { EstimateContext } from "../context/EstimatesContext";
import Utils from "../Utils";
import DialogHelper from "../helpers/DialogHelper";
import PlusButton from "./ui/PlusButton";
import MinusButton from "./ui/MinusButton";
import PartsThumbnail from "./ui/PartsThumbnail";
import AutoItemTitle from "./ui/AutoItemTitle";
import CountInput from "./ui/CountInput";

export default function ConnectSocketCountAndPrice({ estimate }) {
  const {
    setConnectSocketCount,
    handleIncreaseConnectSocketCount,
    handleDecreaseConnectSocketCount,
    conSocketDisableAutoIncrease,
  } = useContext(EstimateContext);

  const blurHandler = (e) => {
    const value = e.target.value;
    if (value === "" || value === "0") {
      setConnectSocketCount(estimate.id, 0);
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;

    // 오직 숫자만
    if (/^[0-9]*$/.test(value) && value !== "") {
      const count = parseInt(value);
      setConnectSocketCount(estimate.id, count);
      return;
    }

    // 숫자가 0이면 -> 1로 변경
    if (value === "0") {
      setConnectSocketCount(estimate.id, 0);
      return;
    }
    if (value === "") {
      setConnectSocketCount(estimate.id, "");
    }
  };

  const handleIncrease = async () => {
    if (estimate.conSocketAutomaticIncrease) {
      const result = await DialogHelper.showConSocketAutoDialog();
      if (result.isConfirmed) {
        // handleIncreaseConnectSocketCount(estimate.id);
        conSocketDisableAutoIncrease(estimate.id);
      }
    } else {
      handleIncreaseConnectSocketCount(estimate.id);
    }
  };

  const handleDecrease = async () => {
    if (estimate.conSocketAutomaticIncrease) {
      const result = await DialogHelper.showConSocketAutoDialog();

      if (result.isConfirmed) {
        // handleDecreaseConnectSocketCount(estimate.id);
        conSocketDisableAutoIncrease(estimate.id);
      }
    } else {
      handleDecreaseConnectSocketCount(estimate.id);
    }
  };

  return (
    <div className="flex">
      <PartsThumbnail url={estimate.conSocketThumbnail} />

      <div className="flex-1 flex justify-between items-center px-0 vsp:px-4 py-2">
        {/* button hanler  */}
        <div className="">
          <AutoItemTitle title={"연결 소켓"} autoSetCount={estimate.conSocketAutomaticIncrease} />

          <div className="flex items-center">
            <MinusButton onClick={handleDecrease} />

            <CountInput
              disabled={estimate.conSocketAutomaticIncrease}
              value={estimate.connectSocketCount}
              onBlur={blurHandler}
              onChange={handleChange}
            />

            <PlusButton onClick={handleIncrease} />
          </div>
        </div>

        {/* price and meter  */}
        <div className="flex flex-col justify-center text-right">
          <p className="">{estimate.connectSocketCount}개</p>
          <p className="font-bold">
            {Utils.addComma(estimate.connectSocketCount * estimate.conSocketPrice)}원
          </p>
        </div>
      </div>
    </div>
  );
}
