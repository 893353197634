import React from "react";

export default function PartsThumbnail({ url }) {
  return url === "" ? (
    <span className="w-0 h-0 sp:w-24 sp:h-20" />
  ) : (
    <img
      src={url}
      alt="🔴 URL 잘못됨 🔴"
      className="w-0 h-0 sp:w-20 sp:h-20 se:w-24 bg-cover rounded-md "
    />
  );
}
