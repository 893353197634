import React, { useContext, useState } from "react";

import { EstimateContext } from "../context/EstimatesContext";
import LocationDropdown from "../components/construction/LocationDropdown";
import SidoDropdown from "../components/construction/SidoDropdown";
import TotalConstruction from "../components/construction/TotalConstruction";
import { useEffect } from "react";
import AutoConstructionInfo from "../components/construction/AutoConstructionInfo";
import ForkliftCostBurden from "../components/construction/ForkliftCostBurden";
import Forklift from "../components/construction/Forklift";
import HeightWithoutIron from "../components/construction/HeightWithoutIron";
import TotalProducts from "../components/TotalProducts";
import ConstructionWays from "../components/construction/ConstructionWays";
import { ConstructionContext } from "../context/ConstructionContext";
import Boyang from "../components/construction/Boyang";
import Level from "../components/construction/Level";
import Slope from "../components/construction/Slope";
import PdfButton from "../components/PdfButton";
import NewOrRemodeling from "../components/NewOrRemodeling";
import SlideDialog from "../components/SlideDialog";
import { PdfContext } from "../context/PdfContext";

export default function AutoConstructionPage() {
  //, isDisabled
  const { cTotal, getTotalConstructionCost, constEstimate, isDisabled, setConstructionWay } =
    useContext(ConstructionContext);
  const { estimates } = useContext(EstimateContext);
  const { dialogOpen } = useContext(PdfContext);
  const [check, setCheck] = useState(false);

  useEffect(() => {
    getTotalConstructionCost();

    if (check) return;
    for (const index in estimates) {
      if (estimates[index].boltSetAdded) {
        setConstructionWay("dry");
        setCheck(true);
        return;
      }
    }
  }, [getTotalConstructionCost, estimates, check, setConstructionWay]);

  return (
    <>
      <>{dialogOpen && <SlideDialog />}</>
      <div className="max-w-screen-xl mx-auto bg-white border rounded-lg mt-20 p-2 sm:p-8 mb-5">
        {cTotal === 1300000 && (
          <div className="ml-2 mt-2 mb-5 flex flex-col">
            <p className="font-bold text-lg text-red-500">
              시공할 제품의 m수가 너무 적어 기본 시공비 130만원으로 설정 되었습니다.
            </p>
            <div className="h-0.5 w-full mt-2 bg-gray-100" />
          </div>
        )}
        <div className="m-2 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <SidoDropdown />
          <LocationDropdown />
          <NewOrRemodeling />
          <Boyang />
          <ConstructionWays />
          <Level />
          <Slope />
          {constEstimate.constructionWay === "dry" && <HeightWithoutIron />}
          <Forklift />
          {constEstimate.useForklift && <ForkliftCostBurden />}
        </div>
        <span className="mt-2 text-transparent">.</span>
        <div className="flex flex-col md:flex-row justify-center gap-0 md:gap-5">
          {cTotal > 1300000 && <PdfButton purpose={"construction"} disabled={isDisabled()} />}
          {cTotal > 1300000 && <PdfButton purpose={"all"} disabled={isDisabled()} />}
        </div>

        {estimates.length !== 0 && <TotalProducts isConstructionPage={true} />}
        {estimates.length !== 0 && <TotalConstruction />}
      </div>
      <AutoConstructionInfo />
    </>
  );
}
