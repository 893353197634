import { Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';

PDFText.propTypes = {
  text: PropTypes.string,
  fontSize: PropTypes.string,
  fontFamily: PropTypes.oneOf([ 'Regular' ,'Bold']),
  
  textAlign: PropTypes.oneOf([ 'center', 'justify' | 'right' | 'left']),
  pt: PropTypes.oneOfType([ PropTypes.string,  PropTypes.number]),
  pl: PropTypes.oneOfType([ PropTypes.string,  PropTypes.number]),
  pr: PropTypes.oneOfType([ PropTypes.string,  PropTypes.number]),
  pb: PropTypes.oneOfType([ PropTypes.string,  PropTypes.number]),
  mt: PropTypes.oneOfType([ PropTypes.string,  PropTypes.number]),
  mb: PropTypes.oneOfType([ PropTypes.string,  PropTypes.number]),
  ml: PropTypes.oneOfType([ PropTypes.string,  PropTypes.number]),
  mr: PropTypes.oneOfType([ PropTypes.string,  PropTypes.number]),
  mv: PropTypes.oneOfType([ PropTypes.string,  PropTypes.number]),
  mh: PropTypes.oneOfType([ PropTypes.string,  PropTypes.number]),
  pv: PropTypes.oneOfType([ PropTypes.string,  PropTypes.number]),
  ph: PropTypes.oneOfType([ PropTypes.string,  PropTypes.number]),
  color:  PropTypes.string,
}

export function PDFText ({
  text,
  fontSize = '12px',
  fontFamily = undefined,
  textAlign = 'left',
  pt,
  pl,
  pb,
  pr,
  ml,
  mr,
  mt,
  mb,
  mv,
  mh,
  pv,
  ph,
  color = '#000',
})  {
  return (
    <View>
      <Text
        style={{
          fontSize: fontSize,
          fontFamily: fontFamily,
          textAlign: textAlign,
          paddingTop: pt,
          paddingBottom: pb,
          paddingLeft: pl,
          paddingRight: pr,
          marginTop: mt,
          marginBottom: mb,
          marginLeft: ml,
          marginRight: mr,
          color: color,
          marginHorizontal: mh,
          marginVertical: mv,
          paddingHorizontal: ph,
          paddingVertical: pv,
        }}
      >
        {text}
      </Text>
    </View>
  );
};
