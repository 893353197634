import React, { useContext } from "react";
import PlusButton from "./ui/PlusButton";
import MinusButton from "./ui/MinusButton";
import { EstimateContext } from "../context/EstimatesContext";
import ToastHelper from "../helpers/ToastHelper";

export default function PipeOptionItem({ type, estimate, pipeSize, pipeLocation, remainCount }) {
  const { handleDownPipePlus, handleDownPipeMinus, handleSidePipePlus, handleSidePipeMinus } =
    useContext(EstimateContext);

  const handlePlus = () => {
    if (remainCount === 0) {
      ToastHelper.showReachedMaximumSize();
    } else {
      type === "bottom"
        ? handleDownPipePlus(estimate.id, pipeSize, pipeLocation)
        : handleSidePipePlus(estimate.id, pipeSize);
    }
  };

  const handleMinus = () => {
    const count = getItemCount();
    if (count > 0) {
      type === "bottom"
        ? handleDownPipeMinus(estimate.id, pipeSize, pipeLocation)
        : handleSidePipeMinus(estimate.id, pipeSize);
    }
  };

  const getItemCount = () => {
    const count =
      type === "bottom"
        ? estimate.downPipeOptions.items.find(
            (m) => m.size === pipeSize && m.location === pipeLocation
          ).count
        : estimate.sidePipeOptions.items.find((m) => m.size === pipeSize).count;
    return count;
  };

  return (
    <div className="mx-1 sm:mx-10 flex justify-between h-20 border border-gray-300 px-2 sm:px-6 p-2 mb-2 hover:bg-gray-100">
      <div className="flex flex-col justify-center">
        <div className="font-bold text-lg">{pipeSize}</div>
        {pipeLocation && (
          <div>
            <p>{pipeLocation}</p>
          </div>
        )}
      </div>
      <div className="flex items-center gap-2">
        <MinusButton onClick={handleMinus} />

        <p className="mx-2 text-bold text-lg">
          {type === "bottom"
            ? estimate.downPipeOptions.items.find(
                (m) => m.size === pipeSize && m.location === pipeLocation
              ).count
            : estimate.sidePipeOptions.items.find((m) => m.size === pipeSize).count}
        </p>
        <PlusButton onClick={handlePlus} />
      </div>
    </div>
  );
}
