import React, { useContext } from "react";
import { ImCancelCircle } from "react-icons/im";
import { EstimateContext } from "../context/EstimatesContext";
import DialogHelper from "../helpers/DialogHelper";
import Utils from "../Utils";
import ToastHelper from "../helpers/ToastHelper";

export default function ProductInfo({ estimate }) {
  const { handleDelete } = useContext(EstimateContext); // 사용할 context 정의

  const _handleDelete = async () => {
    const result = await DialogHelper.showDeleteDialog();
    if (result.isConfirmed) {
      handleDelete(estimate.id);
      ToastHelper.showDeletedToast();
    }
  };

  return (
    <div className="flex items-center mb-2">
      <img
        onClick={() => {
          window.open(
            `https://koreatrench.com/bbs/board.php?bo_table=ez_trench&wr_id=${estimate.webId}`,
            "_blank"
          );
        }}
        src={estimate.thumbnail}
        className="w-24 h-24 md:w-28 md:h-28 bg-white rounded-md border cursor-pointer"
        alt=""
      />
      <div className=" flex-auto p-4">
        <div className="flex justify-between items-center">
          <p className="text-lg font-semibold">{estimate.name}</p>
          <button onClick={_handleDelete} className="">
            <ImCancelCircle className="w-6 h-6 text-gray-700" />
          </button>
        </div>

        <p className="text-md text-gray-400 ">{estimate.standard}</p>
        <div>
          <span className="text-sm">
            {estimate.displayMeter}m ({estimate.unit})
          </span>
          <span className="text-gray-400">| </span>
          <span className="text-sm">1m 단가 </span>
          <span className="text-sm text-red-500 font-semibold">
            {Utils.addComma(estimate.normalPrice)}원
          </span>
        </div>
      </div>
    </div>
  );
}
