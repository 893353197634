import React, { useContext, useEffect, useState } from "react";
import { ConstructionContext } from "../context/ConstructionContext";
import { EstimateContext } from "../context/EstimatesContext";
import DialogHelper from "../helpers/DialogHelper";

export default function NewOrRemodeling() {
  const { constEstimate, setProjectType } = useContext(ConstructionContext);
  const { hasOnlyRemodelingProducts } = useContext(EstimateContext);
  const [selectedOption, setSelectedOption] = useState("new");

  const handleChange = (e) => {
    const value = e.target.value;

    if (value === "new") {
      setProjectType("new");
    } else {
      if (hasOnlyRemodelingProducts() === false) {
        DialogHelper.showDialog({
          icon: "info",
          title: "견적 문의",
          msg: `해당 제품은 리모델링 시공시 상세 견적이 필요합니다.<br/>032-561-0303으로 문의 주세요`,
        });
        return;
      }
      setProjectType("remodeling");
    }
    setSelectedOption(value);
  };

  useEffect(() => {
    if (constEstimate.projectType === "") {
      return;
    }
    if (constEstimate.projectType === "new") {
      setSelectedOption("new");
    } else {
      setSelectedOption("remodling");
    }
  }, [constEstimate.projectType]);

  return (
    <fieldset className="field-set-style">
      <legend className="label-style">공사 유형</legend>

      <div className="mt-4 space-y-6 border p-5 rounded">
        <div className="flex items-center gap-x-3">
          <input
            id="new"
            name="new"
            type="radio"
            value="new"
            checked={selectedOption === "new"}
            className={`h-4 w-4 border-gray-300 text-red-600 focus:ring-red-600 ${
              selectedOption === "new" ? "accent-red-500" : ""
            }`}
            onChange={handleChange}
          />
          <label htmlFor="new" className="label-style">
            신축 공사
          </label>
        </div>
        <div className="flex items-center gap-x-3">
          <input
            id="remodling"
            name="remodling"
            type="radio"
            value="remodling"
            checked={selectedOption === "remodling"}
            className={`h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600 ${
              selectedOption === "remodling" ? "accent-red-500" : ""
            }`}
            onChange={handleChange}
          />
          <label htmlFor="remodling" className="label-style">
            리모델링 공사
          </label>
        </div>
      </div>
    </fieldset>
  );
}
