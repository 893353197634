import { createContext, useEffect, useState, useCallback, useContext } from "react";
import { useImmer } from "use-immer";
import DialogHelper from "../helpers/DialogHelper";
import { ProductsContext } from "./ProductsContext";

// context 생성 (여기에 데이터를 보관)
export const EstimateContext = createContext();

// provider도 컴포넌트로 Wrapper 같은 거 같음.
// wrapper안에 children들은 모두 Context 데이터에 접근이 가능.✅
// 그 데이터 = 밑에 value이다.

export function EstimatesProvider({ children }) {
  const { getConstructibleSmallCategoriesWithoutMuldorang } = useContext(ProductsContext);
  const [estimates, updateEstimates] = useImmer([]);
  const [canConstruct, setCanConstruct] = useState(false);
  const [sawInfoDialog, setSawInfoDialog] = useState(true);
  const [activeDialogIndex, setActiveDialogIndex] = useState(0);

  const canBeginConstruction = useCallback(() => {
    let result = false;
    estimates.forEach((e) => {
      if (e.dryConstructionCost > 0) {
        result = true;
        return;
      }
    });
    setCanConstruct(result);
  }, [estimates, setCanConstruct]);

  useEffect(() => {
    canBeginConstruction();
  }, [estimates, canBeginConstruction]);

  // 추가
  const handleAdd = (newEstimate) => {
    const found = estimates.find((est) => est.id === newEstimate.id);
    if (found) {
      DialogHelper.showInfoText("이미 제품이 추가되었습니다");
      return false;
    }

    updateEstimates((estimates) => {
      estimates.push(newEstimate);
      return estimates;
    });

    return true;
  };

  const handleDelete = (id) => {
    updateEstimates((estimates) => {
      const index = estimates.findIndex((e) => e.id === id);
      estimates.splice(index, 1);
    });
  };

  const getPossibleMeter = () => {
    let meters = 0;
    estimates.forEach((e) => {
      if (e.dryConstructionCost > 0) {
        meters += e.productCount * e.meter;
      }
    });
    return meters;
  };

  const getTotalPartsConstructionCost = (way) => {
    let totalLCost = 0;
    let totalTCost = 0;
    let totalCrossCost = 0;
    let productsMeterCost = 0;

    estimates.forEach((e) => {
      if (e.dryConstructionCost > 0) {
        productsMeterCost +=
          e.productCount *
          e.meter *
          (way === "dry" ? e.dryConstructionCost : e.wetConstructionCost);
        if (e.lSocketCount > 0) {
          totalLCost += e.lSocketCount * 10000;
        }
        if (e.tSocketCount > 0) {
          totalTCost += e.tSocketCount * 20000;
        }
        if (e.crossSocketCount > 0) {
          totalCrossCost += e.crossSocketCount * 30000;
        }
      }
    });
    return totalLCost + totalTCost + totalCrossCost + productsMeterCost;
  };

  const getBagsPrice = () => {
    let price = 0;
    estimates.forEach((e) => {
      if (e.smallCategory === "물도랑") {
        const bags = getNumberOfBags(e);
        price += bags * 20000;
      }
    });
    return price;
  };

  const getBagsCount = () => {
    let count = 0;
    estimates.forEach((e) => {
      if (e.smallCategory === "물도랑") {
        count += getNumberOfBags(e);
      }
    });
    return count;
  };

  const getNumberOfBags = (product) => {
    let bags = 2; // default

    if (product.standarad === "200*70*3000(2t)" || product.standarad === "200*70*3000(3t)") {
      bags = 2.5;
    } else if (product.standarad === "200*80*3000(3t)") {
      bags = 3;
    } else if (product.standarad === "200*100*3000(3t)") {
      bags = 4;
    } else if (product.standarad === "200*130(25)*3000(3.5t)") {
      bags = 4.5;
    } else if (product.standarad === "200*130(32)*3000(3.5t)") {
      bags = 4.5;
    } else if (product.standarad === "200*200*3000(5t)") {
      bags = 5;
    } else if (product.standarad === "300*70*3000(5t)") {
      bags = 3;
    } else if (product.standarad === "300*130*3000(5t)") {
      bags = 4;
    } else if (product.standarad === "300*250*3000(5t)") {
      bags = 5;
    }

    bags = bags * (product.meter * product.productCount);

    return bags;
  };

  const getRemodelingTotalPartsConstructionCost = () => {
    let totalLCost = 0;
    let totalTCost = 0;
    let totalCrossCost = 0;
    let productsMeterCost = 0;
    estimates.forEach((e) => {
      if (e.dryConstructionCost > 0) {
        productsMeterCost += e.productCount * e.meter * 200000; // 리모델링 기본 가격이 20만원
        if (e.lSocketCount > 0) {
          totalLCost += e.lSocketCount * 10000; // L소켓 시공 기본 가격 1만원
        }
        if (e.tSocketCount > 0) {
          totalTCost += e.tSocketCount * 20000; // L소켓 시공 기본 가격 2만원
        }
        if (e.crossSocketCount > 0) {
          totalCrossCost += e.crossSocketCount * 30000; // L소켓 시공 기본 가격 3만원
        }
      }
    });
    return totalLCost + totalTCost + totalCrossCost + productsMeterCost;
  };

  // 리모델링 가능한 제품 여부를 확인하는 함수 (물도랑만 있는지)
  const hasOnlyRemodelingProducts = () => {
    // 물도랑을 제외한 , 공사 가능한 제품들의 소제목 모음     예시 : [하나로,라인트렌치,자갈트렌치,결로트렌치 ,석재(판석)트렌치 ... ]
    const smallCategories = getConstructibleSmallCategoriesWithoutMuldorang();

    // estimates의 smallCategories   예시 : ['물도랑','라인트렌치','거북이']
    const estimatesCategories = Array.from(new Set(estimates.map((obj) => obj.smallCategory)));

    // estimatesCategories에 시공 가능한 smallCategories를 포함하는지 아닌지를 알아낸다.
    // 포함 되어 있다면 true , 포함 안되어 있다면 false
    const isConstructibleSmallCategoryIncludedArray = estimatesCategories.map((smallCategory) =>
      smallCategories.includes(smallCategory)
    );
    const isConstructibleSmallCategoryIncluded =
      isConstructibleSmallCategoryIncludedArray.includes(true);

    // 물도랑이 포함되어 있는지 유무
    const isMuldorangIncluded = estimatesCategories.includes("물도랑");

    // return 다른 시공 가능 제품들은 포함하지 않았다. && 물도랑이 포함되어 있다.
    return !isConstructibleSmallCategoryIncluded && isMuldorangIncluded;
    //
  };

  // ✅
  const setEstimateProductCount = (id, count) => {
    updateEstimates((estimates) => {
      const est = estimates.find((m) => m.id === id);

      // if (
      //   est.conSocketAutomaticIncrease &&
      //   est.connectSocketCount !== -1 &&
      //   count !== 0 &&
      //   count !== ""
      // ) {
      //   est.connectSocketCount = count - 1;
      // }
      // if (est.boltSetAutomaticIncrease && est.boltSetCount !== -1) {
      //   est.boltSetCount = count * 6;
      // }

      if (count !== "") {
        calcAutoParts(id);
      }

      est.productCount = count;
    });
  };

  const resetEstimatesPipeOptions = () => {
    updateEstimates((estimates) => {
      estimates.forEach((est) => {
        est.sidePipeOptions = { total: 0, items: [] };
        est.downPipeOptions = { total: 0, items: [] };
      });
    });
  };

  // ✅
  const handleIncreaseProductCount = (id) => {
    updateEstimates((estimates) => {
      const est = estimates.find((m) => m.id === id);

      if (est.conSocketAutomaticIncrease && est.connectSocketCount !== -1) {
        est.connectSocketCount = est.connectSocketCount + 1;
      }

      if (est.boltSetAutomaticIncrease && est.boltSetCount !== -1) {
        est.boltSetCount = est.boltSetCount + 6;
      }

      // TODO : packageCount가 0이 아니면 롤일 경우 예외 처리
      est.productCount = est.productCount + 1;
    });
  };

  // ✅
  const handleDecreaseProductCount = (id) => {
    updateEstimates((estimates) => {
      const est = estimates.find((m) => m.id === id);
      const mini = est.minimumOrder;
      const count = est.productCount;
      if (mini !== 0 && count <= mini) {
        DialogHelper.showInfoText(
          `최소 주문 수량이 있는 제품입니다. ${mini} 이하로는 설정하실 수 없습니다`
        );
        return;
      }

      if (count <= 1) {
        DialogHelper.showInfoText("1 이하로는 설정하실 수 없습니다");
        return;
      }

      if (est.conSocketAutomaticIncrease && est.connectSocketCount !== -1) {
        est.connectSocketCount = est.connectSocketCount - 1;
      }
      if (est.boltSetAutomaticIncrease && est.boltSetCount !== -1) {
        est.boltSetCount = est.boltSetCount - 6;
      }
      est.productCount = est.productCount - 1;
    });
  };

  //! Pipe Options ( 하부, 측면 가공 파이프 핸들링 )
  // down pipes
  const handleDownPipePlus = (id, size, location) => {
    const est = estimates.find((m) => m.id === id);

    const itemExist = doesPipeItemExist(est.downPipeOptions.items, size, location);
    if (itemExist) {
      updateEstimates((estimates) => {
        const estimate = estimates.find((m) => m.id === id);
        const item = estimate.downPipeOptions.items.find(
          (item) => item.size === size && item.location === location
        );
        if (item) {
          item.count += 1;
          estimate.downPipeOptions.total += 1;
        }
      });
    } else {
      updateEstimates((estimates) => {
        const estimate = estimates.find((m) => m.id === id);
        estimate.downPipeOptions.total = estimate.downPipeOptions.total + 1;
        estimate.downPipeOptions.items.push({ size, location, count: 1 });
      });
    }
  };

  const handleDownPipeMinus = (id, size, location) => {
    updateEstimates((estimates) => {
      const estimate = estimates.find((m) => m.id === id);
      estimate.downPipeOptions.total = estimate.downPipeOptions.total - 1;
      const item = estimate.downPipeOptions.items.find(
        (item) => item.size === size && item.location === location
      );
      if (item) {
        item.count -= 1;
      }
    });
  };

  const handleDownPipeAdd = (id, size, location) => {
    const est = estimates.find((m) => m.id === id);

    const itemExist = doesPipeItemExist(est.downPipeOptions.items, size, location);

    if (!itemExist) {
      updateEstimates((estimates) => {
        const estimate = estimates.find((m) => m.id === id);
        estimate.downPipeOptions.items.push({ size, location, count: 0 });
      });
    }
  };

  // side pipes
  const handleSidePipePlus = (id, size) => {
    const est = estimates.find((m) => m.id === id);

    const itemExist = doesPipeItemExist(est.sidePipeOptions.items, size);
    if (itemExist) {
      updateEstimates((estimates) => {
        const estimate = estimates.find((m) => m.id === id);
        const item = estimate.sidePipeOptions.items.find((item) => item.size === size);
        if (item) {
          item.count += 1;
          estimate.sidePipeOptions.total += 1;
        }
      });
    } else {
      updateEstimates((estimates) => {
        const estimate = estimates.find((m) => m.id === id);
        estimate.sidePipeOptions.total = estimate.sidePipeOptions.total + 1;
        estimate.sidePipeOptions.items.push({ size, count: 1 });
      });
    }
  };

  const handleSidePipeMinus = (id, size) => {
    updateEstimates((estimates) => {
      const estimate = estimates.find((m) => m.id === id);
      estimate.sidePipeOptions.total = estimate.sidePipeOptions.total - 1;
      const item = estimate.sidePipeOptions.items.find((item) => item.size === size);
      if (item) {
        item.count -= 1;
      }
    });
  };

  const handleSidePipeAdd = (id, size) => {
    const est = estimates.find((m) => m.id === id);
    const itemExist = doesPipeItemExist(est.sidePipeOptions.items, size);
    if (!itemExist) {
      updateEstimates((estimates) => {
        const estimate = estimates.find((m) => m.id === id);

        estimate.sidePipeOptions.items.push({ size, count: 0 });
      });
    }
  };

  //!  Connect Socket
  const setConnectSocketCount = (id, count) => {
    updateEstimates((estimates) => {
      const est = estimates.find((m) => m.id === id);
      est.connectSocketCount = count;
    });
  };

  const handleIncreaseConnectSocketCount = (id) => {
    updateEstimates((estimates) => {
      const est = estimates.find((m) => m.id === id);

      est.connectSocketCount = est.connectSocketCount + 1;
    });
  };

  const handleDecreaseConnectSocketCount = (id) => {
    updateEstimates((estimates) => {
      const est = estimates.find((m) => m.id === id);
      if (est.connectSocketCount <= 0) {
        DialogHelper.showInfoText("0 이하로는 설정하실 수 없습니다");
        return;
      }
      est.connectSocketCount = est.connectSocketCount - 1;
    });
  };

  const conSocketDisableAutoIncrease = (id) => {
    updateEstimates((estimates) => {
      const est = estimates.find((m) => m.id === id);
      est.conSocketAutomaticIncrease = false;
    });
  };

  //!  End Socket
  const setEndSocketCount = (id, count) => {
    updateEstimates((estimates) => {
      const est = estimates.find((m) => m.id === id);
      est.endSocketCount = count;
    });
  };

  const handleIncreaseEndSocketCount = (id) => {
    updateEstimates((estimates) => {
      const est = estimates.find((m) => m.id === id);

      est.endSocketCount = est.endSocketCount + 1;
    });
  };

  const handleDecreaseEndSocketCount = (id) => {
    updateEstimates((estimates) => {
      const est = estimates.find((m) => m.id === id);
      if (est.endSocketCount <= 0) {
        DialogHelper.showInfoText("0 이하로는 설정하실 수 없습니다");
        return;
      }
      est.endSocketCount = est.endSocketCount - 1;
    });
  };

  //! T자 가공
  const setTPartCount = (id, count) => {
    updateEstimates((estimates) => {
      const est = estimates.find((m) => m.id === id);
      est.tSocketCount = count;
    });
    calcAutoParts(id);
  };

  const handleIncreaseTPartCount = (id) => {
    updateEstimates((estimates) => {
      const est = estimates.find((m) => m.id === id);
      est.tSocketCount = est.tSocketCount + 1;
    });
    calcAutoParts(id);
  };

  const handleDecreaseTPartCount = (id) => {
    updateEstimates((estimates) => {
      const est = estimates.find((m) => m.id === id);
      if (est.tSocketCount <= 0) {
        DialogHelper.showInfoText("0 이하로는 설정하실 수 없습니다");
        return;
      }
      est.tSocketCount = est.tSocketCount - 1;
    });
    calcAutoParts(id);
  };

  //! L자 가공
  const setLPartCount = (id, count) => {
    updateEstimates((estimates) => {
      const est = estimates.find((m) => m.id === id);
      est.lSocketCount = count;
    });
    calcAutoParts(id);
  };

  const handleIncreaseLPartCount = (id) => {
    updateEstimates((estimates) => {
      const est = estimates.find((m) => m.id === id);

      est.lSocketCount = est.lSocketCount + 1;
    });
    calcAutoParts(id);
  };

  const handleDecreaseLPartCount = (id) => {
    updateEstimates((estimates) => {
      const est = estimates.find((m) => m.id === id);
      if (est.lSocketCount <= 0) {
        DialogHelper.showInfoText("0 이하로는 설정하실 수 없습니다");
        return;
      }
      est.lSocketCount = est.lSocketCount - 1;
    });
    calcAutoParts(id);
  };

  //! Cross 가공
  const setCrossPartCount = (id, count) => {
    updateEstimates((estimates) => {
      const est = estimates.find((m) => m.id === id);
      est.crossSocketCount = count;
    });
    calcAutoParts(id);
  };

  const handleIncreaseCrossPartCount = (id) => {
    updateEstimates((estimates) => {
      const est = estimates.find((m) => m.id === id);

      est.crossSocketCount = est.crossSocketCount + 1;
    });
    calcAutoParts(id);
  };

  const handleDecreaseCrossPartCount = (id) => {
    updateEstimates((estimates) => {
      const est = estimates.find((m) => m.id === id);
      if (est.crossSocketCount <= 0) {
        DialogHelper.showInfoText("0 이하로는 설정하실 수 없습니다");
        return;
      }
      est.crossSocketCount = est.crossSocketCount - 1;
    });
    calcAutoParts(id);
  };

  //! 하나로 안전 커버
  const setSafeCoverPartCount = (id, count) => {
    updateEstimates((estimates) => {
      const est = estimates.find((m) => m.id === id);
      est.safeCoverCount = count;
    });
  };

  const handleIncreaseSafeCoverPartCount = (id) => {
    updateEstimates((estimates) => {
      const est = estimates.find((m) => m.id === id);
      est.safeCoverCount = est.safeCoverCount + 1;
    });
  };

  const handleDecreaseSafeCoverPartCount = (id) => {
    updateEstimates((estimates) => {
      const est = estimates.find((m) => m.id === id);
      if (est.safeCoverCount <= 0) {
        DialogHelper.showInfoText("0 이하로는 설정하실 수 없습니다");
        return;
      }
      est.safeCoverCount = est.safeCoverCount - 1;
    });
  };

  //! 파이프 가공 (직선)
  const setStraightPipeCount = (id, count) => {
    updateEstimates((estimates) => {
      const est = estimates.find((m) => m.id === id);
      est.pipeStraightCount = count;
    });
  };

  const handleIncreaseStraightPipeCount = (id) => {
    updateEstimates((estimates) => {
      const est = estimates.find((m) => m.id === id);

      est.pipeStraightCount = est.pipeStraightCount + 1;
    });
  };

  const handleDecreaseStraightPipeCount = (id) => {
    updateEstimates((estimates) => {
      const est = estimates.find((m) => m.id === id);
      if (est.pipeStraightCount <= 0) {
        DialogHelper.showInfoText("0 이하로는 설정하실 수 없습니다");
        return;
      }
      est.pipeStraightCount = est.pipeStraightCount - 1;
    });
  };

  //! 파이프 가공 (하부)
  const setDownPipeCount = (id, count) => {
    updateEstimates((estimates) => {
      const est = estimates.find((m) => m.id === id);
      est.pipeDownCount = count;
    });
  };

  const handleIncreaseDownPipeCount = (id) => {
    updateEstimates((estimates) => {
      const est = estimates.find((m) => m.id === id);

      est.pipeDownCount = est.pipeDownCount + 1;
    });
  };

  const handleDecreaseDownPipeCount = (id) => {
    updateEstimates((estimates) => {
      const est = estimates.find((m) => m.id === id);
      if (est.pipeDownCount <= 0) {
        DialogHelper.showInfoText("0 이하로는 설정하실 수 없습니다");
        return;
      }
      est.pipeDownCount = est.pipeDownCount - 1;
    });
  };

  //! 2in1 측면 연결 캡
  const set2in1SideCapCount = (id, count) => {
    updateEstimates((estimates) => {
      const est = estimates.find((m) => m.id === id);
      est.twoInOneSideCapCount = count;
    });
  };

  const handleIncrease2in1SideCapCount = (id) => {
    updateEstimates((estimates) => {
      const est = estimates.find((m) => m.id === id);
      est.twoInOneSideCapCount = est.twoInOneSideCapCount + 1;
    });
  };

  const handleDecrease2in1SideCapCount = (id) => {
    updateEstimates((estimates) => {
      const est = estimates.find((m) => m.id === id);
      if (est.twoInOneSideCapCount <= 0) {
        DialogHelper.showInfoText("0 이하로는 설정하실 수 없습니다");
        return;
      }
      est.twoInOneSideCapCount = est.twoInOneSideCapCount - 1;
    });
  };

  //! 2in1 하부 연결 캡
  const set2in1BottomCapCount = (id, count) => {
    updateEstimates((estimates) => {
      const est = estimates.find((m) => m.id === id);
      est.twoInOneBottomCapCount = count;
    });
  };

  const handleIncrease2in1BottomCapCount = (id) => {
    updateEstimates((estimates) => {
      const est = estimates.find((m) => m.id === id);
      est.twoInOneBottomCapCount = est.twoInOneBottomCapCount + 1;
    });
  };

  const handleDecrease2in1BottomCapCount = (id) => {
    updateEstimates((estimates) => {
      const est = estimates.find((m) => m.id === id);
      if (est.twoInOneBottomCapCount <= 0) {
        DialogHelper.showInfoText("0 이하로는 설정하실 수 없습니다");
        return;
      }
      est.twoInOneBottomCapCount = est.twoInOneBottomCapCount - 1;
    });
  };

  //! 전산볼트 SET (자동)
  const setBoltSetCount = (id, count) => {
    updateEstimates((estimates) => {
      const est = estimates.find((m) => m.id === id);
      est.boltSetCount = count;
    });
  };

  const handleIncreaseBoltSetCount = (id) => {
    updateEstimates((estimates) => {
      const est = estimates.find((m) => m.id === id);

      est.boltSetCount = est.boltSetCount + 1;
    });
  };

  const handleDecreaseBoltSetCount = (id) => {
    updateEstimates((estimates) => {
      const est = estimates.find((m) => m.id === id);
      if (est.boltSetCount <= 0) {
        DialogHelper.showInfoText("0 이하로는 설정하실 수 없습니다");
        return;
      }
      est.boltSetCount = est.boltSetCount - 1;
    });
  };

  const boltSetDisableAutoIncrease = (id) => {
    updateEstimates((estimates) => {
      const est = estimates.find((m) => m.id === id);
      est.boltSetAutomaticIncrease = false;
    });
  };

  //! 부품 Toggle 함수들
  const setPartAdded = (id, value, partName) => {
    updateEstimates((estimates) => {
      const est = estimates.find((m) => m.id === id);
      if (partName === "boltSet") {
        est.boltSetAdded = value;
        // setConstructionWay("dry");
      } else if (partName === "tSocket") {
        est.tSocketAdded = value;
      } else if (partName === "lSocket") {
        est.lSocketAdded = value;
      } else if (partName === "crossSocket") {
        est.crossSocketAdded = value;
      } else if (partName === "straightPipe") {
        est.straightPipeAdded = value;
      } else if (partName === "downPipe") {
        est.downPipeAdded = value;
      } else if (partName === "twoInOneStraightPipe") {
        est.twoInOneStraightPipeAdded = value;
      } else if (partName === "twoInOneDownPipe") {
        est.twoInOneDownPipeAdded = value;
      } else if (partName === "safeCover") {
        est.safeCoverAdded = value;
      }
    });
  };

  const resetNoShowPartProps = (id) => {
    updateEstimates((estimates) => {
      const est = estimates.find((m) => m.id === id);

      if (!est.boltSetAdded && est.boltSetCount !== -1) {
        est.boltSetCount = 6;
        est.boltSetAutomaticIncrease = true;
      }
      if (!est.tSocketAdded && est.tSocketCount !== -1) {
        est.tSocketCount = 0;
      }
      if (!est.lSocketAdded && est.lSocketCount !== -1) {
        est.lSocketCount = 0;
      }
      if (!est.crossSocketAdded && est.crossSocketCount !== -1) {
        est.crossSocketCount = 0;
      }
      if (!est.straightPipeAdded && est.pipeStraightCount !== -1) {
        est.pipeStraightCount = 0;
      }
      if (!est.downPipeAdded && est.pipeDownCount !== -1) {
        est.pipeDownCount = 0;
      }
      if (!est.twoInOneStraightPipeAdded && est.twoInOneSideCapCount !== -1) {
        est.twoInOneSideCapCount = 0;
      }
      if (!est.twoInOneDownPipeAdded && est.twoInOneBottomCapCount !== -1) {
        est.twoInOneBottomCapCount = 0;
      }
      if (!est.safeCoverAdded && est.safeCoverCount !== -1) {
        est.safeCoverCount = 0;
      }
    });
  };

  const calcAutoParts = (id) => {
    updateEstimates((estimates) => {
      const est = estimates.find((m) => m.id === id);
      // boltSetAutomaticIncrease
      if (est.boltSetAutomaticIncrease && est.boltSetCount >= 0) {
        // 제품 수량 * 6개 추가
        const a = est.productCount * 6;
        let b = 0;
        let c = 0;
        let d = 0;

        // L수량 * 5개 추가
        if (est.lSocketAdded) {
          c = est.lSocketCount * 5;
        }

        // T수량 * 7개 추가
        if (est.tSocketAdded) {
          b = est.tSocketCount * 7;
        }

        // Cross수량 * 10개 추가
        if (est.crossSocketAdded) {
          d = est.crossSocketCount * 10;
        }

        const total = a + b + c + d;
        est.boltSetCount = total;
      }
      if (est.conSocketAutomaticIncrease && est.connectSocketCount >= 0) {
        let a = 0;
        let b = 0;
        let c = 0;
        let d = 0;

        // 제품 수량 * 6개 추가
        if (est.productCount > 1) {
          a = est.productCount - 1;
        }

        // L수량 * 2개 추가
        if (est.lSocketAdded) {
          c = est.lSocketCount * 2;
        }

        // T수량 * 3개 추가
        if (est.tSocketAdded) {
          b = est.tSocketCount * 3;
        }

        // Cross수량 * 4개 추가
        if (est.crossSocketAdded) {
          d = est.crossSocketCount * 4;
        }

        const total = a + b + c + d;

        est.connectSocketCount = total;
      }
    });
  };

  const calcEstimateTotal = (id) => {
    // 해당 제품
    const p = estimates.find((m) => m.id === id);
    let productPrice = 0;
    let cSocketPrice = 0;
    let endSocketPrice = 0;
    let lSocketPrice = 0;
    let tSocketPrice = 0;
    let crossSocketPrice = 0;
    let boltSetPrice = 0;
    let straightPipePrice = 0;
    let downPipePrice = 0;
    let twoInOneSideCapPrice = 0;
    let twoInOneBottomCapPrice = 0;
    let safeCoverPrice = 0;

    productPrice = p.productCount * p.normalPrice * p.meter;
    cSocketPrice = p.connectSocketCount * p.conSocketPrice;
    endSocketPrice = p.endSocketCount * p.endSocketPrice;

    if (p.tSocketAdded) {
      tSocketPrice = p.tSocketCount * p.tPrice;
    }
    if (p.lSocketAdded) {
      lSocketPrice = p.lSocketCount * p.lPrice;
    }
    if (p.crossSocketAdded) {
      crossSocketPrice = p.crossSocketCount * p.crossPrice;
    }
    if (p.boltSetAdded) {
      boltSetPrice = p.boltSetCount * p.boltSetPrice;
    }
    if (p.straightPipeAdded) {
      straightPipePrice = p.pipeStraightCount * p.pipePrice;
    }
    if (p.downPipeAdded) {
      downPipePrice = p.pipeDownCount * p.pipePrice;
    }
    if (p.twoInOneStraightPipeAdded) {
      twoInOneSideCapPrice = p.twoInOneSideCapCount * p.twoInOneSideCapPrice;
    }
    if (p.twoInOneDownPipeAdded) {
      twoInOneBottomCapPrice = p.twoInOneBottomCapCount * p.twoInOneBottomCapPrice;
    }
    if (p.safeCoverAdded) {
      safeCoverPrice = p.safeCoverCount * p.safeCoverPrice;
    }

    return (
      productPrice +
      cSocketPrice +
      endSocketPrice +
      lSocketPrice +
      tSocketPrice +
      crossSocketPrice +
      boltSetPrice +
      straightPipePrice +
      downPipePrice +
      twoInOneSideCapPrice +
      twoInOneBottomCapPrice +
      safeCoverPrice
    );
  };

  const getSumPrice = () => {
    let total = 0;
    estimates.forEach((est) => {
      const estTotal = calcEstimateTotal(est.id);

      total = total + estTotal;
    });
    return total;
  };

  /// 파이프 가공 옵션이 있는지 체크하는 함수.
  const doesPipeItemExist = (arr, size, location) => {
    if (location) {
      return arr.some((item) => item.size === size && item.location === location);
    } else {
      return arr.some((item) => item.size === size);
    }
  };

  return (
    // value = 자식 컴포들P이 사용할 것들을 명시하면 된다.
    <EstimateContext.Provider
      value={{
        estimates,
        setEstimateProductCount,
        handleAdd,
        handleDelete,
        handleIncreaseProductCount,
        handleDecreaseProductCount,
        setConnectSocketCount,
        handleIncreaseConnectSocketCount,
        handleDecreaseConnectSocketCount,
        conSocketDisableAutoIncrease,
        setEndSocketCount,
        handleIncreaseEndSocketCount,
        handleDecreaseEndSocketCount,
        setTPartCount,
        handleIncreaseTPartCount,
        handleDecreaseTPartCount,
        setLPartCount,
        handleIncreaseLPartCount,
        handleDecreaseLPartCount,
        setCrossPartCount,
        handleIncreaseCrossPartCount,
        handleDecreaseCrossPartCount,
        setStraightPipeCount,
        handleIncreaseStraightPipeCount,
        handleDecreaseStraightPipeCount,
        setDownPipeCount,
        handleIncreaseDownPipeCount,
        handleDecreaseDownPipeCount,
        set2in1SideCapCount,
        handleIncrease2in1SideCapCount,
        handleDecrease2in1SideCapCount,
        set2in1BottomCapCount,
        handleIncrease2in1BottomCapCount,
        handleDecrease2in1BottomCapCount,
        setBoltSetCount,
        handleIncreaseBoltSetCount,
        handleDecreaseBoltSetCount,
        boltSetDisableAutoIncrease,
        setPartAdded,
        resetNoShowPartProps,
        calcAutoParts,
        calcEstimateTotal,
        getSumPrice,
        canConstruct,
        getTotalPartsConstructionCost,
        getBagsPrice,
        getBagsCount,
        getRemodelingTotalPartsConstructionCost,
        resetEstimatesPipeOptions,
        getPossibleMeter,
        sawInfoDialog,
        setSawInfoDialog,
        activeDialogIndex,
        setActiveDialogIndex,
        handleDownPipePlus,
        handleDownPipeAdd,
        handleDownPipeMinus,
        handleSidePipePlus,
        handleSidePipeMinus,
        handleSidePipeAdd,
        setSafeCoverPartCount,
        handleIncreaseSafeCoverPartCount,
        handleDecreaseSafeCoverPartCount,
        hasOnlyRemodelingProducts,
        // getDownPipeOptionLength,
      }}
    >
      {children}
    </EstimateContext.Provider>
  );
}
