import React, { useState, useEffect, useContext } from "react";
import { EstimateContext } from "../context/EstimatesContext";
import { LuCircleEqual, LuPlusSquare } from "react-icons/lu";

import Utils from "../Utils";

export default function TotalProducts({ isConstructionPage = false }) {
  const { getSumPrice } = useContext(EstimateContext);
  const price = getSumPrice();
  const [isFixed, setIsFixed] = useState(false); // 초기 값: 고정되지 않음

  useEffect(() => {
    // 창 크기가 변경될 때 호출되는 이벤트 핸들러
    const handleResize = () => {
      // 현재 창의 너비를 검사하여 sm (예: 640px) 이하인 경우 고정 해제
      if (window.innerWidth <= 720) {
        setIsFixed(false);
      } else {
        setIsFixed(true); // 그렇지 않으면 고정
      }
    };

    // 초기 렌더링 시 이벤트 핸들러 등록
    handleResize();

    // 창 크기 변경 이벤트 리스너 등록
    window.addEventListener("resize", handleResize);

    // 컴포넌트 언마운트 시 이벤트 리스너 제거
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      className={
        isConstructionPage
          ? `w-full px-2 np:px-3 eme:px-7  md:px-8 md:py-5 pt-0 pb-5 , md:mt-8 , flex flex-col items-end gap-1 md:items-center md:flex-row justify-end md:justify-center ${
              isFixed ? "fixed bottom-20 left-0 right-0 bg-white z-999 border" : ""
            }`
          : `w-full , px-2 np:px-3 eme:px-7  md:px-8 md:py-10 pt-0 pb-5 , md:mt-8 , flex flex-col items-end gap-1 md:items-center md:flex-row justify-end md:justify-center  ${
              isFixed ? "fixed bottom-0 left-0 right-0 bg-white z-999 border" : ""
            }`
      }
    >
      <Total price={price} />
      <Fee price={price} />
      <LastTotal price={price} />
    </div>
  );
}

function Total({ price }) {
  return (
    <div className="flex items-center">
      <p className="pr-2">제품 금액</p>
      <p className="text-lg md:text-2xl font-bold pr-1">{Utils.addComma(price)}</p>
      <p className="">원</p>
      <LuPlusSquare className="w-7 h-7 text-gray-800 hover:text-gray-600 mx-4 hidden md:block" />
    </div>
  );
}

function Fee({ price }) {
  return (
    <div className="flex items-center">
      <p className="pr-2">부가세</p>
      <p className="text-lg md:text-2xl font-bold pr-1">{Utils.addComma(price / 10)}</p>
      <p className="">원</p>
      <LuCircleEqual className="w-7 h-7 text-gray-800 hover:text-gray-600 mx-4  hidden md:block" />
    </div>
  );
}

function LastTotal({ price }) {
  return (
    <div className="flex items-center">
      <p className="pr-2 text-semibold">최종 제품 금액</p>
      <p className="text-xl md:text-3xl font-bold pr-1 text-red-500">
        {Utils.addComma(price + price / 10)}
      </p>
      <p className="">원</p>
    </div>
  );
}
