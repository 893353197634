import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useContext } from "react";
import { EstimateContext } from "../context/EstimatesContext";
import { PdfContext } from "../context/PdfContext";
import animationData from "./lego.json";
import Lottie from "react-lottie";

export default function SlideDialog() {
  const { activeDialogIndex } = useContext(EstimateContext);
  const { dialogBodies, loading, dialogOpen, setDialogOpen } = useContext(PdfContext);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      <Transition appear show={dialogOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={() => setDialogOpen(false)}
        >
          <div className="min-h-screen px-4 text-center">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>

            <Transition.Child
              as="div"
              className={`inline-block ${
                loading ? "w-64 py-5" : "w-full max-w-2xl"
              } overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl`}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              {!loading && (
                <div className="inline-block w-full max-w-2xl overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl center">
                  <div>{dialogBodies[activeDialogIndex]}</div>
                </div>
              )}

              {loading && <Lottie options={defaultOptions} height={200} width={200} />}
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
