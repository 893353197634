import React, { useContext } from "react";
import { ProductsContext } from "../context/ProductsContext";
import { Typography, Switch } from "@material-tailwind/react";

export default function RecommendationSwitch() {
  const { recommendation, toggleRecommendation } = useContext(ProductsContext);
  return (
    <div>
      <div className="p-4 flex w-full items-center justify-end">
        <div className="mr-3">
          <Typography color="gray" className="font-medium">
            세트 제품 추천
          </Typography>
        </div>
        <Switch
          color="green"
          onChange={() => {
            toggleRecommendation();
          }}
          checked={recommendation}
        />
      </div>
    </div>
  );
}
