import React, { useContext } from "react";
import { useDaumPostcodePopup } from "react-daum-postcode";
import { PdfContext } from "../context/PdfContext";

export default function FindAddress() {
  const { setRoadJibun } = useContext(PdfContext);
  const open = useDaumPostcodePopup();

  const handleComplete = (data) => {
    setRoadJibun({
      jibun: data.jibunAddress,
      road: data.roadAddress,
    });
  };

  const handleClick = () => {
    open({ onComplete: handleComplete });
  };

  return (
    <div>
      <button
        type="button"
        className="rounded-md bg-blue-600 px-3.5 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
        onClick={handleClick}
      >
        주소 찾기
      </button>
    </div>
  );
}
