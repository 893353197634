import React, { useContext } from "react";
import Utils from "../Utils";

import PartsPopUp from "./PartsPopUp";
import { EstimateContext } from "../context/EstimatesContext";

export default function PartButtonAndTotal({ estimate }) {
  const { calcEstimateTotal } = useContext(EstimateContext);

  return (
    <div className="flex justify-between items-center mb-2">
      {estimate.hasExtraParts && <PartsPopUp estimate={estimate} />}
      {!estimate.hasExtraParts && <div />}
      {/* <PartsButton /> */}
      <div className="flex flex-col sp:flex-row  justify-center text-right mr-4 items-center">
        <p className="font-bold">{"품목 금액 : "}&nbsp;</p>
        <div className="flex items-center ">
          <p className="text-lg font-bold text-red-500">{`${Utils.addComma(
            calcEstimateTotal(estimate.id)
          )} `}</p>
          <p className="font-bold ml-1"> 원</p>
        </div>
      </div>
    </div>
  );
}
