import React, { useContext } from "react";
import { PdfContext } from "../context/PdfContext";

export default function DeliveryWay() {
  const { setDeliveryWay, pdfData } = useContext(PdfContext);
  const handleOptionChange = (e) => {
    setDeliveryWay(e.target.value);
  };
  return (
    <div>
      <div className="flex items-center ">
        <label htmlFor="company-name" className="label-style">
          배송 방법
        </label>
      </div>

      <div className="mt-4">
        <div>
          <div className="flex items-center mb-3">
            <input
              type="radio"
              value="택배 착불"
              checked={pdfData.order.deliveryWay === "택배 착불"}
              className="w-5 h-5 mr-2"
              onChange={handleOptionChange}
            />
            <p className="text-gray-700">택배 착불</p>
          </div>

          <div className="flex items-center mb-3">
            <input
              type="radio"
              value="용차 착불"
              checked={pdfData.order.deliveryWay === "용차 착불"}
              onChange={handleOptionChange}
              className="w-5 h-5 mr-2"
            />
            <p className="text-gray-700">용차 착불</p>
          </div>

          <div className="flex items-center">
            <input
              type="radio"
              value="용차 선불"
              checked={pdfData.order.deliveryWay === "용차 선불"}
              onChange={handleOptionChange}
              className="w-5 h-5 mr-2"
            />
            <p className="text-gray-700">용차 선불 ( 직원과 통화 후 배송비 별도 추가 )</p>
          </div>
        </div>
      </div>
    </div>
  );
}
