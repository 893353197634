import { PDFGrid } from "../PDFGrid";
import { PDFText } from "../PDFText";
import PropTypes from "prop-types";
import { View } from "@react-pdf/renderer";

PDFTable.propTypes = {
  data: PropTypes.array,
};

export function PDFTable({ data }) {
  return (
    <PDFGrid
      border
      borderRadius="10px"
      mh={8}
      pa={0}
      row="column"
      height={
        data.estimate.items.length <= 18
          ? "450px"
          : data.estimate.items.length <= 24
          ? "585px"
          : "auto"
      }
    >
      <View
        style={{
          padding: 0,
          margin: 0,
          borderLeftWidth: "0px",
          borderTopWidth: "0px",
          borderRightWidth: "0px",
          borderBottomWidth: "1px",
          borderBottomColor: "#1f2937",
          borderTopLeftRadius: "10px",
          borderButton: "1.5px ,solid",
          borderTopRightRadius: "10px",
          flexDirection: "row",
        }}
      >
        <View
          style={{
            // borderRight: '1.5px  solid  #1f2937',
            padding: 0,
            margin: 0,
            borderLeftColor: "white",
            width: "5%",
            alignItems: "center",
            justifyContent: "center",
            borderTopLeftRadius: "10px",
            borderRight: "1px solid #1f2937",
          }}
        >
          <PDFText text={"색인"} fontSize="9px" fontFamily="Bold" />
        </View>
        <View
          style={{
            // borderRight: '1.5px  solid  #1f2937',
            padding: 0,
            margin: 0,
            borderLeftColor: "white",
            width: "30%",
            alignItems: "center",
            justifyContent: "center",
            borderTopLeftRadius: "10px",
            borderRight: "1px solid #1f2937",
          }}
        >
          <PDFText text={"제품명"} fontSize="9px" fontFamily="Bold" />
        </View>

        {/* </View>
        <PDFGrid
        bgColor='red'
          borderRight
          borderWidth="1px"
          width="35%"
          alignItems="center"
          justifyContent="center"
        >
          <PDFText text={'제품명'} fontSize="9px" fontFamily="Bold" />
        </PDFGrid> */}
        <PDFGrid
          borderRight
          borderWidth="1px"
          width="20%"
          alignItems="center"
          justifyContent="center"
        >
          <PDFText text={"규격"} fontSize="9px" fontFamily="Bold" textAlign="center" />
        </PDFGrid>
        <PDFGrid
          borderRight
          borderWidth="1px"
          width="5%"
          alignItems="center"
          justifyContent="center"
        >
          <PDFText text={"단위"} fontSize="9px" fontFamily="Bold" textAlign="center" />
        </PDFGrid>
        <PDFGrid
          borderRight
          borderWidth="1px"
          width="10%"
          alignItems="center"
          justifyContent="center"
        >
          <PDFText text={"수량"} fontSize="9px" fontFamily="Bold" textAlign="center" />
        </PDFGrid>
        <PDFGrid
          borderRight
          borderWidth="1px"
          width="15%"
          alignItems="center"
          justifyContent="center"
        >
          <PDFText text={"단가"} fontSize="9px" fontFamily="Bold" textAlign="center" />
        </PDFGrid>
        {/* <PDFGrid
          width="15%"
          alignItems="center"
          justifyContent="center"
        >
 
        </PDFGrid> */}

        <View
          style={{
            borderRight: "1.5px  solid  #1f2937",
            padding: 0,
            margin: 0,
            borderLeftColor: "white",
            width: "15%",
            alignItems: "center",
            justifyContent: "center",
            borderTopLeftRadius: "10px",
          }}
        >
          <PDFText text={"합계"} fontSize="9px" fontFamily="Bold" textAlign="center" />
        </View>
      </View>
      {data.estimate.items.map((e, i) => (
        <PDFGrid borderButton borderWidth="1px" pa={0} key={i}>
          <PDFGrid
            borderRight
            borderWidth="1px"
            width="5%"
            alignItems="center"
            justifyContent="center"
          >
            <PDFText
              text={i + 1}
              fontSize="8px"
              // color="#544a4a"
              fontFamily="Regular"
            />
          </PDFGrid>
          <PDFGrid
            borderRight
            borderWidth="1px"
            width="30%"
            alignItems="center"
            justifyContent="center"
          >
            <PDFText
              text={e?.name}
              fontSize="8px"
              // color="#544a4a"
              fontFamily="Regular"
            />
          </PDFGrid>
          <PDFGrid
            borderRight
            borderWidth="1px"
            width="20%"
            row="column"
            alignItems="center"
            justifyContent="center"
          >
            <PDFText
              text={e?.standard}
              fontSize="8px"
              // color="#544a4a"
              fontFamily="Regular"
            />
          </PDFGrid>
          <PDFGrid
            borderRight
            borderWidth="1px"
            width="5%"
            alignItems="center"
            justifyContent="center"
          >
            <PDFText
              text={e?.unit}
              fontSize="8px"
              // color="#544a4a"
              fontFamily="Regular"
            />
          </PDFGrid>
          <PDFGrid
            borderRight
            borderWidth="1px"
            width="10%"
            alignItems="center"
            justifyContent="center"
          >
            <PDFText
              text={e?.count}
              fontSize="8px"
              // color="#544a4a"
              fontFamily="Regular"
            />
          </PDFGrid>
          <PDFGrid
            borderRight
            borderWidth="1px"
            width="15%"
            alignItems="center"
            justifyContent="center"
          >
            <PDFText
              text={e?.price}
              fontSize="8px"
              // color="#544a4a"
              fontFamily="Regular"
            />
          </PDFGrid>
          <PDFGrid width="15%" alignItems="center" justifyContent="center">
            <PDFText
              text={e?.total}
              fontSize="8px"
              // color="#544a4a"
              fontFamily="Regular"
            />
          </PDFGrid>
        </PDFGrid>
      ))}
    </PDFGrid>
  );
}
