import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "./components/Navbar";
import { ProductsProvider } from "./context/ProductsContext";
import { EstimatesProvider } from "./context/EstimatesContext";
import { CategoryProvider } from "./context/CategoryContext";
import { ConstructionProvider } from "./context/ConstructionContext";

import { Toaster } from "react-hot-toast";
import { PdfProvider } from "./context/PdfContext";

function App() {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <ProductsProvider>
        <EstimatesProvider>
          <ConstructionProvider>
            <CategoryProvider>
              <PdfProvider>
                <div className="pb-0 md:pb-44">
                  <Navbar />
                  <Outlet />
                  <Toaster />
                  {/* <ParticlesComponent /> */}
                </div>
              </PdfProvider>
            </CategoryProvider>
          </ConstructionProvider>
        </EstimatesProvider>
      </ProductsProvider>
    </QueryClientProvider>
  );
}

export default App;
