import React, { useContext, useEffect, useState } from "react";
import { Dialog } from "@headlessui/react";
import { DocumentDuplicateIcon } from "@heroicons/react/20/solid";
import { Button } from "@mui/material";
import Phone from "./construction/Phone";
import Email from "./construction/Email";
import { PdfContext } from "../context/PdfContext";
import { EstimateContext } from "../context/EstimatesContext";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import RoadAddress from "./construction/RoadAddress";
import JibunAddress from "./construction/JibunAddress";
import DetailAddress from "./construction/DetailAddress";
import FindAddress from "./FindAddress";
import ReceiverName from "./ReceiverName";
import BizCertificate from "./BizCertificate";
import CompanyName from "./CompanyName";
import ReceiverPhone from "./ReceiverPhone";
import PaymentDate from "./PaymentDate";
import ToastHelper from "../helpers/ToastHelper";
import DialogHelper from "../helpers/DialogHelper";
import DepositorName from "./DepositorName";
import animationData from "./lego.json";
import Lottie from "react-lottie";
import DeliveryWay from "./DeliveryWay";
import Note from "./Note";

export default function OrderDialogBody({ hasPrevButton }) {
  const {
    pdfData,
    dialogBodies,
    makeEstimatesToPdfData,
    setReceiverName,
    setReceiverPhone,
    setDialogOpen,
  } = useContext(PdfContext);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [firstInit, setFirstInit] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isSamePerson, setIsSamePerson] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const { setActiveDialogIndex, activeDialogIndex } = useContext(EstimateContext);

  const handleCheckboxChange = () => {
    if (!isSamePerson) {
      setReceiverName(pdfData.client.name);
      setReceiverPhone(pdfData.client.phone);
    } else {
      setReceiverName("");
      setReceiverPhone("");
    }
    setIsSamePerson(!isSamePerson);
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (loading) {
      return;
    }
    setLoading(true);

    const host =
      window.location.hostname === "localhost" ? "http://koreatrenchorder.cafe24app.com" : "api";

    try {
      const response = await fetch(`${host}/estimate/order`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(pdfData),
      });

      if (response.ok) {
        setDialogOpen(false);
        setLoading(false);
        DialogHelper.showSentOrder();
      } else {
        setLoading(false);
        ToastHelper.showErrorMsg("발주서 제출에 실패하였습니다");
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      ToastHelper.showErrorMsg("발주서 제출 중 에러가 발생했습니다.");
    }
  };

  const prevSlide = () => {
    setActiveDialogIndex(
      (prevSlide) => (prevSlide - 1 + dialogBodies.length) % dialogBodies.length
    );
  };

  useEffect(() => {
    if (activeDialogIndex === dialogBodies.length - 1) {
      if (firstInit) {
        makeEstimatesToPdfData();
        setFirstInit(false);
      }
    }
  }, [activeDialogIndex, dialogBodies.length, makeEstimatesToPdfData, firstInit]);

  return (
    <div className={`flex flex-col`}>
      {loading && <Lottie options={defaultOptions} height={200} width={200} />}
      {!loading && (
        <div>
          <div className="bg-gray-50 px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
            <div className="mt-3 text-center sm:mt-0">
              <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                발주 정보 입력
              </Dialog.Title>
            </div>
          </div>

          <div className="bg-white p-3  sm:p-7 my-4 flex flex-col gap-6">
            <>
              <p className="text-xl font-bold text-green-500">주문자 정보</p>
              <CompanyName isForPdf={true} isForOrder={true} />
              <Phone isForPdf={true} />
              <Email isForPdf={true} />
              <BizCertificate />

              <span className="h-4" />
              <div className={`flex flex-col sm:flex-row justify-between`}>
                <p className="text-xl font-bold text-green-500">배송 정보</p>
                <div className="flex items-center">
                  <p className="mr-2 text-gray-700">주문자와 배송받는분이 같습니다</p>
                  <input
                    className="h-5 w-5 p-1"
                    type="checkbox"
                    checked={isSamePerson}
                    onChange={handleCheckboxChange}
                  />
                </div>
              </div>

              <ReceiverName />
              <ReceiverPhone />
              <RoadAddress />
              <JibunAddress />
              <div className="sm:col-span-3">
                <div className="flex items-end">
                  <DetailAddress />
                  <FindAddress />
                </div>
              </div>

              <DeliveryWay />

              <Note />

              <p className="mt-10 text-xl font-bold text-green-500">입금 정보</p>

              <DepositorName />

              <PaymentDate selectedDate={selectedDate} setSelectedDate={setSelectedDate} />

              <BankInformation finalTotal={pdfData.estimate.finalTotal} />

              <Button
                variant="contained"
                color="info"
                onClick={handleSubmit}
                disabled={
                  pdfData.client.name === "" ||
                  pdfData.client.phone === "" ||
                  !emailRegex.test(pdfData.client.email) ||
                  pdfData.order.receiverName === "" ||
                  pdfData.order.receiverPhone === "" ||
                  pdfData.order.roadAddress === "" ||
                  pdfData.order.depositorName === "" ||
                  pdfData.order.deliveryWay === "" ||
                  selectedDate === null
                }
              >
                <p className="p-1 font-bold text-md">발주하기</p>
              </Button>

              <Button
                variant="contained"
                color="inherit"
                className="visible sm:invisible"
                onClick={() => {
                  setDialogOpen(false);
                }}
                startIcon={<CloseIcon />}
              >
                닫기
              </Button>
            </>
          </div>

          {hasPrevButton && (
            <div className="bg-gray-50 px-4 p-5">
              <div className="mt-3 text-center sm:mt-0">
                <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                  <div className="flex gap-10 justify-center">
                    <Button
                      variant="contained"
                      color="info"
                      onClick={prevSlide}
                      startIcon={<ArrowBackIcon />}
                    >
                      이전
                    </Button>
                  </div>
                </Dialog.Title>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

/// Inner Components
const BankInformation = ({ finalTotal }) => {
  const handleCopyClick = () => {
    navigator.clipboard.writeText("기업은행 (코리아트렌치 주식회사) 237-037410-01-017");
    ToastHelper.showToast("기업은행 (코리아트렌치 주식회사)\n237-037410-01-017\n복사 완료");
  };
  return (
    <p className="ml-1">
      기업은행 (코리아트렌치 주식회사)
      <br className="mb-2" />
      <div className="flex">
        <span className="font-bold text-lg mr-2">237-037410-01-017</span>
        <button onClick={handleCopyClick}>
          <DocumentDuplicateIcon className="mx-auto h-7 w-7 text-gray-300" aria-hidden="true" />
        </button>
      </div>
      <br />
      <span className="text-red-500 text-xl font-bold">{finalTotal}</span>
      <span className="text-lg"> 원</span>
      <br />
      <span className="text-red-500 text-lg">위 견적 금액 입금 확인 후 배송이 시작됩니다.</span>
      <br />
      <span className="text-gray-500 ">
        가공 부속이 있는 경우 입금확인 후 제작이 되므로 수량에 따라서 1-3일 후 배송이 시작됩니다.{" "}
        <br />
        (별도확인필요)
      </span>
    </p>
  );
};
