import React, { useContext } from "react";
import { PopoverContent, Button, Typography, Switch } from "@material-tailwind/react";

import { useState } from "react";
import { EstimateContext } from "../context/EstimatesContext";
import Divider2 from "./Divider2";
import SubText from "./ui/SubText";

export default function PartList({ estimate, closePopover }) {
  const { setPartAdded, resetNoShowPartProps } = useContext(EstimateContext);

  const [tSocketAdded, setTSocketAdded] = useState(estimate.tSocketAdded);
  const [lSocketAdded, setLSocketAdded] = useState(estimate.lSocketAdded);
  const [crossSocketAdded, setCrossSocketAdded] = useState(estimate.crossSocketAdded);
  const [straightPipeAdded, setStraightPipeAdded] = useState(estimate.straightPipeAdded);
  const [downPipeAdded, setDownPipeAdded] = useState(estimate.downPipeAdded);
  const [boltSetAdded, setBoltSetAdded] = useState(estimate.boltSetAdded);
  const [twoInOneSideCapAdded, setTwoInOneSideCapAdded] = useState(estimate.twoInOneSideCapAdded);
  const [twoInOneDownCapAdded, setTwoInOneDownCapAdded] = useState(estimate.twoInOneDownCapAdded);
  const [safeCoverAdded, setSafeCoverAdded] = useState(estimate.safeCoverAdded);
  // const [twoInOneStraightAdded, setTwoInOneStraightAdded] = useState(estimate.twoInOneStraightAdded);
  // const [twoInOneDownAdded, setTwoInOneDownAdded] = useState(estimate.twoInOneDownAdded);

  return (
    <PopoverContent className="z-[999] flex w-full sm:w-[28rem] overflow-hidden p-0 mb-4">
      <div className="w-full flex-col">
        <div className="w-full ">
          {/* //! 전산 볼트 */}
          {estimate.boltSetCount >= 0 && (
            <div>
              <div className="p-4 flex justify-between w-full items-center">
                <div>
                  <Typography color="blue-gray" className="font-medium">
                    볼트 세트
                  </Typography>
                  <Typography variant="small" color="gray" className="font-normal">
                    <b className="text-red-500">건식</b> 시공시, 1m당 2개 씩 필요합니다
                    <br />
                    <b className="text-red-500">습식</b> 시공시, 필요없습니다.
                  </Typography>
                </div>
                <Switch
                  color="blue"
                  onChange={() => {
                    const value = !boltSetAdded;
                    setBoltSetAdded(value);
                  }}
                  checked={boltSetAdded}
                />
              </div>
              <Divider2 />
            </div>
          )}

          {/* //! SafeCover */}
          {estimate.safeCoverCount >= 0 && (
            <div>
              <div className="p-4 flex justify-between w-full items-center">
                <div>
                  <Typography color="blue-gray" className="font-medium">
                    안전 커버
                  </Typography>
                </div>
                <Switch
                  color="blue"
                  onChange={() => {
                    const value = !safeCoverAdded;
                    setSafeCoverAdded(value);
                  }}
                  checked={safeCoverAdded}
                />
              </div>
              <Divider2 />
            </div>
          )}

          {/* //! T Socket */}
          {estimate.tSocketCount >= 0 && (
            <div>
              <div className="p-4 flex justify-between w-full items-center">
                <div>
                  <Typography color="blue-gray" className="font-medium">
                    T자 가공
                  </Typography>
                  <SubText
                    partName={"tSocket"}
                    autoBoltSet={estimate.boltSetAutomaticIncrease}
                    autoConSocket={estimate.conSocketAutomaticIncrease}
                  />
                </div>
                <Switch
                  color="blue"
                  onChange={() => {
                    const value = !tSocketAdded;
                    setTSocketAdded(value);
                  }}
                  checked={tSocketAdded}
                />
              </div>
              <Divider2 />
            </div>
          )}
          {/* //! L Socket */}
          {estimate.lSocketCount >= 0 && (
            <div>
              <div className="p-4 flex justify-between w-full items-center">
                <div>
                  <Typography color="blue-gray" className="font-medium">
                    L자 가공
                  </Typography>

                  <SubText
                    partName={"lSocket"}
                    autoBoltSet={estimate.boltSetAutomaticIncrease}
                    autoConSocket={estimate.conSocketAutomaticIncrease}
                  />
                </div>
                <Switch
                  color="blue"
                  onChange={() => {
                    const value = !lSocketAdded;
                    setLSocketAdded(value);
                  }}
                  checked={lSocketAdded}
                />
              </div>
              <Divider2 />
            </div>
          )}
          {/* //! Cross Socket */}
          {estimate.crossSocketCount >= 0 && (
            <div>
              <div className="p-4 flex justify-between w-full items-center">
                <div>
                  <Typography color="blue-gray" className="font-medium">
                    크로스 가공
                  </Typography>
                  <SubText
                    partName={"crossSocket"}
                    autoBoltSet={estimate.boltSetAutomaticIncrease}
                    autoConSocket={estimate.conSocketAutomaticIncrease}
                  />
                </div>
                <Switch
                  color="blue"
                  onChange={() => {
                    const value = !crossSocketAdded;
                    setCrossSocketAdded(value);
                  }}
                  checked={crossSocketAdded}
                />
              </div>
              <Divider2 />
            </div>
          )}

          {/* //! Pipe Straight */}
          {estimate.pipeStraightCount >= 0 && (
            <div>
              <div className="p-4 flex justify-between w-full items-center">
                <div>
                  <Typography color="blue-gray" className="font-medium">
                    파이프 가공 (측면)
                  </Typography>
                </div>
                <Switch
                  color="blue"
                  onChange={() => {
                    const value = !straightPipeAdded;
                    setStraightPipeAdded(value);
                  }}
                  checked={straightPipeAdded}
                />
              </div>
              <Divider2 />
            </div>
          )}

          {/* //! Pipe Down */}
          {estimate.pipeDownCount >= 0 && (
            <div>
              <div className="p-4 flex justify-between w-full items-center">
                <div>
                  <Typography color="blue-gray" className="font-medium">
                    파이프 가공 (하부)
                  </Typography>
                </div>
                <Switch
                  color="blue"
                  onChange={() => {
                    const value = !downPipeAdded;
                    setDownPipeAdded(value);
                  }}
                  checked={downPipeAdded}
                />
              </div>
              <Divider2 />
            </div>
          )}

          {/* //! 2in1SideCapCount */}
          {estimate.twoInOneSideCapCount >= 0 && (
            <div>
              <div className="p-4 flex justify-between w-full items-center">
                <div>
                  <Typography color="blue-gray" className="font-medium">
                    직선 파이프 소켓
                  </Typography>
                </div>
                <Switch
                  color="blue"
                  onChange={() => {
                    const value = !twoInOneSideCapAdded;
                    setTwoInOneSideCapAdded(value);
                  }}
                  checked={twoInOneSideCapAdded}
                />
              </div>
              <Divider2 />
            </div>
          )}

          {/* //! 2in1BottomCapCount */}
          {estimate.twoInOneBottomCapCount >= 0 && (
            <div>
              <div className="p-4 flex justify-between w-full items-center">
                <div>
                  <Typography color="blue-gray" className="font-medium">
                    하부 파이프 소켓
                  </Typography>
                </div>
                <Switch
                  color="blue"
                  onChange={() => {
                    const value = !twoInOneDownCapAdded;
                    setTwoInOneDownCapAdded(value);
                  }}
                  checked={twoInOneDownCapAdded}
                />
              </div>
              <Divider2 />
            </div>
          )}
        </div>

        <div className="flex mt-6 mb-2">
          <Button
            color="gray"
            className="flex-1 mx-4 mb-4"
            onClick={() => {
              const id = estimate.id;
              setPartAdded(id, boltSetAdded, "boltSet");
              setPartAdded(id, tSocketAdded, "tSocket");
              setPartAdded(id, lSocketAdded, "lSocket");
              setPartAdded(id, crossSocketAdded, "crossSocket");
              setPartAdded(id, straightPipeAdded, "straightPipe");
              setPartAdded(id, downPipeAdded, "downPipe");
              setPartAdded(id, twoInOneSideCapAdded, "twoInOneStraightPipe");
              setPartAdded(id, twoInOneDownCapAdded, "twoInOneDownPipe");
              setPartAdded(id, safeCoverAdded, "safeCover");
              resetNoShowPartProps(id);
              closePopover();
            }}
          >
            닫기
          </Button>
        </div>
      </div>
    </PopoverContent>
  );
}
