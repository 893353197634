import { useQuery } from "@tanstack/react-query";
import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ProductsContext } from "../context/ProductsContext";
import { EstimateContext } from "../context/EstimatesContext";
import { isMobile } from "react-device-detect";
import { DcocumentPDFTable } from "./PDF/document";
import { pdf } from "@react-pdf/renderer";
import { PdfContext } from "../context/PdfContext";
import { CategoryContext } from "../context/CategoryContext";
import ProductsDialog from "./ProductsDialog";

export default function Navbar() {
  const host =
    window.location.hostname === "localhost" ? "http://koreatrenchorder.cafe24app.com" : "api";
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isOpen, setOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [firstInit, setFirstInit] = useState(true);
  const [chosenProducts, setChosenProducts] = useState([]);
  const { pdfData } = useContext(PdfContext);
  const { setProducts } = useContext(ProductsContext);
  const { onProductChosen } = useContext(CategoryContext);
  const { setSawInfoDialog } = useContext(EstimateContext);
  const { estimates, canConstruct } = useContext(EstimateContext);

  // Fetch data
  useQuery(
    ["products", "estimate"],
    async () => {
      console.log("✅✅✅⭐️📌🟢🟡🔴");
      return fetch(`${host}/db/products`).then((res) =>
        res
          .json()
          .then((products) => {
            products.forEach((p) => {
              /// normalPrice = 앱에서 upPrice를 의미한다.
              p.discounted = false;
              const webIds = p.webId.split("-");
              p.groupId = webIds[0];

              //! Price 셋팅
              const minimumOrder = p.minimumOrder === 0 ? 1 : p.minimumOrder;
              const a = p.normalPrice * p.meter;
              const b = p.endSocketPrice * 2;
              const c = p.normalPrice * p.meter * minimumOrder;
              const d = p.endSocketPrice * 2;
              p.productPrice = a + b;
              p.totalPrice = c + d;
              p.twoInOneSideCapPrice = p.straightPipeSocketPrice; // 측면 가격
              p.twoInOneBottomCapPrice = p.downPipeSocketPrice; // 하부 가격

              //! 파이프 규격 선택
              if (p.customPipeSizes !== "") {
                const q = p.customPipeSizes.split(",").map((size) => `Ø${size}`);
                p.customPipeSizes = ["파이프 규격을 선택해주세요", ...q];
              }

              p.sidePipeOptions = { total: 0, items: [] };
              p.downPipeOptions = { total: 0, items: [] };

              //! 부품 추가 유무 셋팅
              // if (p.boltSetPrice !== 0) {
              //   p.boltSetAdded = true;
              // } else {
              // }

              p.boltSetAdded = false;
              p.tSocketAdded = false;
              p.lSocketAdded = false;
              p.crossSocketAdded = false;
              p.straightPipeAdded = false;
              p.downPipeAdded = false;
              p.twoInOneStraightPipeAdded = false; // ✅ 리팩토링 필요
              p.twoInOneDownPipeAdded = false; // ✅ 리팩토링 필요
              p.twoInOneSideCapAdded = false;
              p.twoInOneDownCapAdded = false;
              p.safeCoverAdded = false; // 하나로 안전 커버 추가 유무

              //! Automatic 셋팅
              p.conSocketAutomaticIncrease = true;
              p.boltSetAutomaticIncrease = true;

              //! Count 셋팅   ( Price가 있으면 -> 0 없으면 -1 )
              p.connectSocketCount = p.conSocketPrice === 0 ? -1 : 0;
              if (p.minimumOrder !== 0) {
                p.productCount = p.minimumOrder;
                if (p.connectSocketCount === 0) {
                  p.connectSocketCount = p.productCount - 1;
                }
              } else {
                p.productCount = 1;
              }

              p.endSocketCount = p.endSocketPrice === 0 ? -1 : 2;
              p.lSocketCount = p.lPrice === 0 ? -1 : 0;
              p.tSocketCount = p.tPrice === 0 ? -1 : 0;
              p.crossSocketCount = p.crossPrice === 0 ? -1 : 0;
              p.boltSetCount = p.boltSetPrice === 0 ? -1 : 6;
              p.pipeStraightCount = p.pipePrice === 0 ? -1 : 0;
              p.pipeDownCount = p.pipePrice === 0 ? -1 : 0;
              p.twoInOneSideCapCount = p.twoInOneSideCapPrice === 0 ? -1 : 0;
              p.twoInOneBottomCapCount = p.twoInOneBottomCapPrice === 0 ? -1 : 0;
              p.safeCoverCount = p.safeCoverPrice === 0 ? -1 : 0; // 하나로 안전 커버

              //! Extra parts 유무
              p.hasExtraParts = checkForExtraParts(p);

              //! Dummy Count ⭐️
              // p.lSocketCount = 0;
              // p.tSocketCount = 0;
              // p.boltSetCount = 6;
              // p.pipeStraightCount = 0;
              // p.pipeDownCount = 0;
              // p.twoInOneSideCapCount = 0;
              // p.twoInOneBottomCapCount = 0;
            });
            return products;
          })
          .then((products) => {
            const parsedProducts = products.filter(function (p) {
              // TODO : invisible 제품 필터링 하기
              return p;
            });
            setProducts([]); // 초기화
            setProducts(parsedProducts);
            return parsedProducts;
          })
          .then((products) => {
            if (firstInit) {
              const queryParams = new URLSearchParams(window.location.search);
              const webId = queryParams.get("id");

              const relatedProducts = products.filter((product) => {
                return product.groupId === webId;
              });
              setChosenProducts(relatedProducts);
              if (relatedProducts.length === 1) {
                // Case1. 제품 하나의 단일 제품임 예를 들어 2in1
                //! 자동 제품 추가
                const product = { product: relatedProducts[0] }; // onProductChosen이 다른곳에서 쓰일때는 product.product 코드가 들어가기 때문에 이 코드가 들어간다.
                onProductChosen(product); // Add to estimate
                // TODO : 관련 제품 추천 해주기.
              } else if (relatedProducts.length > 1) {
                // Case2. 여러 제품군임.
                //! Show 규격 선택하는 Dialog
                setOpen(true);
              } else {
                // Case3. 어떤 것에도 해당되지 않을 때.
                setSawInfoDialog(false);
              }
            }
          })

          .catch((e) => console.log(`🔴 Error 🔴 ${e}`))
      );
    },
    {
      staleTime: 1000 * 60, // (1시간 정도)
    }
  );

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 30) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    const handleKeyPress = (event) => {
      if (event.key === "F8") {
        navigate("/");
      }
      if (event.key === "F9" && estimates.length > 0) {
        navigate("/auto");
      }
    };

    // 컴포넌트가 마운트될 때 이벤트 리스너 추가
    window.addEventListener("keydown", handleKeyPress);

    // 스크롤 이벤트 리스너 등록
    window.addEventListener("scroll", handleScroll);

    if (firstInit) {
      // 2초 후에 실행될 익명 함수
      setTimeout(function () {
        const doc = <DcocumentPDFTable data={pdfData} title={"title"} />;
        const asPdf = pdf([]);
        asPdf.updateContainer(doc);
        asPdf.toBlob();
        setFirstInit((prev) => false);
      }, 2000);
    }

    // 컴포넌트 언마운트 시 이벤트 리스너 제거
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [estimates, navigate, firstInit, pdfData]);

  return (
    <nav className={` ${scrolled ? "bg-white" : "bg-transparent"} fixed w-full z-20 top-0 left-0`}>
      <>{isOpen && <ProductsDialog open={isOpen} setOpen={setOpen} products={chosenProducts} />}</>
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
        <a href="/" className="flex items-center ">
          <img src="../../logo.png" className="h-8 ml-2 mr-2 sm:mr-4 hidden se:block" alt="Logo" />
          <span className="self-center text-base vsp:text-xl md:text-2xl font-semibold whitespace-nowrap ">
            {pathname === "/"
              ? "제품 자동 견적"
              : pathname === "/construction"
              ? "정식 시공 견적"
              : "시공 자동 견적"}
          </span>
        </a>

        {canConstruct && (
          <div className="w-100 flex justify-between">
            <Link
              className={`font-[500] hover:text-blue-500 text-center mr-2 md:mr-5 ${
                pathname === "/" ? "text-blue-500  font-bold" : ""
              }`}
              to="/"
            >
              <span className="flex flex-col">
                <p>제품 견적</p>
                <p>{isMobile ? "" : "F8"}</p>
              </span>
            </Link>
            <Link
              className={`font-[500] hover:text-blue-500 text-center mr-2  ${
                pathname === "/auto" || pathname === "/construction"
                  ? "text-blue-500 font-bold"
                  : ""
              }`}
              to="/auto"
            >
              <span className="flex flex-col">
                <p>시공 견적</p>
                <p>{isMobile ? "" : "F9"}</p>
              </span>
            </Link>

            <Link className="font-[500] hover:text-blue-500" to="/construction"></Link>
          </div>
        )}
      </div>
    </nav>
  );
}

function checkForExtraParts(product) {
  if (
    product.connectSocketCount >= 0 || // 연결 소켓
    product.endSocketCount >= 0 || // 마구리 소켓
    product.lSocketCount >= 0 || // L소켓
    product.tSocketCount >= 0 || // T소켓
    product.crossSocketCount >= 0 || // 크로스 소켓
    product.pipeStraightCount >= 0 || // 파이프 직선
    product.pipeDownCount >= 0 || // 파이프 하부
    product.boltSetCount >= 0 || // 볼트셋트
    product.twoInOneSideCapCount >= 0 || // 투인원 측면
    product.twoInOneBottomCapCount >= 0 // 투인원 하부
  ) {
    return true;
  }
  return false;
}
