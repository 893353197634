import { Font, Document } from "@react-pdf/renderer";
import PropTypes from "prop-types";
import RobotoBold from "../../../../src/fonts/NotoSansKR-Bold.ttf";
import corean_light from "../../../../src/fonts/NotoSansKR-Regular.ttf";
import { PageOne } from "../body/page_one_";
import { PageTwo } from "../body/page_two_";
import { PageThree } from "../body/page_three_";

DcocumentPDFTable.propTypes = {
  data: PropTypes.array,
  title: PropTypes.string,
};

export function DcocumentPDFTable({ data, title }) {
  Font.register({
    family: "Bold",
    src: RobotoBold,
  });
  Font.register({
    family: "Regular",
    src: corean_light,
  });
  return (
    <Document title={title}>
      <PageOne data={data} />
      {data.estimate.photos.length !== 0 && <PageTwo data={data} />}
      {data.estimate.extraInfos.length !== 0 && <PageThree data={data} />}
    </Document>
  );
}
