import Divider from "./Divider";
import ProductInfo from "./ProductInfo";
import ProductCountAndPrice from "./ProductCountAndPrice";
import ConnectSocketCountAndPrice from "./ConnectSocketCountAndPrice";
import EndSocketCountAndPrice from "./EndSocketCountAndPrice";
import TPartCountAndPrice from "./TPartCountAndPrice";
import LPartCountAndPrice from "./LPartCountAndPrice";
import StraightPipeCountAndPrice from "./StraightPipeCountAndPrice";
import BottomPipeCountAndPrice from "./BottomPipeCountAndPrice";
import TwoInOneSideCapCountAndPrice from "./TwoInOneSideCapCountAndPrice";
import TwoInOneBottomCountAndPrice from "./TwoInOneBottomCountAndPrice";
import BoltSetCountAndPrice from "./BoltSetCountAndPrice";
import CrossPartCountAndPrice from "./CrossPartCountAndPrice";
import PartButtonAndTotal from "./PartButtonAndTotal";
import SafeCoverCoverAndPrice from "./SafeCoverCoverAndPrice";

export default function EstimateCard({ estimate }) {
  return (
    <main
      key={estimate.id}
      className={`p-2 sm:p-4 mb-6  mx-1 p:mx-0 rouned-md bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700`}
    >
      <ProductInfo estimate={estimate} />

      <ProductCountAndPrice estimate={estimate} />

      {estimate.connectSocketCount >= 0 && (
        <div>
          <Divider />
          <ConnectSocketCountAndPrice estimate={estimate} />
        </div>
      )}

      {estimate.boltSetAdded && (
        <div>
          <Divider />
          <BoltSetCountAndPrice estimate={estimate} />
        </div>
      )}

      {estimate.endSocketCount >= 0 && (
        <div>
          <Divider />
          <EndSocketCountAndPrice estimate={estimate} />
        </div>
      )}

      {estimate.tSocketAdded && (
        <div>
          <Divider />
          <TPartCountAndPrice estimate={estimate} />
        </div>
      )}

      {estimate.lSocketAdded && (
        <div>
          <Divider />
          <LPartCountAndPrice estimate={estimate} />
        </div>
      )}

      {estimate.crossSocketAdded && (
        <div>
          <Divider />
          <CrossPartCountAndPrice estimate={estimate} />
        </div>
      )}

      {estimate.straightPipeAdded && (
        <div>
          <Divider />
          <StraightPipeCountAndPrice estimate={estimate} />
        </div>
      )}

      {estimate.downPipeAdded && (
        <div>
          <Divider />
          <BottomPipeCountAndPrice estimate={estimate} />
        </div>
      )}
      {estimate.safeCoverAdded && (
        <div>
          <Divider />
          <SafeCoverCoverAndPrice estimate={estimate} />
        </div>
      )}

      {estimate.twoInOneStraightPipeAdded && (
        <div>
          <Divider />
          <TwoInOneSideCapCountAndPrice estimate={estimate} />
        </div>
      )}

      {estimate.twoInOneDownPipeAdded && (
        <div>
          <Divider />
          <TwoInOneBottomCountAndPrice estimate={estimate} />
        </div>
      )}

      <div>
        <Divider thick={true} />
        <PartButtonAndTotal estimate={estimate} />
      </div>
    </main>
  );
}
