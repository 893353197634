import React from "react";
import Utils from "../Utils";

export default function SummaryProductInfo({ estimate }) {
  return (
    <div className="flex items-center mb-2">
      <img
        src={estimate.thumbnail}
        className="w-24 h-24 md:w-28 md:h-28 bg-white rounded-md border"
        alt=""
      />
      <div className=" flex-auto p-4">
        <div className="flex justify-between items-center">
          <p className="text-lg font-semibold">{estimate.name}</p>
        </div>

        <p className="text-md text-gray-400 ">{estimate.standard}</p>
        <div>
          <span className="text-sm">
            {estimate.displayMeter}m ({estimate.unit})
          </span>
          <span className="text-gray-400">| </span>
          <span className="text-sm">1m 단가 </span>
          <span className="text-sm text-red-500 font-semibold">
            {Utils.addComma(estimate.normalPrice)}원
          </span>
        </div>
      </div>
      <div className="flex flex-col justify-center text-right p-4">
        <p className="">{estimate.productCount * estimate.displayMeter}m</p>
        <p className="font-bold">
          {Utils.addComma(estimate.productCount * estimate.displayMeter * estimate.normalPrice)}원
        </p>
      </div>
    </div>
  );
}
