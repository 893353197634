import React, { useContext, useState } from "react";
import { PdfContext } from "../context/PdfContext";

export default function DepositorName() {
  const { setDepositorName, pdfData } = useContext(PdfContext);
  const [isSamePerson, setIsSamePerson] = useState(false);
  const handleChange = (e) => {
    const value = e.target.value;
    setDepositorName(value);
  };
  const handleCheckboxChange = () => {
    if (!isSamePerson) {
      setDepositorName(pdfData.client.name);
    } else {
      setDepositorName("");
    }

    setIsSamePerson(!isSamePerson);
  };
  return (
    <div>
      <div className="flex items-center justify-between">
        <label htmlFor="company-name" className="label-style">
          입금자명
        </label>
        <span className="mr-1" />

        <div className="flex items-center">
          <p className="mr-2 text-gray-700">주문자와 입금자가 같습니다</p>
          <input
            className="h-5 w-5 p-1"
            type="checkbox"
            checked={isSamePerson}
            onChange={handleCheckboxChange}
          />
        </div>
      </div>

      <div className="mt-2">
        <input
          required
          type="text"
          name="company-name"
          id="company-name"
          autoComplete="company-name"
          className="one-line-input-style"
          onChange={handleChange}
          value={pdfData.order.depositorName}
        />
      </div>
    </div>
  );
}
