import { View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';

PDFGrid.propTypes = {
  children: PropTypes.any,
  width: PropTypes.string,
  row:  PropTypes.oneOf([ "row","column","row-reverse","column-reverse" ]),
  pa: PropTypes.oneOfType([ PropTypes.string,  PropTypes.number]),
  border: PropTypes.bool,
  borderButton: PropTypes.bool,
  borderTop: PropTypes.bool,
  borderLeft: PropTypes.bool,
  borderRight: PropTypes.bool,
  borderWidth: PropTypes.string,
  borderColor: PropTypes.string,
  height: PropTypes.string,
  borderRadius: PropTypes.string,
  ma: PropTypes.oneOfType([ PropTypes.string,  PropTypes.number]),
  pt: PropTypes.oneOfType([ PropTypes.string,  PropTypes.number]),
  pl: PropTypes.oneOfType([ PropTypes.string,  PropTypes.number]),
  pr: PropTypes.oneOfType([ PropTypes.string,  PropTypes.number]),
  pb: PropTypes.oneOfType([ PropTypes.string,  PropTypes.number]),
  mt: PropTypes.oneOfType([ PropTypes.string,  PropTypes.number]),
  mb: PropTypes.oneOfType([ PropTypes.string,  PropTypes.number]),
  ml: PropTypes.oneOfType([ PropTypes.string,  PropTypes.number]),
  mr: PropTypes.oneOfType([ PropTypes.string,  PropTypes.number]),
  mv: PropTypes.oneOfType([ PropTypes.string,  PropTypes.number]),
  mh: PropTypes.oneOfType([ PropTypes.string,  PropTypes.number]),
  pv: PropTypes.oneOfType([ PropTypes.string,  PropTypes.number]),
  ph: PropTypes.oneOfType([ PropTypes.string,  PropTypes.number]),
  bgColor: PropTypes.string,
  justifyContent: PropTypes.oneOf([ 'center' ,'flex-end' ,'flex-start' ,'space-between' ]),
  alignItems: PropTypes.oneOf([ 'center' , 'flex-end' , 'flex-start' , 'stretch' , 'baseline' , undefined ]),
  flexWrap: PropTypes.oneOf([ 'nowrap', 'wrap' ]),
  flex : PropTypes.string,
  borderTopLeftRadius: PropTypes.string,
  borderTopRightRadius: PropTypes.string,
  borderLeftColor: PropTypes.string,
  borderRightColor: PropTypes.string,
  borderTopolor: PropTypes.string,
  borderBottomColor: PropTypes.string,
  // justifyContent?: 'center' | 'flex-end' | 'flex-start' | 'space-between',
  // alignItems?: 'center' | 'flex-end' | 'flex-start' | 'stretch' | 'baseline' | undefined
}

export function PDFGrid({
  children,
  flex,
  width = '100%',
  row = 'row',
  pa = 5,
  border = false,
  borderButton = false,
  borderTop = false,
  borderLeft = false,
  borderRight = false,
  height = 'auto',
  borderRadius = '0',
  ma,
  mv,
  mh,
  pv,
  ph,
  pt,
  pl,
  pb,
  pr,
  ml,
  mr,
  mt,
  mb,
  bgColor = '#fff',
  justifyContent = 'flex-start',
  alignItems = undefined,
  borderWidth = '1.5px',
  borderColor = '#1f2937',
  flexWrap= undefined,
  borderTopLeftRadius,
  borderTopRightRadius,
  borderLeftColor,
  borderRightColor,
  borderTopolor,
  borderBottomColor,
}){
  return (
    <View
      style={{
        flex: flex,
        flexWrap: flexWrap,
        flexDirection: row,
        width: width,
        height: height,
        borderBottom: borderButton
          ? `${borderWidth} solid ${borderColor}`
          : border
          ? `${borderWidth} solid ${borderColor}`
          : 'none',
        borderTop: borderTop
          ? `${borderWidth} solid ${borderColor}`
          : border
          ? `${borderWidth} solid ${borderColor}`
          : 'none',
        borderLeft: borderLeft
          ? `${borderWidth} solid ${borderColor}`
          : border
          ? `${borderWidth} solid ${borderColor}`
          : 'none',
        borderRight: borderRight
          ? `${borderWidth} solid ${borderColor}`
          : border
          ? `${borderWidth} solid ${borderColor}`
          : 'none',
        borderRadius: borderRadius,
        margin: ma,
        padding: pa,
        marginVertical: mv,
        marginHorizontal: mh,
        paddingVertical: pv,
        paddingHorizontal: ph,
        backgroundColor: bgColor,
        justifyContent: justifyContent,
        alignItems: alignItems,
        paddingTop: pt,
        paddingBottom: pb,
        paddingLeft: pl,
        paddingRight: pr,
        marginTop: mt,
        marginBottom: mb,
        marginLeft: ml,
        marginRight: mr,
        borderTopLeftRadius: borderTopLeftRadius,
        borderLeftColor : borderLeftColor ,
        borderRightColor : borderRightColor ,
        borderTopolor : borderTopolor ,
        borderButtonColor : borderBottomColor ,
        borderTopRightRadius: borderTopRightRadius
      }}
    >
      {children}
    </View>
  );
};
