import { Popover, PopoverHandler, Button } from "@material-tailwind/react";
import { useContext, useState } from "react";
import PartList from "./PartList";
import { EstimateContext } from "../context/EstimatesContext";

export default function PartsPopUp({ estimate }) {
  const [statusState, setStatusState] = useState(false);
  const { calcAutoParts } = useContext(EstimateContext);

  // 팝업 열기 함수
  const openPopover = () => {
    setStatusState(true);
  };

  // 팝업 닫기 함수
  const closePopover = () => {
    setStatusState(false);
    calcAutoParts(estimate.id);
  };

  return (
    <Popover open={statusState}>
      <PopoverHandler onClick={() => openPopover()}>
        <Button
          color="blue-gray"
          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-xs vsp:text-sm px-4 py-2 vsp:px-5 vsp:py-2.5  dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
        >
          <p className="text-sm sp:text-sm  font-semibold ">부품 추가</p>
        </Button>
      </PopoverHandler>
      <PartList estimate={estimate} closePopover={closePopover} />
    </Popover>
  );
}
