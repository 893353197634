import React, { useContext } from "react";
import { PdfContext } from "../context/PdfContext";

export default function ReceiverPhone() {
  const { setReceiverPhone, pdfData } = useContext(PdfContext);

  const handleChange = (e) => {
    const value = e.target.value;
    setReceiverPhone(value);
  };

  return (
    <div>
      <div className="flex items-center ">
        <label htmlFor="company-name" className="label-style">
          받는 분 연락처
        </label>
      </div>

      <div className="mt-2">
        <input
          required
          type="text"
          name="company-name"
          id="company-name"
          autoComplete="company-name"
          className="one-line-input-style"
          onChange={handleChange}
          value={pdfData.order.receiverPhone}
        />
      </div>
    </div>
  );
}
